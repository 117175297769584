import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable, Component, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { AppContextService } from './app-context.service';
import * as _ from 'lodash';
import { ConfigService } from './config.service';

import { RequestMethod } from './enums/request-methods';

import 'reflect-metadata';

@Component({
  providers: [ConfigService, ApiService]
})

@Injectable()
export class BaseBreadService {
  __metadata: any;

  constructor(public http: HttpClient, public ConfigService: ConfigService, public apiService: ApiService) {
    if (this.__metadata) {
      if (!_.isUndefined(this.__metadata.resourceName)) this._resourceName = this.__metadata.resourceName;
      if (!_.isUndefined(this.__metadata.moduleName)) this._moduleName = this.__metadata.moduleName;
      if (!_.isUndefined(this.__metadata.withAttachments)) this._withAttachments = this.__metadata.withAttachments;
      if (!_.isUndefined(this.__metadata.attachmentsResourceName)) {
        this._attachmentsResourceName = this.__metadata.attachmentsResourceName;
      } else {
        this._attachmentsResourceName = this.__metadata.resourceName + 'Attachment';
      }
    }

    let injector = Injector.create({providers: [{provide: AppContextService, deps: []}]});

    this.appContextService = injector.get(AppContextService);
  }

  protected _resourceName: string = null;
  protected _moduleName: string = null;

  protected _withAttachments: boolean = false;
  protected _attachmentsResourceName: string = null;

  public appContextService: AppContextService;

  appContextProperties = {
    company: 'company',
    companyBranch: 'companyBranch',
    creatorEmployee: 'creatorEmployee',
  };

  getResourceName(): string {
    return this._resourceName;
  }

  // Default request URL format: [BASE]/[RESOURCE_NAME]/[ACTION]
  getRequestUrl(action: string, resource: string = null): string {
    return this.apiService.getRequestUrl(action, resource ? resource : this.getResourceName());
  }

  generateQueryOptions(queryOptionsBase: any, sortOptions: any = null) {
    var queryOptions = _.clone(queryOptionsBase);
    if (sortOptions) {
      queryOptions.sort = []; // overwrite sort if sortOptionsArr is defined
      if (<any> sortOptions instanceof Array) {
        for (var i in sortOptions) {
          var opt = sortOptions[i];
          if (opt.property) {
            queryOptions.sort.push({ 'property': opt.property, 'dir': opt.dir });
          }
        }
      } else { // assume Object
        if (sortOptions.property) {
          queryOptions.sort.push({ 'property': sortOptions.property, 'dir': sortOptions.dir, 'association': sortOptions.association });
        }
      }
    }
    return queryOptions;
  }

  browseResource(resource: string, paginationParams: any, queryOptionsBase: any, sortOptions: any = null, noBranchContext: boolean = null, noCompanyContext: boolean = null) {
    // var queryOptions = _.clone(queryOptionsBase);
    // if (sortOptions) {
    //   queryOptions.sort = []; // overwrite sort if sortOptionsArr is defined
    //   if (<any>sortOptions instanceof Array) {
    //     for (var i in sortOptions) {
    //       var opt = sortOptions[i];
    //       if (opt.property) {
    //         queryOptions.sort.push({ 'property': opt.property, 'dir': opt.dir });
    //       }
    //     }
    //   } else { // assume Object
    //     if (sortOptions.property) {
    //       queryOptions.sort.push({ 'property': sortOptions.property, 'dir': sortOptions.dir, 'association': sortOptions.association });
    //     }
    //   }
    // }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // BEGIN: Special handling for employee
    // It's not good to put this kind of logic here but this will do for now
    // (the better solution would require changing other parts of the code).
    if (resource === 'employee' || (this._resourceName === 'employee' && _.isNil(resource))) {
      if (_.isNil(noBranchContext)) {
        noBranchContext = true;
      }
      if (_.isNil(noCompanyContext) && noBranchContext) {
        noCompanyContext = true;
      }
    }
    // END: Special handling for employee
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    var queryOptions = this.generateQueryOptions(queryOptionsBase, sortOptions);
    return this.apiService.requestToJsonApi(RequestMethod.POST, this.getRequestUrl('browse', resource), { 'max': paginationParams.max, 'offset': paginationParams.offset }, { 'queryOptions': queryOptions }, false, false, noBranchContext, noCompanyContext);
  }

  browseResourceWithAttachments(resource: string, paginationParams: any, queryOptionsBase: any, sortOptions: any = null, noBranchContext: boolean = null, noCompanyContext: boolean = null) {
    return new Observable((observer) => {
      this.browseResource(resource, paginationParams, queryOptionsBase, sortOptions, noBranchContext, noCompanyContext).subscribe((res: any) => {
        let recordsDataIds = _.map(res.data.records, (record) => {
          return record.id;
        });

        this.apiService.requestToJsonApi(RequestMethod.POST, this.getRequestUrl('browse', this._attachmentsResourceName), { 'max': paginationParams.max, 'offset': paginationParams.offset }, { 'queryOptions': this.createDataIdsFilter(recordsDataIds) }, false, false, noBranchContext, noCompanyContext).subscribe((res2: any) => {
          _.forEach(res.data.records, (record) => {
            record.attachments = _.filter(res2.data.records, (attachment) => {
              return attachment[this._resourceName].id === record.id;
            });
          });

          observer.next(res);
          observer.complete();
        }, (err) => {
          observer.error(err);
        });
      });
    });
  }

  createDataIdsFilter(recordIds) {
    let filter = {
      conditions: [
        {
          compound: true,
          filter: {
            joinOp: 'and',
            conditions: _.map(recordIds, (recordId) => {
              return {
                property: this._resourceName + '.id',
                dataType: 'long',
                value: recordId
              };
            })
          }
        }
      ]
    };
  }

  exportResource(resource: string, paginationParams: any, queryOptionsBase: any, sortOptions: any = null, noBranchContext: boolean = null) {
    var queryOptions = this.generateQueryOptions(queryOptionsBase, sortOptions);
    return this.apiService.requestToJsonApi(RequestMethod.POST, this.getRequestUrl('export', resource), { 'max': paginationParams.max, 'offset': paginationParams.offset }, { 'queryOptions': queryOptions }, false, false, noBranchContext);
  }

  readResource(resource: string, id, noBranchContext: boolean = null) {
    let body = { properties: _.isArray(id) ? id.map(id => ({id: id})) : { id: id } };
    return this.apiService.requestToJsonApi(RequestMethod.POST, this.getRequestUrl('read', resource), null, body, false, false, noBranchContext);
  }

  addResource(resource: string, object: any, noBranchContext: boolean = null) {
    console.error(_.cloneDeep(object));
    console.error(resource);
    this.buildResourceForAdding(object);
    return this.apiService.requestToJsonApi(RequestMethod.POST, this.getRequestUrl('add', resource), null, { 'properties': object.getPropertiesMap ? object.getPropertiesMap.call(this) : object }, false, false, noBranchContext);
  }

  editResource(resource: string, id: number, object: any | any[], noBranchContext: boolean = null) {
    console.log(_.cloneDeep(object));
    if (_.isArray(object)) {
      object = object.map((o) => {
        this.buildResourceForEditing(o);
        return o;
      });
    } else {
      this.buildResourceForEditing(object);
    }

    console.log(_.cloneDeep(object));

    let bodyJson = _.isArray(object)
      ? { 'properties': object}
      : { 'properties': object.getPropertiesMap ? object.getPropertiesMap.call(this) : object };

    return this.apiService.requestToJsonApi(RequestMethod.POST, this.getRequestUrl('edit', resource), _.isNil(id) ? null : { 'id': id }, bodyJson, false, false, noBranchContext);
  }

  deleteResource(resource: string, object: any, noBranchContext: boolean = null) {
    return this.apiService.requestToJsonApi(RequestMethod.POST, this.getRequestUrl('delete', resource), null, { 'properties': object.getPropertiesMap ? object.getPropertiesMap.call(this) : object }, false, false, noBranchContext);
  }

  viewResource(resource: string, id, noBranchContext: boolean = null) {
    let body = { properties: _.isArray(id) ? id.map(id => ({id: id})) : { id: id } };
    return this.apiService.requestToApi(RequestMethod.POST, this.getRequestUrl('view', resource), null, body, false, false, noBranchContext);
  }

  editBatchResource(resource: string, object: any, noBranchContext: boolean = null) {
    return this.apiService.requestToJsonApi(RequestMethod.POST, this.getRequestUrl('edit', resource), null, { 'properties': object.getPropertiesMap ? object.getPropertiesMap.call(this) : object }, false, false, noBranchContext);
  }

  deleteBatchResource(resource: string, object: any, noBranchContext: boolean = null) {
    return this.apiService.requestToJsonApi(RequestMethod.POST, this.getRequestUrl('delete', resource), null, { 'properties': object.getPropertiesMap ? object.getPropertiesMap.call(this) : object }, false, false, noBranchContext);
  }

  downloadResource(resource: string, object: any, noBranchContext: boolean = null) {
    return this.apiService.requestToApi(RequestMethod.POST, this.getResourceDownloadUrl(resource, object, object.name), null, { 'properties': object.getPropertiesMap ? object.getPropertiesMap.call(this) : object }, false, noBranchContext);
  }

  findOneResource(resource: string, queryOptionsBase: any, sortOptions: any = null, noBranchContext: boolean = null) {
    return new Observable((observer: any) => {
      let observable = this.browseResource(resource, { max: 1, offset: 0 }, queryOptionsBase, sortOptions, noBranchContext);
      observable.subscribe(
        (res: any) => {
          if (_.get(res, 'data.records.length') > 0) {
            observer.next(res.data.records[0]);
          } else {
            observer.next(null);
          }
        },
        (err: any) => {
          observer.error(err);
        },
        () => {
          observer.complete();
        }
      );
    });
  }

  ////////////////////////////////////////////////////////////////////////////////////////
  // BREAD for designated resource
  ////////////////////////////////////////////////////////////////////////////////////////

  browse(paginationParams: any, queryOptionsBase: any, sortOptions: any = null, noBranchContext: boolean = null, noCompanyContext: boolean = null) {
    if (this._withAttachments) return this.browseResourceWithAttachments(null, paginationParams, queryOptionsBase, sortOptions, noBranchContext, noCompanyContext);
    else return this.browseResource(null, paginationParams, queryOptionsBase, sortOptions, noBranchContext, noCompanyContext);
  }

  export(paginationParams: any, queryOptionsBase: any, sortOptions: any = null, noBranchContext: boolean = null) {
    return this.exportResource(null, paginationParams, queryOptionsBase, sortOptions, noBranchContext);
  }

  read(id: number | number[], noBranchContext: boolean = null) {
    return this.readResource(null, id, noBranchContext);
  }

  add(object: any, noBranchContext: boolean = null) {
    return this.addResource(null, object, noBranchContext);
  }

  edit(id: number, object: any, noBranchContext: boolean = null) {
    return this.editResource(null, id, object, noBranchContext);
  }

  addByBatch(object: any, noBranchContext: boolean = null) {
    return this.add(object, noBranchContext);
  }

  editByBatch(object: any, noBranchContext: boolean = null) {
    return this.editBatchResource(null, object, noBranchContext);
  }

  deleteByBatch(object: any, noBranchContext: boolean = null) {
    return this.deleteBatchResource(null, object, noBranchContext);
  }

  delete(id: number, noBranchContext: boolean = null) {
    return this.deleteResource(null, id, noBranchContext);
  }

  download(id: any, noBranchContext: boolean = null) {
    return this.downloadResource(null, id, noBranchContext);
  }

  view(id: number | number[], noBranchContext: boolean = null) {
    return this.viewResource(null, id, noBranchContext);
  }

  findOne(queryOptionsBase: any, sortOptions: any = null, noBranchContext: boolean = null) {
    return this.findOneResource(null, queryOptionsBase, sortOptions, noBranchContext);
  }

  // Special functions

  saveResource(resource: string, object: any, noBranchContext: boolean = null) {
    if (object.id) {
      return this.editResource(resource, object.id, object, noBranchContext);
    } else {
      return this.addResource(resource, object, noBranchContext);
    }
  }

  save(object: any, noBranchContext: boolean = null) {
    return this.saveResource(null, object, noBranchContext);
  }

  getResourceDownloadUrl(resource: string = this._resourceName, object: any, name: any = null) {
    return this.getRequestUrl('download?access_token=' + _.defaultTo(this.apiService.getAccessToken(), '') + '&id=' + object.id + '&name=' + (name ? name : ''), resource);
  }

  getDownloadUrl(object: any, name: any = null) {
    return this.getResourceDownloadUrl(null, object, name);
  }

  getResourceUrl(action: string, resource: string, object: any, name: any = null) {
    return this.getRequestUrl(action + '?access_token=' + _.defaultTo(this.apiService.getAccessToken(), '') + '&id=' + object.id, resource);
  }

  getUrl(action: string, object: any, name: any = null) {
    return this.getResourceUrl(action, null, object, name);
  }

  //////////

  requestForResource(resource: string, action: string, requestPayload: any, noBranchContext: boolean = null) {
    return this.apiService.requestToJsonApi(RequestMethod.POST, this.getRequestUrl(action, resource), null, requestPayload, false, false, noBranchContext);
  }

  request(action: string, requestPayload: any, noBranchContext: boolean = null) {
    return this.requestForResource(null, action, requestPayload, noBranchContext);
  }

  buildResourceForSaving(object: any) {
    console.log(_.clone(object));
    if (!object) {
      return;
    }
    if (!object.id) {
      if (this.appContextProperties.company) {
        if (!object[this.appContextProperties.company]) {
          let company = this.appContextService.getCompany();
          if (company) {
            object[this.appContextProperties.company] = company;
            console.debug('Active company = %o, setting resource context', company);
          } else {
            console.warn('No active company');
          }
        } else {
          console.debug('Resource company property already set');
        }
      }
      if (this.appContextProperties.companyBranch) {
        if (!object[this.appContextProperties.companyBranch]) {
          let branch = this.appContextService.getBranch();
          if (branch) {
            object[this.appContextProperties.companyBranch] = branch;
            console.debug('Active branch = %o, setting resource context', branch);
          } else {
            console.warn('No active branch');
          }
        } else {
          console.debug('Resource branch property already set');
        }
      }
      if (this.appContextProperties.creatorEmployee) {
        if (!object[this.appContextProperties.creatorEmployee]) {
          let employee = this.appContextService.getEmployee();
          if (employee) {
            object[this.appContextProperties.creatorEmployee] = employee;
            console.debug('Active creatorEmployee = %o, setting resource context', employee);
          } else {
            console.warn('No active creatorEmployee');
          }
        } else {
          console.debug('Resource creatorEmployee property already set');
        }
      }
    }
  }

  buildResourceForAdding(object: any) {
    this.buildResourceForSaving(object);
  }

  buildResourceForEditing(object: any) {
    this.buildResourceForSaving(object);
  }

  buildNewObject(object) {
    this.buildResourceForSaving(object);
  }

  getAggregatedData(requestData) {
    return this.apiService.requestToJsonApi(RequestMethod.POST, this.getRequestUrl('getAggregatedData'), {}, requestData);
  }

  getAggregatedResourceData(resource: string, queryOptionsBase: any, sortOptions: any = null) {
    var queryOptions = this.generateQueryOptions(queryOptionsBase, sortOptions);
    return this.apiService.requestToJsonApi(RequestMethod.POST, this.getRequestUrl('getAggregatedData', resource), {}, { 'queryOptions': queryOptions });
  }

  list(paginationParams: any, queryOptionsBase: any, sortOptions: any = null, noBranchContext: boolean = null, noCompanyContext: boolean = null) {
    return this.listResource(null, paginationParams, queryOptionsBase, sortOptions, noBranchContext, noCompanyContext);
  }

  listResource(resource: string, paginationParams: any, queryOptionsBase: any, sortOptions: any = null, noBranchContext: boolean = null, noCompanyContext: boolean = null) {
    // var queryOptions = _.clone(queryOptionsBase);
    // if (sortOptions) {
    //   queryOptions.sort = []; // overwrite sort if sortOptionsArr is defined
    //   if (<any>sortOptions instanceof Array) {
    //     for (var i in sortOptions) {
    //       var opt = sortOptions[i];
    //       if (opt.property) {
    //         queryOptions.sort.push({ 'property': opt.property, 'dir': opt.dir });
    //       }
    //     }
    //   } else { // assume Object
    //     if (sortOptions.property) {
    //       queryOptions.sort.push({ 'property': sortOptions.property, 'dir': sortOptions.dir, 'association': sortOptions.association });
    //     }
    //   }
    // }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // BEGIN: Special handling for employee
    // It's not good to put this kind of logic here but this will do for now
    // (the better solution would require changing other parts of the code).
    if (resource === 'employee' || (this._resourceName === 'employee' && _.isNil(resource))) {
      if (_.isNil(noBranchContext)) {
        noBranchContext = true;
      }
      if (_.isNil(noCompanyContext) && noBranchContext) {
        noCompanyContext = true;
      }
    }
    // END: Special handling for employee
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    var queryOptions = this.generateQueryOptions(queryOptionsBase, sortOptions);
    return this.apiService.requestToJsonApi(RequestMethod.POST, this.getRequestUrl('list', resource), { 'max': paginationParams.max, 'offset': paginationParams.offset, noCount: paginationParams.noCount ? 1 : null }, { 'queryOptions': queryOptions }, false, false, noBranchContext, noCompanyContext);
  }
}

export function BreadServiceDecorator(config: object) {

  return function(target) {
    let config2: any = _.cloneDeep(config);
    config2.providers = [ConfigService, ApiService];
    var annotations = Reflect.getMetadata('annotations', target) || [];
    annotations.push(new Component(config2));
    Reflect.defineMetadata('annotations', annotations, target);

    target.prototype.__metadata = config2;

    return target;
  };
}

import { PublicApiService } from '../../../public-api.service';
import { BasePublicApiService } from '../base-public-api.service';
import { BreadServiceDecorator } from '../../../base-bread.service';
import { Injectable } from '@angular/core';

@BreadServiceDecorator({
  resourceName: 'lmsCourse'
})

@Injectable()
export class LmsCoursePublicApiService extends BasePublicApiService {
  constructor(public apiService: PublicApiService) {
    super(null, null, apiService, null);
  }
}


import { Injectable, ComponentFactoryResolver } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';

import * as _ from 'lodash';

@Injectable()
export class ComponentLoader {
  constructor(private _componentResolver: ComponentFactoryResolver) {
  }

  loadComponent(containerRef, component, index = null) {
    return Promise.resolve(this._componentResolver.resolveComponentFactory(component)).then((factory) => {
      let c = containerRef.createComponent(factory, index == null ? containerRef.length : index/*, containerRef.injector*/);
      return c;
    });
  }

  loadNextComponent(containerRef, viewsArr, index, observables = null, postInit = null): Observable<any> {
    let ret = observables ? observables : [];
    let componentIndex = index != null ? index + 1 : 0;
    // console.debug('Load next component %o %d %d', viewsArr, componentIndex, viewsArr.length);
    if (viewsArr.length > componentIndex) {
      let object = viewsArr[componentIndex];
      // console.debug('Loading component %o %o %d', containerRef, object.componentClass, componentIndex);
      let promise = this.loadComponent(containerRef, object.componentClass, componentIndex);
      ret.push(promise);
      promise.then(() => {
        // console.debug('Loaded component %o %o %d', containerRef, object.componentClass, componentIndex);
        this.loadNextComponent(containerRef, viewsArr, componentIndex, ret, postInit);
      });
      return ret;
    }
    if (_.isFunction(postInit)) {
      forkJoin(ret).subscribe(x => {
        _.forEach(viewsArr, (object, index) => {
          postInit(object, index, x[index]);
        });
      });
    }
    return ret;
  }

  loadComponentsFromConfig(containerRef, viewsArr, postInit): Observable<any> {
    return this.loadNextComponent(containerRef, viewsArr, null, null, postInit);
  }
}

import {
  HttpClient,
  HttpResponse,
  HttpRequest,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import {
  Injectable,
  Component,
} from '@angular/core';

import {
  ConfigService,
} from './config.service';
import {
  AppContextService,
} from './app-context.service';
import {
  Observable,
} from 'rxjs';

import { environment } from '../../environments/environment';

import { ApiService } from './api.service';

import { ApiRequestDataService } from './api-request-data.service';

@Component({
  providers: [ConfigService, AppContextService]
})

@Injectable()
export class PublicApiService extends ApiService {

  constructor(http: HttpClient, configService: ConfigService, appContextService: AppContextService, public apiRequestDataService: ApiRequestDataService) {
    super(http, configService, appContextService, apiRequestDataService);
  }

  // Default request URL format: [BASE]/[OBJECT_NAME]/[ACTION]
  getRequestUrl(action: string, resource: string = null) {
    return this.configService.getPublicApiBaseUrl() + (resource ? ('/' + resource) : '') + (action ? ('/' + action) : '');
  }
}

import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { LMSMessageModalService } from '../../commons/message-modal/lms/lms-message-modal';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { CustomValidators } from '../../shared/utils/custom-validators';

import { LmsUserService } from '../../shared/services/api/lms/lms-user.service';
import { LmsCoursePublicApiService } from '../../shared/services/api/public/lms/lms-course-public-api.service';
import { LocationCityPublicApiService } from '../../shared/services/api/public/location-city-public-api.service';
import { LocationAreaPublicApiService } from '../../shared/services/api/public/location-area-public-api.service';
import { LocationCountryPublicApiService } from '../../shared/services/api/public/location-country-public-api.service';
import { LmsUserModel } from '../../shared/models/lms/lms-user.model';

import {
  genders,
  genderDisplayText,
  maritalStatuses,
  maritalStatusDisplayText,
  highestEducationalAttainments,
  highestEducationalAttainmentDisplayText,
  employmentStatus,
  employmentStatusDisplayText,
  trainingOptions,
  trainingDisplayText
} from './utils/utils';

import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegistrationComponent implements OnInit {

  loading = false;
  isLoading = false;
  _registerForm: any = null;
  showRegisterFormError = false;
  _registerErrorMessage: any;
  _registerBusy: boolean;

  lmsUser: LmsUserModel;

  public _formEventSubscriptions: any = [];

  private maxYear = parseInt(moment().format('YYYY'));
  private minYear = parseInt(moment().subtract(100, 'y').format('YYYY'));
  private yearRange = 100;

  private conforme = false;

  private genders = genders;
  private genderDisplayText = genderDisplayText;
  private maritalStatuses = maritalStatuses;
  private maritalStatusDisplayText = maritalStatusDisplayText;
  private highestEducationalAttainments = highestEducationalAttainments;
  private highestEducationalAttainmentDisplayText = highestEducationalAttainmentDisplayText;
  private employmentStatus = employmentStatus;
  private employmentStatusDisplayText = employmentStatusDisplayText;
  private trainingOptions = trainingOptions;
  private trainingDisplayText = trainingDisplayText;

  private onSuccessMessage = 'Your application is successfully sent. You will receive an email or a phone call after your application has been reviewed.';
  private onSaveModalMessage = 'Are you sure you want to submit your application?';
  private onSubmitErrMessage = 'Oops.. Something went wrong in submitting your application :(';
  private onLoadNote = `
  <div class="ta-left">
    <p class="c-red bold"> Note: </p>
    <p> Take a picture or prepare a scanned copy of your birth certificate before continuing with the registration. </p>
  </div>
`;

  constructor(
    protected messageModalService: LMSMessageModalService,
    private lmsUserService: LmsUserService,
    private lmsCoursePublicApiService: LmsCoursePublicApiService,
    private locationCityPublicApiService: LocationCityPublicApiService,
    private locationAreaPublicApiService: LocationAreaPublicApiService,
    private locationCountryPublicApiService: LocationCountryPublicApiService,
    private router: Router,
    protected formBuilder: FormBuilder,
    protected cd: ChangeDetectorRef
  ) {
    this.onLoadAlert();
    this.buildRegisterForm();
    this.attachFormEventHandlers();
  }

  buildRegisterForm() {
    this._registerForm = this.formBuilder.group({
      profile_image: ['', null],
      curriculumNote: ['', Validators.required],
      firstName: ['', Validators.required],
      middleName: ['', null],
      lastName: ['', Validators.required],
      suffix: ['', null],
      gender: ['', null],
      maritalStatus: ['', null],
      birthDate: ['', null],
      birthPlace: ['', null],
      xHighestEducationalAttainment: ['', null],
      xLastSchoolAttended: ['', null],
      addrLine1: ['', null],
      addrLocationCity: ['', null],
      addrLocationArea: ['', null],
      addrLocationCountry: ['', null],
      xEmploymentStatus: ['', null],
      xEmployer: ['', null],
      xEmployerAddress: ['', null],
      xJobTitle: ['', null],
      birthCertificate: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, CustomValidators.validateEmailFactory()])],
      mobile: ['', Validators.required],
      facebook: ['', null],
      twitter: ['', null],
      instagram: ['', null],
      xHesNationalCertificateNumber: ['', null]
    });
  }

  ngOnInit(): void {
  }

  private onLoadAlert() {
    this.messageModalService.customAlert(this.onLoadNote);
  }

  private backToLogin() {
    this.router.navigate(['../login']);
  }

  attachFormEventHandlers() {
    this.attachCountryAreaCityHandlingFormOnly(this._registerForm, {
      names: {
        formControl: 'theForm',
        model: 'model',
        countryProperty: 'addrLocationCountry',
        areaProperty: 'addrLocationArea',
        cityProperty: 'addrLocationCity',
      },
      subscriptions: this._formEventSubscriptions
    });
  }

  attachCountryAreaCityHandlingFormOnly(formControl, config: any) {
    config || (config = { names: {} });
    // let model = config.names.model ? this[config.names.model] : this.model;
    const countryPropertyName = config.names.countryProperty ? config.names.countryProperty : 'country';
    const areaPropertyName = config.names.areaProperty ? config.names.areaProperty : 'area';
    const cityPropertyName = config.names.cityProperty ? config.names.cityProperty : 'city';
    const subscriptions = [];

    if (formControl) {
      let s;
      s = formControl.controls[cityPropertyName].valueChanges.subscribe((value: any) => {
        setTimeout(() => {
          if (formControl.controls[cityPropertyName].pristine) {
            console.debug('City control is pristine. Country-Area-City integrity check won\'t be executed.');
            return;
          }
          const city = formControl.controls[cityPropertyName].value;
          if (city) {
            const area = city ? city.area : null;
            const country = area ? area.country : null;
            formControl.patchValue({
              [areaPropertyName]: area,
              [countryPropertyName]: country,
            });
          }
        });
      });
      subscriptions.push(s);
      s = formControl.controls[areaPropertyName].valueChanges.subscribe((value: any) => {
        setTimeout(() => {
          if (formControl.controls[areaPropertyName].pristine) {
            console.debug('Area control is pristine. Country-Area-City integrity check won\'t be executed.');
            return;
          }
          const area = formControl.controls[areaPropertyName].value;
          if (area) {
            const country = area ? area.country : null;
            const city = formControl.controls[cityPropertyName].value;
            formControl.patchValue({
              [countryPropertyName]: country,
            });
            if (city && ((!city.area) || (city.area.id != area.id ))) {
              formControl.patchValue({
                [cityPropertyName]: null,
              });
            }
          } else {
            formControl.patchValue({
              [cityPropertyName]: null,
            });
          }
        });
      });
      subscriptions.push(s);
      s = formControl.controls[countryPropertyName].valueChanges.subscribe((value: any) => {
        setTimeout(() => {
          if (formControl.controls[countryPropertyName].pristine) {
            console.debug('Country control is pristine. Country-Area-City integrity check won\'t be executed.');
            return;
          }
          const country = formControl.controls[countryPropertyName].value;
          if (country) {
            const area = formControl.controls[areaPropertyName].value;
            if (area && ((!area.country) || (area.country.id != country.id ))) {
              formControl.patchValue({
                [areaPropertyName]: null,
                [cityPropertyName]: null,
              });
            }
          } else {
            formControl.patchValue({
              [areaPropertyName]: null,
              [cityPropertyName]: null,
            });
          }
        });
      });
      subscriptions.push(s);

      if (_.isArrayLikeObject(config.subscriptions)) {
        config.subscriptions.push(...subscriptions);
      }
    }
  }

  triggerSaveModal() {
    // setTimeout because formModel values are not immediately updated (async)
    setTimeout(() => {
      if (this._registerForm.valid) {
        if (this.conforme) {
          this.messageModalService.confirm(
            { message: this.onSaveModalMessage, yesButtonLabel: 'Submit', noButtonLabel: 'Cancel' },
            () => { // on confirmed
              this.save();
            },
            () => { // cancelled
              this.conforme = false;
            },
          );
        }
      } else {
        this.showRegisterFormError = true;
        this.conforme = false;
        this.cd.markForCheck();
      }
    });
  }

  private save() {
    this.lmsUser = Object.assign({});
    this.lmsUser = Object.assign(this.lmsUser, this._registerForm.value);
    const data = this.formatModelBeforeSave(this.lmsUser);

    this.loading = true;
    this.lmsUserService.register(data)
      .subscribe(
        (succ) => {
          this.loading = false;
          this.messageModalService.alert(this.onSuccessMessage);
          this._registerForm.reset();
        },
        (err) => {
          this.loading = false;
          this.messageModalService.alert(this.onSubmitErrMessage);
          this.conforme = false;
        },
      );
  }

  formatModelBeforeSave(model) {
    model = _.cloneDeep(model);

    // curriculum/Social Media/notes
    if (model.curriculumNote) model.notes = `Registered Curriculum: ${_.get(model.curriculumNote, 'name') || model.curriculumNote}`;
    if (model.facebook || model.twitter || model.instagram) {
      (model.curriculumNote) ? model.notes += "\nSocial Media:" : model.notes = "Social Media:";
      if (model.facebook) model.notes += `\n- Facebook: ${model.facebook}`;
      if (model.twitter) model.notes += `\n- Twitter: ${model.twitter}`;
      if (model.instagram) model.notes += `\n- Instagram: ${model.instagram}`;
    }

    // profile image
    if (model.profile_image) { model.profile_image = { data: model.profile_image.data }; }

    // attachments
    model.attachments = [];
    if (model.birthCertificate) {
      model.attachments.push({
        name: 'Birth Certificate',
        description: null,
        file: {
          data: model.birthCertificate.data,
          originalFilename: model.birthCertificate.originalFilename,
          mimeType: model.birthCertificate.mimeType,
        },
      });
    }
    if (model.diploma) {
      model.attachments.push({
        name: 'Diploma',
        description: null,
        file: {
          data: model.diploma.data,
          originalFilename: model.diploma.originalFilename,
          mimeType: model.diploma.mimeType,
        },
      });
    }
    if (model.hesNationalCert) {
      model.attachments.push({
        name: 'HES National Certificate',
        description: null,
        file: {
          data: model.hesNationalCert.data,
          originalFilename: model.hesNationalCert.originalFilename,
          mimeType: model.hesNationalCert.mimeType,
        },
      });
    }
    if (model.otherFiles) {
      model.attachments.push({
        name: 'Other Files - 1',
        description: null,
        file: {
          data: model.otherFiles.data,
          originalFilename: model.otherFiles.originalFilename,
          mimeType: model.otherFiles.mimeType,
        },
      });
    }

    // temporarily hardcoded
    model.company = { id: 1 };
    model.companyBranch = { id: 1 };

    // uppercase first letters of names
    if (model.firstName) { model.firstName = model.firstName.toLowerCase().split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' '); }
    if (model.lastName) { model.lastName = model.lastName.toLowerCase().split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' '); }
    if (model.middleName) { model.middleName = model.middleName.toLowerCase().split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' '); }

    if (model.birthDate) { model.birthDate = moment(model.birthDate).toDate().getTime(); }
    return {
      properties: model,
    };
  }
}

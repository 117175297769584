import { ApiService } from '../../api.service';
import { BaseBreadService, BreadServiceDecorator } from '../../base-bread.service';
import { Injectable } from '@angular/core';

@BreadServiceDecorator({
  resourceName: 'employee'
})

@Injectable()
export class EmployeeService extends BaseBreadService {
  constructor(public apiService: ApiService) {
    super(null, null, apiService);
  }

  // TODO: Remove dependence of other modules on this function. Remove function when no longer needed.
  saveEmployee(employee: any) {
    return employee.id === 0 ? this.add(employee) : this.edit(employee.id, employee);
  }
}

import {
  Component,
  Injectable,
  ChangeDetectorRef,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ComponentFactoryResolver,
} from '@angular/core';

import { MessageModal, MessageModalService } from '../message-modal';

import * as _ from 'lodash';

@Component({
  selector: 'xsi-lms-message-modal',
  templateUrl: 'lms-message-modal.html',
  styleUrls: ['lms-message-modal.scss'],
  host: { 'class': 'modal' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class LMSMessageModal extends MessageModal {

  private customMessageHTMLString = '';

  constructor(protected _cd: ChangeDetectorRef) {
    super(_cd);
  }

  // @override
  show(config, onYes = null, onNo = null) {
    setTimeout(() => {
      if (this.yesButton) this.yesButton.nativeElement.focus();
    });

    if (!this.hidden) {
      throw 'Message modal is already shown';
    }

    this._yesCallback = onYes;
    this._noCallback = onNo;

    if (config) {
      if (config.htmlString) {
        this.customMessageHTMLString = config.htmlString;
      } else {
        this.customMessageHTMLString = '';
        this.message = config.message;
        this.messageType = config.messageType;
      }

      this.yesButtonLabel = config.yesButtonLabel;
      this.noButtonLabel = config.noButtonLabel;
    }

    this.hidden = false;
    this._cd.markForCheck();
  }
}

@Injectable()
export class LMSMessageModalService extends MessageModalService {
  constructor(protected _componentResolver: ComponentFactoryResolver) {
    super(_componentResolver);
  }

  message(config, yesCallback = null, noCallback = null) {
    let show = () => {
      return this._cachedComponent.instance.show(config,
        () => { if (_.isFunction(yesCallback)) { yesCallback(); } },
        () => { if (_.isFunction(noCallback)) { noCallback(); } }
      );
    };
    if (this._cachedComponent) {
      show();
    } else {
      Promise.resolve(this._componentResolver.resolveComponentFactory(LMSMessageModal)).then((factory) => {
        let componentRef = this._contentContainer.createComponent(factory, this._contentContainer.length, this._contentContainer.injector);
        this._cachedComponent = componentRef;
        show();
      });
    }
  }

  // @override
  alert(message: string, callback?: any) {
    return this.message({ message: message, noButtonLabel: 'Close' }, callback);
  }

  customAlert(htmlString: string, callback?: any) {
    return this.message({ htmlString, noButtonLabel: 'Close' }, callback);
  }
}


import { Component, OnInit, ChangeDetectorRef, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { BaseBreadService } from '../../../shared/services/base-bread.service';
import { HttpClient } from '@angular/common/http';
import { LmsUserService } from '../../../shared/services/api/lms/lms-user.service';
import { AuthService } from '../../../shared/services/auth.service';
import * as moment from 'moment';

import * as _ from 'lodash';
import { MessageModalService } from '../../../commons/message-modal/message-modal';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { SecUserService } from '../../../shared/services/sec-user.service';

function PasswordValidator(formGroup: FormGroup) {
  const newPassword = formGroup.controls['newPassword'].value;
  const newPasswordConfirm = formGroup.controls['confirmPassword'].value;

  if (!newPasswordConfirm) { formGroup.controls['confirmPassword'].setErrors({required: true}); }
  else if (newPassword !== newPasswordConfirm) { formGroup.controls['confirmPassword'].setErrors({errorMatch: true}); }
  else { formGroup.controls['confirmPassword'].setErrors(null); }
}
@Component({
  selector: 'app-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileDetailsComponent implements OnInit {

  defaultImageName: string = null;
  protected _busy: boolean = false;
  protected _load: boolean = false;
  protected _imageSource: string = null;
  protected _error: boolean;
  protected exists: boolean;
  user = null;
  userStatus = '';
  protected _eventEmitter = new EventEmitter();

  passwordForm = null;
  showPasswordFormError = false;

  constructor(
    protected _cd: ChangeDetectorRef,
    protected _baseBreadService: BaseBreadService,
    protected http: HttpClient,
    protected formBuilder: FormBuilder,
    private authService: AuthService,
    private _lmsUserService: LmsUserService,
    private messageModalService: MessageModalService,
    private secUserService: SecUserService
  ) {
    this.buildPasswordForm();
  }

  buildPasswordForm() {
    this.passwordForm = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });

    this.passwordForm.setValidators(PasswordValidator);
  }

  ngOnInit(): void {
    this._eventEmitter.emit(true);
    this._lmsUserService.read(this.authService.getActiveLmsUser().object.id).subscribe((res) => {
      this.user = res.data;
      this.userStatus = _.get(this.user, 'lmsUserStatus.name');
      this._eventEmitter.emit(false);
      this.loadImages(this.user);
    }, (err) => {
      this._eventEmitter.emit(false);
      this.messageModalService.error('Failed to load data');
      this._cd.markForCheck();
    });
  }

  downloadImage(resourceName: string, id: number, name: string = this.defaultImageName) {
    this.startBusy();
    this.clearError();
    this._load = false;
    this._imageSource = null;
    const imageSource = this._baseBreadService.getResourceDownloadUrl(resourceName, { id: id, name: name }, name) + `&_timestamp=${new Date().getTime()}`;
    this._imageSource = imageSource;
    this.http.get(imageSource).subscribe(
      (response) => {
        this.endBusy();
        this._load = true;
        this._imageSource = imageSource;
      },
      (error) => {
        this.setError();
        this.endBusy();
        this.exists = error.status !== 404;
      }
    );
    this._cd.markForCheck();
  }

  startBusy() {
    this._busy = true;
    this._cd.markForCheck();
  }

  endBusy() {
    this._busy = false;
    this._cd.markForCheck();
  }

  onImageLoad(event: Event) {
    this._load = true;
    this.clearError();
    this.endBusy();
    this._cd.markForCheck();
    this.exists = true;
  }

  onImageError(event: Event) {
    this._load = false;
    this.setError();
    this.endBusy();
    this._imageSource = null;
    this._cd.markForCheck();
    this.exists = false; // FOR NOW: If error, assume file doesn't exist.
  }

  clearError() {
    this._error = false;
    this._cd.markForCheck();
  }

  setError() {
    this._error = true;
    this._cd.markForCheck();
  }

  loadImages(model) {
    this.downloadImage('lmsUser', model.id, 'profile_image');
  }

  getEventEmitter() {
    return this._eventEmitter;
  }

  formatBirthday(date) {
    if (!date) { return; }

    return moment(date).format('MM/DD/YYYY');
  }

  savePassword() {
    if (this.passwordForm.valid) {

      let obj = {
        id: this.authService.getStoredUserInfo().userDetails.id,
        oldPassword: this.passwordForm.get('oldPassword').value,
        newPassword: this.passwordForm.get('newPassword').value,
      };

      this._eventEmitter.emit(true);
      this.secUserService.changePassword(obj).subscribe((res) => {
        this._eventEmitter.emit(false);
        this.showPasswordFormError = false;
        this.messageModalService.alert('Password saved.');
        this.passwordForm.reset();
      }, (err) => {
        this._eventEmitter.emit(false);
        this.showPasswordFormError = false;
        this.messageModalService.error(err.error.data);
      });
    } else {
      this.showPasswordFormError = true;
    }
  }

}

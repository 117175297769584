import { Injectable } from '@angular/core';

import { ConfigService } from './config.service';
import { ApiRequestDataService } from './api-request-data.service';

import * as _ from 'lodash';

export enum AppFeature {
  LOGIN__REQUIRE_LMSUSER_PROFILE = 'login__require_lmsuser_profile',
}
@Injectable()
export class AppConfigService {
  protected _config = null;

  constructor(
    private configService: ConfigService,
    private apiRequestDataService: ApiRequestDataService,
  ) {
  }

  loadConfig() {
    return this.configService.loadConfig().toPromise().then((data) => {
      let config = this.configService.getConfig();
      let ecommerceSiteId = _.defaultTo(_.get(config, 'ecommerceSite'), 0);
      this.apiRequestDataService.setRequestHeader('X-App-EcommerceSite', ecommerceSiteId.toString());
      this._config = config;
      return data;
    });
  }

  hasFeature(feature) {
    return _.get(this._config, `features.${feature}`);
  }

  public getConfig(path: string, defaultValue: any = null): any {
    return _.get(this._config, path, defaultValue);
  }
}

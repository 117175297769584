import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({name: 'xsiName'})
export class NamePipe implements PipeTransform {
  transform(obj: any) {
    if (_.isNil(obj)) return '';
    return obj.name || _.compact([obj.firstName, obj.lastName]).join(' ');
  }
}

import * as _ from 'lodash';

export class NumberUtils {
  static toNumber(val, defaultVal: number = null) {
    let ret;
    if (_.isNull(val) || _.isUndefined(val)) {
      ret = defaultVal;
    } else {
      ret = val != '' ? +val : null;
    }
    if (_.isNaN(ret)) {
      ret = null;
    }
    return ret
  }
}

export class YesNoUtils {

  static options: Array<any> = [
    true,
    false,
  ];

  static displayText(x) {
    if (_.isNil(x)) return null;
    if (x === true) return 'Yes';
    if (x === false) return 'No';
    return 'Unknown';
  }
}

export function isScrolledIntoView(el, adjustments = {top: 0, bottom: 0, left: 0, right: 0}) {
  let top = el.getBoundingClientRect().top;
  let bottom = el.getBoundingClientRect().bottom;
  let left = el.getBoundingClientRect().left;
  let right = el.getBoundingClientRect().right;

  let isVerticallyVisible = (top >= 0 + adjustments.top) && (bottom <= window.innerHeight - adjustments.bottom);
  let isHorizontallyVisible = (left >= 0 + adjustments.left) && (right <= window.innerWidth - adjustments.right);

  return isVerticallyVisible && isHorizontallyVisible;
}

export function arrayBufferToBase64(arrayBuffer) {
  let base64 = '';
  let b64Lookup = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

  let bytes = new Uint8Array(arrayBuffer);
  let byteLength = bytes.byteLength;
  let byteRemainder = byteLength % 3;
  let mainLength = byteLength - byteRemainder;

  let num24bit = 0;
  const MASK_6BIT = 63; // binary = 111111

  // One iteration converts three octets to four characters
  for (let i = 0; i < mainLength; i = i + 3) {
    num24bit = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2]; // 24-bit integer

    // four 6-bit segments
    let seg1, seg2, seg3, seg4;
    seg1 = (num24bit & (MASK_6BIT << 18)) >> 18;
    seg2 = (num24bit & (MASK_6BIT << 12)) >> 12;
    seg3 = (num24bit & (MASK_6BIT << 6)) >>  6;
    seg4 = num24bit & MASK_6BIT;

    base64 += b64Lookup[seg1] + b64Lookup[seg2] + b64Lookup[seg3] + b64Lookup[seg4];
  }

  if (byteRemainder === 1) {
    num24bit = bytes[mainLength];
    let seg1 = (num24bit & (MASK_6BIT << 2)) >> 2;
    let seg2 = (num24bit & 3) << 4; // pad to right with 0s
    base64 += b64Lookup[seg1] + b64Lookup[seg2] + '==';
  } else if (byteRemainder === 2) {
    num24bit = (bytes[mainLength] << 8) | bytes[mainLength + 1];
    let seg1 = (num24bit & (MASK_6BIT << 10)) >> 10;
    let seg2 = (num24bit & (MASK_6BIT << 4)) >> 4;
    let seg3 = (num24bit & 15) << 2; // pad to right with 0s
    base64 += b64Lookup[seg1] + b64Lookup[seg2] + b64Lookup[seg3] + '=';
  }

  return base64;
}

// export function dispatchWindowResizeEvent() {
//   let event = window.document.createEvent('UIEvents');
//   event.initUIEvent('resize', true, false, window, 0);
//   setTimeout(() => { //settimeout for nvd3 charts not resizing immidiately after toggling sidebar 
//     window.dispatchEvent(event);
//   });
// }

export function printContent(content, style) {
  let popupWin;
  popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
  popupWin.document.open();
  popupWin.document.write(`
    <html>
      <head>
        <style>
          ${style || ''}
        </style>
      </head>
      <body onload="window.print();window.close()">
        <div>
          ${content}
          </div>
        </body>
    </html>`
  );
  popupWin.document.close();
}

export function getXhrResponseErrorMessage(err, message = null) {
  let errMessage = null;
  try {
    errMessage = err.json().message;
  } catch (e) {
    try {
      errMessage = err.text();
    } catch (e) {
    }
  }
  let msgs = _.filter([message, errMessage], x => x);
  return _.join(msgs, '\n');
}

import { RequestMethod } from './enums/request-methods';
import { ApiService } from './api.service';
import { BaseBreadService } from './base-bread.service';
import { AppSettingService } from './api';

import * as _ from 'lodash';

export class BaseBreadWithApprovalService extends BaseBreadService {
  constructor(public apiService: ApiService, public appSettingService?: AppSettingService) {
    super(null, null, apiService);
  }

  approve(object: any) {
    return this.apiService.requestToJsonApi(RequestMethod.POST, this.getRequestUrl('approve', null), null, { 'properties': object.getPropertiesMap ? object.getPropertiesMap.call(this) : object });
  }

  cancelApproval(object: any) {
    return this.apiService.requestToJsonApi(RequestMethod.POST, this.getRequestUrl('cancelApproval', null), null, { 'properties': object.getPropertiesMap ? object.getPropertiesMap.call(this) : object });
  }

  reject(object: any) {
    return this.apiService.requestToJsonApi(RequestMethod.POST, this.getRequestUrl('reject', null), null, { 'properties': object.getPropertiesMap ? object.getPropertiesMap.call(this) : object });
  }

  markAsFinal(object: any) {
    return this.apiService.requestToJsonApi(RequestMethod.POST, this.getRequestUrl('markAsFinal', null), null, { 'properties': object.getPropertiesMap ? object.getPropertiesMap.call(this) : object });
  }

  unmarkAsFinal(object: any) {
    return this.apiService.requestToJsonApi(RequestMethod.POST, this.getRequestUrl('unmarkAsFinal', null), null, { 'properties': object.getPropertiesMap ? object.getPropertiesMap.call(this) : object });
  }

  fetchNumApproversRequired(moduleName: string = null, resourceName: string = null) {
    if (_.isNil(resourceName)) {
      resourceName = _.snakeCase(this._resourceName);
    }
    if (_.isNil(moduleName)) {
      if (this._moduleName) {
        moduleName = _.snakeCase(this._moduleName);
      } else if (resourceName) {
        let parts = resourceName.split('_');
        moduleName = parts[0];
      }
    }
    let obj = { name: `${moduleName}.num_approvers_required.${resourceName}` };
    return this.readAppSettingByName(obj);
  }

  readAppSettingByName(object: any, noBranchContext: boolean = null) {
    let obj = _.cloneDeep(object || {});
    if (this.appContextService) {
      if (_.isNil(obj.company)) {
        console.log('nil company', this.appContextService.getCompany());
        obj.company = this.appContextService.getCompany();
      }
      if (_.isNil(obj.companyBranch)) {
        obj.companyBranch = this.appContextService.getBranch();
      }
    }
    return this.apiService.requestToJsonApi(
      RequestMethod.POST, this.getRequestUrl('readByName', 'appSetting'), null,
      { 'properties': obj },
      false, false, noBranchContext,
    );
  }
}

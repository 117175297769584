import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

import {
  HttpClient,
  HttpResponse,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';

import * as _ from 'lodash';

@Injectable()
export class ConfigService {

  protected _config: any = null;

  constructor(private http: HttpClient) {
  }

  getApiBaseUrl() {
    let serverUrl = localStorage.getItem('serverUrl');
    let serverRootUrl = localStorage.getItem('serverRootUrl');
    let defaultServerUrl = null;
    let defaultRootUrl = null;
    let devMode = window.location.hostname == 'localhost' || window.location.hostname == '127.0.0.1';
    if (environment.production === false && (devMode)) {
      // defaultRootUrl = 'http://dev0.xscribe.com.ph:19080';
      // defaultRootUrl = 'http://dev0.xscribe.com.ph:18080';
      // defaultRootUrl = 'http://192.168.10.176:9080';
      defaultRootUrl = 'http://4161-1.test1.xscribeinc.com:8080';
      // defaultRootUrl = 'http://192.168.10.168:9080';
      // defaultRootUrl = 'http://192.168.10.176:8080';
      // defaultRootUrl = 'http://dev1.xscribe.com.ph:80';
    } else {
      let protocol = window.location.protocol;
      let hostname = window.location.hostname;
      let port = Number(window.location.port || (protocol === 'https' ? 443 : 80));
      let serverPort = port + (port <= 2000 ? 8000 : -1);
      defaultRootUrl = `${ protocol }//${ hostname }${ serverPort ? ':' + serverPort : ''}`;
    }
    defaultServerUrl = (serverRootUrl ? serverRootUrl : defaultRootUrl) + '/api';
    return serverUrl ? serverUrl : defaultServerUrl;
  }

  getPublicApiBaseUrl() {
    let serverUrl = localStorage.getItem('serverUrl');
    let serverRootUrl = localStorage.getItem('serverRootUrl');
    let defaultServerUrl = null;
    let defaultRootUrl = null;
    let devMode = window.location.hostname == 'localhost' || window.location.hostname == '127.0.0.1';
    if (environment.production === false && (devMode)) {
      // defaultRootUrl = 'http://dev0.xscribe.com.ph:19080';
      defaultRootUrl = 'http://4161-1.test1.xscribeinc.com:8080';
      // defaultRootUrl = 'http://192.168.10.176:9080';
      // defaultRootUrl = 'http://dev1.xscribe.com.ph:80';
    } else {
      let protocol = window.location.protocol;
      let hostname = window.location.hostname;
      let port = Number(window.location.port || (protocol === 'https' ? 443 : 80));
      let serverPort = port + (port <= 2000 ? 8000 : -1);
      defaultRootUrl = `${ protocol }//${ hostname }${ serverPort ? ':' + serverPort : ''}`;
    }
    defaultServerUrl = (serverRootUrl ? serverRootUrl : defaultRootUrl) + '/public/api';
    return serverUrl ? serverUrl : defaultServerUrl;
  }

  loadConfig() {
    var headers: HttpHeaders = new HttpHeaders();
    headers.append('Accept', '*/*');
    let obs = this.http.get('assets/config.json', {headers});
    obs.subscribe((data) => {
      this._config = data;
      console.debug('config', data);
    });

    return obs;
  }

  getConfig() {
    return _.cloneDeep(this._config);
  }
}

import { Component, Input, Output, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { PaginationComponent } from '../../../../../commons/pagination/pagination.component';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-assessment-test-preview-pagination',
  templateUrl: './assessment-test-preview-pagination.component.html',
  styleUrls: ['./assessment-test-preview-pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssessmentTestPreviewPaginationComponent extends PaginationComponent{

  @Input()  maxFilter: any;
  @Input()  pagination: any = {
    total: 1000,
    max: 20,
    offset: 0,
  };
  @Input() disabled: boolean = false;
  @Input() currentPart = null;
  @Output() refresh = new EventEmitter();
  @Output() submitClick = new EventEmitter();

  _buttonType = null;

  prevButtonDisabled = true;
  nextButtonDisabled = false;

  nextChapterDisabled = true;
  previousChapterDisabled = true;

  constructor(_formBuilder: FormBuilder, _cd: ChangeDetectorRef) {
    super(_formBuilder, _cd);
  }

  nextPageClick() {
    this._buttonType = 'next';
    this.nextPage();
  }

  prevPageClick() {
    this._buttonType = 'prev';
    this.previousPage();
  }

  nextPage() {
    this.updatePage(this.currentPage);
  }

  previousPage() {
    this.updatePage(this.currentPage - 2);
  }

  refreshEmit(dataToEmit = null) {
    this.refresh.emit(this._buttonType);
  }

  toggleButton(buttonType, value) {
    if (buttonType === 'next') {
      this.nextButtonDisabled = value;
    } else if (buttonType === 'nextChapter') {
      this.nextChapterDisabled = value;
    } else if (buttonType === 'previousChapter') {
      this.previousChapterDisabled = value;
    } else {
      this.prevButtonDisabled = value;
    }
  }

  onSubmit() {
    this.submitClick.emit();
  }


}

// Add the services you need here.
import { ConfigService } from './config.service';
export { ConfigService } from './config.service';
import { AppContextService } from './app-context.service';
export { AppContextService } from './app-context.service';

import { LocationCountryPublicApiService } from './api/public/location-country-public-api.service';
import { LocationAreaPublicApiService } from './api/public/location-area-public-api.service';
import { LocationCityPublicApiService } from './api/public/location-city-public-api.service';

export { LocationCountryPublicApiService } from './api/public/location-country-public-api.service';
export { LocationAreaPublicApiService } from './api/public/location-area-public-api.service';
export { LocationCityPublicApiService } from './api/public/location-city-public-api.service';

import { CustomerService } from './api/customer/customer.service';
export { CustomerService } from './api/customer/customer.service';


import { CompanyService } from './api/company/company.service';
export { CompanyService } from './api/company/company.service';
import { CompanyBranchService } from './api/company-branch/company-branch.service';
export { CompanyBranchService } from './api/company-branch/company-branch.service';
import { EmployeeService } from './api/employee/employee.service';
export { EmployeeService } from './api/employee/employee.service';
import { AppSettingService } from './api/common/app-setting.service';
export { AppSettingService } from './api/common/app-setting.service';
// import { AuthGuard } from '../../../app-customer-portal/app-customer-portal.routes';

import { LmsPaymentMethodService } from './api/lms/lms-payment-method.service';
import { LmsLessonTypeService } from './api/lms/lms-lesson-type.service';
import { LmsUserStatusService } from './api/lms/lms-user-status.service';
import { LmsUserChecklistActivityService } from './api/lms/lms-user-checklist-activity.service';
import { LmsCertificateIssuanceService } from './api/lms/lms-certificate-issuance.service';
import { LmsLessonScheduleService } from './api/lms/lms-lesson-schedule.service';
import { LmsCourseLessonService } from './api/lms/lms-course-lesson.service';
import { LmsCourseService } from './api/lms/lms-course.service';
import { LmsLessonScheduleInviteService } from './api/lms/lms-lesson-schedule-invite.service';
import { LmsVenueService } from './api/lms/lms-venue.service';
import { LmsEnrollmentService } from './api/lms/lms-enrollment.service';
import { LmsLessonTestTemplateService } from './api/lms/lms-lesson-test-template.service';
import { LmsEnrollmentLessonContentService } from './api/lms/lms-enrollment-lesson-content.service';
import { LmsLessonContentService } from './api/lms/lms-lesson-content.service';
import { LmsUserService } from './api/lms/lms-user.service';
import { LmsEnrollmentLessonService } from './api/lms/lms-enrollment-lesson.service';
import { LmsCertificateService } from './api/lms/lms-certificate.service';
import { LmsEnrollmentStatusService } from './api/lms/lms-enrollment-status.service';
import { LmsLessonService } from './api/lms/lms-lesson.service';
import { LmsLessonScheduleStatusService } from './api/lms/lms-lesson-schedule-status.service';
import { LmsEnrollmentLessonTestService } from './api/lms/lms-enrollment-lesson-test.service';
import { LmsLessonHostService } from './api/lms/lms-lesson-host.service';
import { LmsCoursePublicApiService } from './api/public/lms/lms-course-public-api.service';
export { LmsPaymentMethodService } from './api/lms/lms-payment-method.service';
export { LmsLessonTypeService } from './api/lms/lms-lesson-type.service';
export { LmsUserStatusService } from './api/lms/lms-user-status.service';
export { LmsUserChecklistActivityService } from './api/lms/lms-user-checklist-activity.service';
export { LmsCertificateIssuanceService } from './api/lms/lms-certificate-issuance.service';
export { LmsLessonScheduleService } from './api/lms/lms-lesson-schedule.service';
export { LmsCourseLessonService } from './api/lms/lms-course-lesson.service';
export { LmsCourseService } from './api/lms/lms-course.service';
export { LmsLessonScheduleInviteService } from './api/lms/lms-lesson-schedule-invite.service';
export { LmsVenueService } from './api/lms/lms-venue.service';
export { LmsEnrollmentService } from './api/lms/lms-enrollment.service';
export { LmsLessonTestTemplateService } from './api/lms/lms-lesson-test-template.service';
export { LmsEnrollmentLessonContentService } from './api/lms/lms-enrollment-lesson-content.service';
export { LmsLessonContentService } from './api/lms/lms-lesson-content.service';
export { LmsUserService } from './api/lms/lms-user.service';
export { LmsEnrollmentLessonService } from './api/lms/lms-enrollment-lesson.service';
export { LmsCertificateService } from './api/lms/lms-certificate.service';
export { LmsEnrollmentStatusService } from './api/lms/lms-enrollment-status.service';
export { LmsLessonService } from './api/lms/lms-lesson.service';
export { LmsLessonScheduleStatusService } from './api/lms/lms-lesson-schedule-status.service';
export { LmsEnrollmentLessonTestService } from './api/lms/lms-enrollment-lesson-test.service';
export { LmsLessonHostService } from './api/lms/lms-lesson-host.service';
export { LmsCoursePublicApiService } from './api/public/lms/lms-course-public-api.service';

import { TestPartQuestionService } from './api/test/test-part-question.service';
import { TestQuestionCategoryService } from './api/test/test-question-category.service';
import { TestTemplatePartQuestionService } from './api/test/test-template-part-question.service';
import { TestTemplateService } from './api/test/test-template.service';
import { TestService } from './api/test/test.service';
import { TestQuestionService } from './api/test/test-question.service';
import { TestTemplatePartService } from './api/test/test-template-part.service';
export { TestPartQuestionService } from './api/test/test-part-question.service';
export { TestQuestionCategoryService } from './api/test/test-question-category.service';
export { TestTemplatePartQuestionService } from './api/test/test-template-part-question.service';
export { TestTemplateService } from './api/test/test-template.service';
export { TestService } from './api/test/test.service';
export { TestQuestionService } from './api/test/test-question.service';
export { TestTemplatePartService } from './api/test/test-template-part.service';

export const API_SERVICE_PROVIDERS: any[] = [
  AppContextService,
  ConfigService,

  LocationCountryPublicApiService,
  LocationAreaPublicApiService,
  LocationCityPublicApiService,

  CustomerService,
  CompanyService,
  CompanyBranchService,
  EmployeeService,
  AppSettingService,
  // AuthGuard

  LmsPaymentMethodService,
  LmsLessonTypeService,
  LmsUserStatusService,
  LmsUserChecklistActivityService,
  LmsCertificateIssuanceService,
  LmsLessonScheduleService,
  LmsCourseLessonService,
  LmsCourseService,
  LmsLessonScheduleInviteService,
  LmsVenueService,
  LmsEnrollmentService,
  LmsLessonTestTemplateService,
  LmsEnrollmentLessonContentService,
  LmsLessonContentService,
  LmsUserService,
  LmsEnrollmentLessonService,
  LmsCertificateService,
  LmsEnrollmentStatusService,
  LmsLessonService,
  LmsLessonScheduleStatusService,
  LmsEnrollmentLessonTestService,
  LmsLessonHostService,
  LmsCoursePublicApiService,

  TestPartQuestionService,
  TestQuestionCategoryService,
  TestTemplatePartQuestionService,
  TestTemplateService,
  TestService,
  TestQuestionService,
  TestTemplatePartService,
];

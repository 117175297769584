import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Location } from '@angular/common';

import * as moment from 'moment';
import * as _ from 'lodash';
import { LmsBaseCalendarEventService } from './base-calendar.service';
import { LmsEnrollmentLessonService, LmsLessonScheduleInviteService } from '../../shared/services/api';
import { CalendarConfig } from './definitions';
import { LmsEnrollmentLessonModel } from '../../shared/models/lms/lms-enrollment-lesson.model';
import { LmsLessonScheduleInviteModel } from '../../shared/models/lms/lms-lesson-schedule-invite.model';

import { MessageModalService } from '../../commons/message-modal/message-modal';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarComponent implements OnInit {

  currentMonthFormatted = moment().clone().startOf('month').format('MMMM YYYY');
  currentMonth = moment().clone().startOf('month');
  daysofMonth = [];
  daysOfWeek = [];
  currentDay = moment().clone().format('D');
  monthofCurrentDay = moment().clone().startOf('month');
  monthRowNum = [0, 1, 2, 3, 4];
  selectedDate;
  loading = false;
  events = [];
  selectedDayEvents = [];
  openPicker = false;
  protected eventField = 'lmsLessonSchedule';
  pickerYearRange = null;
  pickerYear: any = moment().clone().format('YYYY');
  monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  yearView = false;

  constructor(
    private _location: Location,
    private lmsBaseCalendarEventService: LmsBaseCalendarEventService,
    private lmsEnrollmentLessonService: LmsEnrollmentLessonService,
    private lmsLessonScheduleInviteService: LmsLessonScheduleInviteService,
    private messageModalService: MessageModalService,
    private _cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.getDaysofWeek();
    this.getDays();
    this.selectedDate = moment().clone().format('MM/DD/YYYY');
    this.loadData(true);
  }

  getDaysofWeek(){
    const days = [];
    for (let i = 0; i <= 6; i++) {
      days.push(moment().startOf('week').add(i, 'days').format('dd').slice(0, 1));
    }
    this.daysOfWeek = days;
  }

  getDays(){
    this.monthRowNum = [];
    for (let i = 0; i < this.getWeeksOfMonth(moment(this.currentMonth)); i++) {
      this.monthRowNum.push(i);
    }

    const days = [];
    this.daysofMonth = [];
    for (let i = 0; i < 42; i++) {
      if (moment(this.currentMonth).day(i).month() === moment(this.currentMonth).month()) {
        days.push({date: moment(this.currentMonth).day(i).format('MM/DD/YYYY'), day: moment(this.currentMonth).day(i).format('D'), isPartofMonth: true});
      } else {
        days.push({date: moment(this.currentMonth).day(i).format('MM/DD/YYYY'), day: moment(this.currentMonth).day(i).format('D'), isPartofMonth: false});
      }
    }

    while (days.length) {
      this.daysofMonth.push(days.splice(0, 7));
    }
  }

  previousMonth() {
    this.currentMonth = moment(this.currentMonth).subtract(1, 'month');
    this.currentMonthFormatted = moment(this.currentMonth).format('MMMM YYYY');
    this.daysofMonth = [];
    this.getDays();
    if (moment().startOf('month').isSame(this.currentMonth)) {
      this.selectedDate = moment().clone().format('MM/DD/YYYY');
      this.loadData(true);
    } else {
      this.selectedDate = null;
      this.loadData();
    }
  }

  nextMonth() {
    this.currentMonth = moment(this.currentMonth).add(1, 'month');
    this.currentMonthFormatted = moment(this.currentMonth).format('MMMM YYYY');
    this.daysofMonth = [];
    this.getDays();
    if (moment().startOf('month').isSame(this.currentMonth)) {
      this.selectedDate = moment().clone().format('MM/DD/YYYY');
      this.loadData(true);
    } else {
      this.selectedDate = null;
      this.loadData();
    }
  }

  // we can use this on erp calendar components (appointment, task)
  getWeeksOfMonth(date) {
    const dateFirst = moment(date).date(1);
    const dateLast = moment(date).date(date.daysInMonth());
    const startWeek = dateFirst.week();
    const endWeek = dateLast.week();
    if (endWeek < startWeek) {
      // cater to end of year (dec/jan)
      return dateFirst.weeksInYear() - startWeek + 1 + endWeek;
    } else {
      return endWeek - startWeek + 1;
    }
  }

  back() {
    this._location.back();
  }

  selectDay(day) {
    this.selectedDate = day.date;
    if (!day.isPartofMonth) {
      let diff = moment(day.date, 'MM/DD/YYYY').diff(this.currentMonth, 'months', true);

      if (diff !== 0 && Math.floor(diff) !== 0) {
        this.currentMonth = moment(this.currentMonth).add(Math.floor(diff), 'month');
        this.currentMonthFormatted = moment(this.currentMonth).format('MMMM YYYY');
        this.daysofMonth = [];
        this.getDays();
        this.loadData(true);
        this._cd.markForCheck();
      }
    } else {
      this.getEventsForSelectedDay();
    }
  }

  formatDate(date) {
    return moment(date, 'MM/DD/YYYY').format('MMMM D');
  }

  loadData(init = false) {
    this.loading = true;
    const calendarConfig = this.createCalendarConfig();
    const dateRangeQuery = {
      dateStart: moment(this.currentMonth).startOf('month').toDate().getTime(),
      dateEnd: moment(this.currentMonth).endOf('month').startOf('day').toDate().getTime(),
    };
    const queryOptions = this.buildQueryOption(dateRangeQuery.dateStart, dateRangeQuery.dateEnd);
    this.lmsBaseCalendarEventService.fetchEvents(calendarConfig, queryOptions).then((res: any) => {
      this.events = res;
      this.events = _.sortBy(this.events, (o) => moment(_.get(o, `${this.eventField}${this.eventField ? '.' : ''}startDate`)).toDate().getTime()).reverse();

      if (init){
        this.getEventsForSelectedDay();
      }

      this.loading = false;
      this._cd.markForCheck();
      // this.loading = false;
      // this.loadingError = false;
      // this._cd.markForCheck();
    })
    .catch((res) => {
      this.loading = false;
      this.messageModalService.error('Failed to load data.');
      this._cd.markForCheck();
      // this.loading = false;
      // this.loadingError = true;
      // this._cd.markForCheck();
    });
  }

  createCalendarConfig(): CalendarConfig[] {
    return [
      {
        service: this.lmsEnrollmentLessonService,
        additionalFilterCondition: [{ property: 'lmsLessonSchedule', op: 'isNotNull' }],
        preprocessFn: (fetchedEvents: LmsEnrollmentLessonModel[]) => {
          if (fetchedEvents.length) {
            return fetchedEvents.map((event) => {
              // lmsLessonSchedule is filtered isNotNull so assumed there's always a value
              event.lmsLessonSchedule.scheduleType = 'enrollmentLesson';
              return event;
            });
          }
          return [];
        },
      },
      {
        service: this.lmsLessonScheduleInviteService,
        additionalFilterCondition: [],
        preprocessFn: (fetchedEvents: LmsLessonScheduleInviteModel[]) => {
          if (fetchedEvents.length) {
            return fetchedEvents.map((event) => {
              if (_.get(event, 'lmsLessonSchedule')) { event.lmsLessonSchedule.scheduleType = 'lessonScheduleInvite'; }
              event.editable = false;
              return event;
            });
          }
          return [];
        },
      },
    ];
  }

  buildQueryOption(dateStart: number, dateEnd: number) {
    const dateConditions1 = [];
    const dateConditions2 = [];
    const dateConditions3 = [];
    const millisecondsInADay = 86400000;
    const queryOptions: any = {};
    const lowerBound = dateStart || null;
    const upperBoundExclusive = dateEnd ? dateEnd + millisecondsInADay : null; // add 1 day
    const endDateField = `${this.eventField}${this.eventField ? '.' : ''}endDate`;
    const startDateField = `${this.eventField}${this.eventField ? '.' : ''}startDate`;

    if (lowerBound !== null) {
      dateConditions1.push({ property: startDateField, op: 'ge', value: lowerBound, dataType: 'date' });
      dateConditions2.push({ property: endDateField, op: 'ge', value: lowerBound, dataType: 'date' });
      dateConditions3.push({ property: startDateField, op: 'lt', value: lowerBound, dataType: 'date' });
    }
    if (upperBoundExclusive !== null) {
      dateConditions1.push({ property: startDateField, op: 'lt', value: upperBoundExclusive, dataType: 'date' });
      dateConditions2.push({ property: endDateField, op: 'lt', value: upperBoundExclusive, dataType: 'date' });
      dateConditions3.push({ property: endDateField, op: 'ge', value: upperBoundExclusive, dataType: 'date' });
    }

    const dateConditions = (lowerBound !== null) || (upperBoundExclusive !== null) ? [{
      compound: true,
      filter: {
        joinOp: 'or',
        conditions: [
          { compound: true, filter: { joinOp: 'and', conditions: dateConditions1 } },
          { compound: true, filter: { joinOp: 'and', conditions: dateConditions2 } },
          { compound: true, filter: { joinOp: 'and', conditions: dateConditions3 } },
        ],
      },
    }] : null;

    queryOptions.filter = {
      conditions: [
        ...(dateConditions || []),
      ],
    };

    queryOptions.sort = [
      {
        property: `${this.eventField}${this.eventField ? '.' : ''}startDate`,
        dir: 'desc'
      }
    ];

    return queryOptions;
  }

  getEventsForSelectedDay() {
    this.selectedDayEvents = _.filter(this.events, (o) => {
      let eventStart = moment(_.get(o, `${this.eventField}${this.eventField ? '.' : ''}startDate`));
      let selectedDay = moment(this.selectedDate, 'MM/DD/YYYY');
      if (eventStart.isBetween(selectedDay.startOf('day'), selectedDay.endOf('day'), 'day', '[]')) {
        return o;
      }
    });

    if (_.isNull(this.selectedDayEvents)) {
      this.selectedDayEvents = [];
    }
    this._cd.markForCheck();
  }

  dateHasTasks(date) {
    let tasks = _.filter(this.events, (o) => {
      let eventStart = moment(_.get(o, `${this.eventField}${this.eventField ? '.' : ''}startDate`));
      let selectedDay = moment(date.date, 'MM/DD/YYYY');
      if (eventStart.isBetween(selectedDay.startOf('day'), selectedDay.endOf('day'), 'day', '[]')) {
        return o;
      }
    });

    if (tasks.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  // picker

  toggleYearView(event) {
    event.stopPropagation();
    this.yearView = true;
    this.pickerYearRange = [];
    this.pickerYearRange.push(this.currentMonth.clone().format('YYYY'));
    for (let i = 0; i < 11; i++) {
      this.pickerYearRange.push(this.currentMonth.clone().add(i+1, 'years').format('YYYY'));
    }
  }

  pickerPrev(event) {
    event.stopPropagation();
    if (this.yearView) {
      const firstYear = this.pickerYearRange[0] - 11;
      this.pickerYearRange = [];
      for (let i = 0; i <= 11; i++) {
        this.pickerYearRange.push(firstYear + i);
      }
    } else {
      this.pickerYear = this.pickerYear - 1;
    }

  }

  pickerNext(event) {
    event.stopPropagation();
    if (this.yearView) {
      const firstYear = this.pickerYearRange[0] + 11;
      this.pickerYearRange = [];
      for (let i = 0; i <= 11; i++) {
        this.pickerYearRange.push(firstYear + i);
      }
    } else {
      this.pickerYear = this.pickerYear + 1;
    }
  }

  selectYear(event, year) {
    event.stopPropagation();
    this.pickerYear = year;
    this.yearView = false;
  }

  selectMonth(event, month, year) {
    event.stopPropagation();
    this.currentMonth = moment(month + ' ' + year, 'MMM YYYY');
    this.currentMonthFormatted = moment(this.currentMonth).format('MMMM YYYY');
    this.daysofMonth = [];
    this.getDays();
    this.selectedDate = null;
    this.loadData();
    this.openPicker = false;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({name: 'parentheses'})
export class ParenthesesPipe implements PipeTransform {
  transform(value:any, args:string, decimal: number) : any {

    if (_.isArray(value)) {
      _.forEach(value,function(data) {
        value[args] = Number(value[args]).toFixed(decimal);
        value[args] = value[args] < 0 ? '(' + value[args].substr(1) + ')' : value[args];
        value[args] = value[args].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      });
    } else if (_.isObject(value)) {
      value[args] = Number(value[args]).toFixed(decimal);
      value[args] = value[args] < 0 ? '(' + value[args].substr(1) + ')' : value[args];
      value[args] = value[args].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      value = Number(value).toFixed(decimal);
      value = value < 0 ? '(' + value.substr(1) + ')' : value;
      value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return value;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filter', pure: false })
export class FilterPipe implements PipeTransform {
  transform(items:any, property:string, searchTerm:string) : any {
    return items.filter((item) => {
      return item[property].toLowerCase().search(searchTerm.toLowerCase()) >= 0;
    });
  }
}

import { Component, OnInit, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import {Location} from '@angular/common';
import { Router } from '@angular/router';
import { LmsEnrollmentLessonTestService } from '../../shared/services/api/lms/lms-enrollment-lesson-test.service';
import { AuthService } from '../../shared/services/auth.service';
import { AssessmentTestComponent } from './assessment-test/assessment-test.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-assessments',
  templateUrl: './assessments.component.html',
  styleUrls: ['./assessments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssessmentsComponent implements OnInit {
  @ViewChild('modal', { static: true }) modal;

  config = [
    [
      {
        header: true,
        label: 'Curriculum:',
        property: 'lmsEnrollmentLesson.lmsCourse.name',
        class: 'bg-yellow'
      }
    ],
    [
      {
        label: 'Course: ',
        property: 'lmsEnrollmentLesson.lmsLesson.name',
        disabled: {
          property: 'test.passed',
          value: true
        },
        onClick: (x, disabled = null) => {
          if (disabled) {
            if (_.get(x, disabled.property) === disabled.value) {
              return null;
            } else {
              this.openModal(x);
            }
          } else {
            this.openModal(x);
          }
        },
      }
    ],
    [
      {
        label: 'Status:',
        property: (x) => {
          if (_.get(x, 'test.passed')) {
            return 'Passed';
          } else if (_.get(x, 'test.taken')) {
            return 'Not Passed';
          } else {
            return 'Pending';
          }
        },
        leftAlign: true,
      },
      {
        label: 'Rating:',
        property: (x) => {
          let rating = (_.get(x, 'test.rating') * 100);
          if (_.get(x, 'test.taken') || _.get(x, 'test.status') === 'done') {
            return Number(rating).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '%';
          } else {
            return 'Pending';
          }
        },
        leftAlign: true,
      }
    ],
  ];

  model = [];

  loading = false;
  _subscriptions = [];
  error = false;
  queryOptions: any = {};

  constructor(
    private _location: Location,
    private router: Router,
    private lmsEnrollmentLessonTestService: LmsEnrollmentLessonTestService,
    private authService: AuthService,
    private _cd: ChangeDetectorRef
  ) {

    let url = router.url.split('/');
    let id = null;
    this.queryOptions = {
      filter: {
        conditions: [
          {
            property: 'testerLmsUser.id',
            value: this.authService.getActiveLmsUser().object.id,
            op: 'eq',
            dataType: 'long'
          }
        ]
      },
      sort: [
        {
          property: 'test.passed',
          dir: 'asc'
        },
        {
          property: 'test.taken',
          dir: 'asc'
        },
        {
          property: 'test.dateTaken',
          dir: 'desc'
        },
        {
          property: 'id',
          dir: 'asc'
        }
      ]
    };
    if (url.length > 3) {
      let url = router.url.split('/')[4];
      let ids = url.split('-').slice(-2);
      let enrollmentLessonId = ids[0];
      let courseId = ids[1];
      id = Number(url[4].split('-').pop());
      this.queryOptions.filter.conditions.push(
        {
          property: 'lmsEnrollmentLesson.lmsCourse.id',
          value: courseId,
          op: 'eq',
          dataType: 'long'
        },
        {
          property: 'lmsEnrollmentLesson.lmsEnrollment.id',
          value: enrollmentLessonId,
          op: 'eq',
          dataType: 'long'
        }
      );
    }
    this.loading = true;
    let s = this.lmsEnrollmentLessonTestService.browse({}, this.queryOptions).subscribe((res: any) => {
      this.model = res.data.records;
      this.loading = false;
      this._cd.markForCheck();
    }, (err) => {
      this.error = true;
      this.loading = false;
      this._cd.markForCheck();
    });
    this._subscriptions.push(s);

  }

  ngOnInit(): void {
  }

  back() {
    this._location.back();
  }

  openModal(enrollmentLessonTest) {
    this.modal.title = _.get(enrollmentLessonTest, 'test.name');
    this.modal.createContentComponent(AssessmentTestComponent);
    this.modal.initialize().then((res) => {
      this.modal.getEventEmitter().subscribe((res) => {
        if (res.name === 'open') {
          this.modal._contentComponent.instance.init(_.get(enrollmentLessonTest, 'test'));
        } else if (res.name === 'close') {
          this.loading = true;
          let s = this.lmsEnrollmentLessonTestService.browse({}, this.queryOptions).subscribe((res: any) => {
            this.model = res.data.records;
            this.loading = false;
            this._cd.markForCheck();
          }, (err) => {
            this.error = true;
            this.loading = false;
            this._cd.markForCheck();
          });
        }
      });
      this.modal.open();
    });
  }

}

import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../shared/services/auth.service';
import { Company } from '../../shared/models/company/company.model';
import { CompanyBranch } from '../../shared/models/company-branch/company-branch.model';
import { AppContextService } from '../../shared/services/app-context.service';
import { CompanyService, CompanyBranchService } from '../../shared/services/api';

import * as _ from 'lodash';

@Component({
  selector: 'app-branch-selector',
  templateUrl: 'branch-selector.component.html',
  styleUrls: ['branch-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BranchSelectorComponent implements OnInit {

  locationForm: FormGroup;
  company: Company = localStorage.getItem("app_context") ? JSON.parse(localStorage.getItem("app_context")).company : null;
  companyBranch: CompanyBranch;
  multipleBranchObject: any = { name: 'Multiple Branches' };
  multipleCompanyBranch: CompanyBranch[] = [];
  optionCompanyBranches: CompanyBranch[];

  searchTerm: string = '';

  showMultipleErrors: boolean = false;
  isDefault: boolean = false;
  showError: boolean = false;
  passMultiple: boolean = false;
  isMultipleBranch: boolean = false;
  optionCompanyBranchesLoading: boolean = false;
  queryOptions = null;
  initial = true;

  companyBranchLabel = 'Branch';

  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _companyService: CompanyService,
    private _companyBranchService: CompanyBranchService,
    private _formBuilder: FormBuilder,
    private _appContextService: AppContextService,
    // private _appService: AppService,
  ) {
    _.bindAll(this, 'autocomplete');

    this.locationForm = this._formBuilder.group({
      'company': ['', Validators.required],
      'companyBranch': ['', Validators.required],
      'multipleCompanyBranch': [''],
      'default': [''],
    });

    this.locationForm.controls['companyBranch'].valueChanges.subscribe((companyBranch: CompanyBranch) => {
      this.companyBranchChange(companyBranch);
    });
  }

  isSuperadminUser() {
    return _.get(this._authService.getStoredUserInfo(), 'userDetails.roleGroups[0].secRoleGroup.name') === 'ROLE_GROUP_SUPERADMIN';
  }

  ngOnInit(): void {
    this.getCompanyBranchValue();

    if (!this.isSuperadminUser()) {
      this.queryOptions = {
        filter: {
          conditions: [{
            compound: true,
            filter: {
              joinOp: 'or',
              conditions: this.buildCompanyAccessConditions()
            }
          }]
        }
      };

      this._companyService.browse({}, this.queryOptions)
        .subscribe((res: any) => {
          let records = res.data.records;
          if (this.getCompanyAccess().length == 1) {
            this.company = records[0];
            let queryOptions = {
              filter: {
                conditions: [
                  {
                    compound: true,
                    filter: this.getCompanyFilter(this.company)
                  },
                  {
                    compound: true,
                    filter: {
                      joinOp: 'or',
                      conditions: this.buildBranchAccessConditions()
                    }
                  }
                ]
              }
            };
            this.getCompanyBranch(queryOptions).subscribe((res: any) => {
              let records = res.data.records;
              if (records.length == 1) {
                this.companyBranch = records[0];
              }
            });
          }
        }, (err) => {});
    } else {
      this.queryOptions = null;
      this._companyService.browse({},{})
        .subscribe((res: any) => {
          let records = res.data.records;
          if (records.length == 1) {
            this.company = records[0];
            this.getCompanyBranch({filter: this.getCompanyFilter(this.company)}).subscribe((res: any) => {
              let records = res.data.records;
              if (records.length == 1) {
                this.companyBranch = records[0];
              }
            });
          }
        }, (err) => {
      });
    }
  }

  buildCompanyAccessConditions(){
    let conditions = [];

    this.getCompanyAccess().forEach(function(item){
      conditions.push({
        property: 'id',
        value: item,
        dataType: 'Long',
      })
    });

    return conditions;
  }

  getCompanyAccess() {
    var companyAccess = [];

    for (let i = 0; i < this._authService.getStoredUserInfo().userDetails.companyBranchAccesses.length; i++) {
      if (companyAccess.indexOf(this._authService.getStoredUserInfo().userDetails.companyBranchAccesses[i].company.id) === -1 ) {
        companyAccess.push(this._authService.getStoredUserInfo().userDetails.companyBranchAccesses[i].company.id);
      }
    }
    return companyAccess;
  }

  getCompanyFilter(company: Company) {
    return !company ? {} : {
      conditions: [{
        property: 'id',
        association: 'company',
        op: 'eq',
        value: company.id,
        dataType: 'long',
      }]
    };
  }

  getBranchAccess() {
    var branchAccess = [];

    for (let i = 0; i < this._authService.getStoredUserInfo().userDetails.companyBranchAccesses.length; i++) {
      let companyBranch = this._authService.getStoredUserInfo().userDetails.companyBranchAccesses[i].companyBranch;
      if (companyBranch) {
        if(branchAccess.indexOf(companyBranch.id) == -1 ) {
          branchAccess.push(companyBranch.id)
        }
      } else {
        return null; // has access to all
      }
    }
    return branchAccess;
  }

  buildBranchAccessConditions(){
    let conditions = [];

    let branchAccess = this.getBranchAccess();
    if (branchAccess) {
      branchAccess.forEach(function(item){
        conditions.push({
          property: 'id',
          value: item,
          dataType: 'Long',
        })
      });
    }

    return conditions;
  }

  getCompanyBranch(queryOptions: any = {}) {
    return this._companyBranchService.browse({}, queryOptions);
  }

  logOut() {
    this._authService.logOut()
      .subscribe((data) => {
        this.completeLogout(false);
      }, (err) => {
        this.completeLogout(true);
      });
  }

  completeLogout(error: boolean = false) {
    // Remove client-side session data whether or not log out request succeeded.
    this._authService.clearStoredUserInfo();
    this._router.navigate(['/login']);
    // this._router.navigate([this._loginService.getLoginRoute()]);
  }

  saveLocation() {
    if (!this.companyBranch && this._authService.isSuperadminUser()) {
      this.storeLocation(this.locationForm.value);
      // this._router.navigate(['/erp']);
      this._router.navigate([this.getAppDefaultRoute()]);
      return;
    }

    this.showError = true;
    if (!this.companyBranch) return;

    if (this.locationForm.valid && this.passMultiple) {
      this.locationForm.value.companyBranch = this.isMultipleBranch ? this.multipleCompanyBranch : this.companyBranch;
      this.storeLocation(this.locationForm.value);
      // this._router.navigate(['/erp']);
      this._router.navigate([this.getAppDefaultRoute()]);
    }
  }

  getAppDefaultRoute() {
    let erpHome = '/lms';
    return erpHome;
  }

  storeLocation(data: any) {
    let dataBranches = data.companyBranch;
    let company = data.company;
    let branches = dataBranches ? _.isArray(dataBranches) ? dataBranches : [dataBranches] : null;
    this._appContextService.setActiveBranch(_.isArray(branches) ? branches[0] : branches);
    this._appContextService.setCompanyBranches(company, branches);
  }

  getCompanyBranchValue() {
    if (localStorage.getItem("active_branch")) {
      if (_.size((JSON.parse(localStorage.getItem("app_context"))).companyBranches) > 1) {
        this.companyBranch = JSON.parse(localStorage.getItem("app_context")).companyBranches;
        this.companyBranch.name = 'Multiple Branches';
        this.isMultipleBranch = true;
      }
      else {
        this.companyBranch = JSON.parse(localStorage.getItem("active_branch"));
      }
    }
    else {
      this.companyBranch = null;
    }
  }

  autocomplete(search: string, doneCallback) {
    if (!this.isSuperadminUser()){
      let queryOptions = {
        searchKeyword: search,
        autocomplete: true,
        filter: {
          conditions: [
            {
              compound: true,
              filter: this.getCompanyFilter(this.company)
            },
            {
              compound: true,
              filter: {
                joinOp: 'or',
                conditions: this.buildBranchAccessConditions()
              }
            }
          ]
        },
      };

      this.getCompanyBranch(queryOptions).subscribe((res: any) => {
        if (this.company) res.data.records.unshift(this.multipleBranchObject);
        doneCallback(res.data.records);
      });
    } else {
      let queryOptions = {
        searchKeyword: search,
        autocomplete: true,
        filter: this.getCompanyFilter(this.company)
      };

      this.getCompanyBranch(queryOptions).subscribe((res: any) => {
        if (this.company) res.data.records.unshift(this.multipleBranchObject);
        doneCallback(res.data.records);
      });
    }
  }

  companyBranchChange(companyBranch: CompanyBranch) {
    this.multipleCompanyBranch = [];
    localStorage.setItem("active_branch", "");
    if (companyBranch) {
      this.isMultipleBranch = companyBranch.name === 'Multiple Branches';

      if (companyBranch.company) {
        setTimeout(() => {
          this.locationForm.controls['company'].setValue(companyBranch.company);
          this.company = companyBranch.company;
        });
      }
    }
    else {
      this.isMultipleBranch = false;
    }

    if (this.isMultipleBranch) {

      this.optionCompanyBranches = [];
      this.optionCompanyBranchesLoading = true;
      if (!this.isSuperadminUser()) {
        let queryOptions = {
          filter: {
            conditions: [
              {
                compound: true,
                filter: this.getCompanyFilter(this.company)
              },
              {
                compound: true,
                filter: {
                  joinOp: 'or',
                  conditions: this.buildBranchAccessConditions()
                }
              }
            ]
          }
        };

        this.getCompanyBranch(queryOptions).subscribe((res) => {
          this.optionCompanyBranches = res.data.records;
          if (this.initial && localStorage.getItem("app_context")) {
            for (let x = 0; x<_.size((JSON.parse(localStorage.getItem("app_context"))).companyBranches); x++) {
              this.moveBranch(this.optionCompanyBranches, this.multipleCompanyBranch, JSON.parse(localStorage.getItem("app_context")).companyBranches[x]);
            }
          }
          this.optionCompanyBranchesLoading = false;
        });
      }
      else {
        let queryOptions = { filter: this.getCompanyFilter(this.company) };

        this.getCompanyBranch(queryOptions).subscribe((res) => {
          this.optionCompanyBranches = res.data.records;
          if (this.initial && localStorage.getItem("app_context")) {
            for (let x = 0; x<_.size((JSON.parse(localStorage.getItem("app_context"))).companyBranches); x++) {
              this.moveBranch(this.optionCompanyBranches, this.multipleCompanyBranch, JSON.parse(localStorage.getItem("app_context")).companyBranches[x]);
            }
          }
          this.optionCompanyBranchesLoading = false;
        });
      }
    }
    else {
      this.initial = false;
    }

    this.checkMultipleBranch();

  }

  companyChange(company: Company) {
    // localStorage.setItem("app_context", "");
    this.emptyCompanyBranchAutocomplete();
    let queryOptions = {
      filter: {
        conditions: [
          {
            compound: true,
            filter: this.getCompanyFilter(this.company)
          },
          {
            compound: true,
            filter: {
              joinOp: 'or',
              conditions: this.buildBranchAccessConditions()
            }
          }
        ]
      }
    };
    if (!this.isSuperadminUser()) {
      this.getCompanyBranch(queryOptions).subscribe((res) => {
        let records = res.data.records;
        if (records.length == 1) {
          this.companyBranch = records[0];
        }
      });
    } else {
      this.getCompanyBranch({filter: this.getCompanyFilter(this.company)}).subscribe((res) => {
        let records = res.data.records;
        if (records.length == 1) {
          this.companyBranch = records[0];
        }
      });
    }
  }

  emptyCompanyBranchAutocomplete() {
    this.locationForm.controls['companyBranch'].setValue(null);
    this.companyBranch = null;
  }

  checkMultipleBranch() {
    this.passMultiple = (this.isMultipleBranch && this.multipleCompanyBranch.length > 0) || !this.isMultipleBranch;
  }

  /*
   * multiple branch selector
   */
  moveBranch(fromBranchArr: CompanyBranch[], toBranchArr: CompanyBranch[], branch: CompanyBranch) {
    let branchIndex = _.findIndex(fromBranchArr, branch);

    this.removeBranch(fromBranchArr, branchIndex);
    this.addBranch(toBranchArr, branch);

    this.checkMultipleBranch();
  }

  addBranch(branchArr: CompanyBranch[], branch: CompanyBranch) {
    branchArr.push(branch);
  }

  removeBranch(branchArr: CompanyBranch[], branchIndex: number) {
    branchArr.splice(branchIndex, 1);
  }

  fillBranch(fromBranchArr: CompanyBranch[], toBranchArr: CompanyBranch[]) {

    // redo this
    for (let i = fromBranchArr.length - 1; i >= 0; i--) {
      this.moveBranch(fromBranchArr, toBranchArr, fromBranchArr[i]);
    }
  }

}

import { ApiService } from '../../api.service';
import { BreadServiceDecorator } from '../../base-bread.service';
import { BaseBreadWithApprovalService } from '../../base-bread-with-approval.service';
import { Injectable } from '@angular/core';

@BreadServiceDecorator({
  resourceName: 'lmsLessonScheduleInvite'
})

@Injectable()
export class LmsLessonScheduleInviteService extends BaseBreadWithApprovalService {
  constructor(public apiService: ApiService) {
    super(apiService);
  }
}

import { Component, OnInit } from '@angular/core';
import { BaseBreadService } from '../../shared/services/base-bread.service';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss']
})
export class CertificateComponent implements OnInit {

  _imageSource = null;
  certificate = null;

  constructor(protected _baseBreadService: BaseBreadService,) { }

  ngOnInit(): void {
  }

  init(certificate, image) {
    this._imageSource = this._baseBreadService.getResourceDownloadUrl('lmsCertificate', { id: image.id, name: image.name }, 'background_image') + `&_timestamp=${new Date().getTime()}`;
    this.certificate = certificate;
  }

}

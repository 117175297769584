import { Component, ChangeDetectionStrategy, ChangeDetectorRef, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ProfileImageInputComponent } from '../profile-image-input.component';

import { BaseBreadService } from '../../../shared/services/base-bread.service';
import { MessageModalService } from '../../message-modal/message-modal';
import { HttpClient } from '@angular/common/http';

export const LMS_IMAGE_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => LMSImageInputComponent),
  multi: true,
};

@Component({
  selector: 'xsi-lms-image-input',
  templateUrl: 'lms-image-input.component.html',
  styleUrls: ['lms-image-input.component.scss'],
  providers: [LMS_IMAGE_INPUT_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LMSImageInputComponent extends ProfileImageInputComponent {
  constructor(
    protected _cd: ChangeDetectorRef,
    protected _baseBreadService: BaseBreadService,
    protected _messageModalService: MessageModalService,
    protected http: HttpClient,
  ) {
    super(_cd, _baseBreadService, _messageModalService, null, http);
  }
}

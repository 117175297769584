import { Component, OnInit, ViewChild, ViewContainerRef, AfterViewInit } from '@angular/core';
import { MessageModalService } from '../commons/message-modal/message-modal';
import { LMSMessageModalService } from '../commons/message-modal/lms/lms-message-modal';

@Component({
  selector: 'xsi-app-router',
  template: `
    <router-outlet></router-outlet>
    <div>
      <div #messageModal>

      </div>
      <div #lmsMessageModal>

      </div>
    </div>
  `,
  providers: [LMSMessageModalService, MessageModalService],
})
export class AppRouterComponent implements AfterViewInit {
  @ViewChild('messageModal', {read: ViewContainerRef, static: false}) messageModal;
  @ViewChild('lmsMessageModal', {read: ViewContainerRef, static: false}) lmsMessageModal;

  constructor(
    // private _router: Router,
    // private _location: Location,
    // private _authService: AuthService,
    // private _apiService: ApiService,
    // private _appContextService: AppContextService,
    private _messageModalService: MessageModalService,
    private _lmsMessageModalService: LMSMessageModalService,
    // private _loginService: LoginService
    ) {
    // this.redirect();
  }

  ngAfterViewInit() {
    // this.pageBusyService.initialize(this.busyScreen);
    this._messageModalService.initialize(this.messageModal);
    this._lmsMessageModalService.initialize(this.lmsMessageModal);
  }
}

import { Component, OnInit, Input, ChangeDetectorRef, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import { LmsBaseCalendarEventService } from '../../app/calendar/base-calendar.service';
import { LmsEnrollmentLessonService, LmsLessonScheduleInviteService } from '../../shared/services/api';
import { LmsEnrollmentLessonModel } from '../../shared/models/lms/lms-enrollment-lesson.model';
import { LmsLessonScheduleInviteModel } from '../../shared/models/lms/lms-lesson-schedule-invite.model';
import { CalendarConfig } from '../../app/calendar/definitions';

import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-calendar-summary',
  templateUrl: './calendar-summary.component.html',
  styleUrls: ['./calendar-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarSummaryComponent implements OnInit, OnChanges {

  protected eventField = 'lmsLessonSchedule';
  events = [];
  max = 5;

  @Input() model = null;
  @Input() headerName = 'CALENDAR';
  @Input() hideDatecolumn = false;
  @Input() showVenueColumn = false;
  @Input() showTimeColumn = false;

  loading = false;
  error = false;

  constructor(
    private lmsBaseCalendarEventService: LmsBaseCalendarEventService,
    private lmsEnrollmentLessonService: LmsEnrollmentLessonService,
    private lmsLessonScheduleInviteService: LmsLessonScheduleInviteService,
    private _cd: ChangeDetectorRef
  ) { }

  ngOnChanges(changes) {
    if (changes.model) {
      this.events = changes.model.currentValue;
    }
  }

  ngOnInit(): void {
    if (!this.model) {
      this.loading = true;
      const calendarConfig = this.createCalendarConfig();
      const dateRangeQuery = {
        dateStart: null,
        dateEnd: null,
      };
      const queryOptions = this.buildQueryOption(dateRangeQuery.dateStart, dateRangeQuery.dateEnd);
      this.lmsBaseCalendarEventService.fetchEvents(calendarConfig, queryOptions).then((res: any) => {
        this.events = res;
        this.events = _.sortBy(this.events, (o) => moment(_.get(o, `${this.eventField}${this.eventField ? '.' : ''}startDate`)).toDate().getTime()).reverse();

        // dont forget to remove this
        this.events = this.events.slice(0, 5); // 5 items for dashboard;
        this.loading = false;
        this._cd.markForCheck();
        // this.loading = false;
        // this.loadingError = false;
        // this._cd.markForCheck();
      })
      .catch((res) => {
        this.error = true;
        this.loading = false;
        this._cd.markForCheck();
        // this.loading = false;
        // this.loadingError = true;
        // this._cd.markForCheck();
      });
    } else {
      this.events = this.model;
    }
  }

  createCalendarConfig(): CalendarConfig[] {
    return [
      {
        service: this.lmsEnrollmentLessonService,
        additionalFilterCondition: [{ property: 'lmsLessonSchedule', op: 'isNotNull' }],
        preprocessFn: (fetchedEvents: LmsEnrollmentLessonModel[]) => {
          if (fetchedEvents.length) {
            return fetchedEvents.map((event) => {
              // lmsLessonSchedule is filtered isNotNull so assumed there's always a value
              event.lmsLessonSchedule.scheduleType = 'enrollmentLesson';
              return event;
            });
          }
          return [];
        },
      },
      {
        service: this.lmsLessonScheduleInviteService,
        additionalFilterCondition: [],
        preprocessFn: (fetchedEvents: LmsLessonScheduleInviteModel[]) => {
          if (fetchedEvents.length) {
            return fetchedEvents.map((event) => {
              if (_.get(event, 'lmsLessonSchedule')) { event.lmsLessonSchedule.scheduleType = 'lessonScheduleInvite'; }
              event.editable = false;
              return event;
            });
          }
          return [];
        },
      },
    ];
  }

  buildQueryOption(dateStart: number, dateEnd: number) {
    const dateConditions1 = [];
    const dateConditions2 = [];
    const dateConditions3 = [];
    const millisecondsInADay = 86400000;
    const queryOptions: any = {};
    const lowerBound = dateStart || null;
    const upperBoundExclusive = dateEnd ? dateEnd + millisecondsInADay : null; // add 1 day
    const endDateField = `${this.eventField}${this.eventField ? '.' : ''}endDate`;
    const startDateField = `${this.eventField}${this.eventField ? '.' : ''}startDate`;

    if (lowerBound !== null) {
      dateConditions1.push({ property: startDateField, op: 'ge', value: lowerBound, dataType: 'date' });
      dateConditions2.push({ property: endDateField, op: 'ge', value: lowerBound, dataType: 'date' });
      dateConditions3.push({ property: startDateField, op: 'lt', value: lowerBound, dataType: 'date' });
    }
    if (upperBoundExclusive !== null) {
      dateConditions1.push({ property: startDateField, op: 'lt', value: upperBoundExclusive, dataType: 'date' });
      dateConditions2.push({ property: endDateField, op: 'lt', value: upperBoundExclusive, dataType: 'date' });
      dateConditions3.push({ property: endDateField, op: 'ge', value: upperBoundExclusive, dataType: 'date' });
    }

    const dateConditions = (lowerBound !== null) || (upperBoundExclusive !== null) ? [{
      compound: true,
      filter: {
        joinOp: 'or',
        conditions: [
          { compound: true, filter: { joinOp: 'and', conditions: dateConditions1 } },
          { compound: true, filter: { joinOp: 'and', conditions: dateConditions2 } },
          { compound: true, filter: { joinOp: 'and', conditions: dateConditions3 } },
        ],
      },
    }] : null;

    queryOptions.filter = {
      conditions: [
        ...(dateConditions || []),
      ],
    };

    queryOptions.sort = [
      {
        property: `${this.eventField}${this.eventField ? '.' : ''}startDate`,
        dir: 'desc'
      }
    ];

    return queryOptions;
  }

  formatDate(date) {
    return moment(_.get(date, `${this.eventField}${this.eventField ? '.' : ''}startDate`)).format('MMMM D (dddd)');
  }

  formatTime(date) {
    return moment(_.get(date, `${this.eventField}${this.eventField ? '.' : ''}startDate`)).format('hh:mm a') + ' - ' + moment(_.get(date, `${this.eventField}${this.eventField ? '.' : ''}endDate`)).format('hh:mm a');
  }

}

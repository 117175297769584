import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';

import * as _ from 'lodash';

import { ConfigService } from './config.service';
import { AppContextService } from './app-context.service';
import { ApiRequestDataService } from './api-request-data.service';

import { RequestMethod } from './enums/request-methods';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  static COMPANY_HEADER_KEY = 'X-App-Company';
  static SUPERADMIN_HEADER_KEY = 'X-Superadmin';
  static AUTHORIZATION_HEADER_KEY = 'Authorization';
  static COMPANY_BRANCHES_HEADER_KEY = 'X-App-Company-Branch';

  constructor(
    private http: HttpClient,
    public configService: ConfigService,
    public appContextService: AppContextService,
    public apiRequestDataService: ApiRequestDataService,
  ) { }

  public setAccessToken(token: string) {
    if (_.isNil(token)) {
      localStorage.removeItem('token');
    } else {
      localStorage.setItem('token', token);
    }
  }

  public getAccessToken() {
    return localStorage.getItem('token');
  }

  public createApiRequest(
    method: RequestMethod,
    url: string,
    urlQueryParams: any = null,
    bodyJson: any = null,
    noAuth = false,
    noBranchContext: boolean = null,
    noCompanyContext: boolean = null,
    isBase64: boolean = false,
  ) {
    let headers: HttpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json');

    if (!noAuth && this.getAuthorization()) {
      headers = headers.set(ApiService.AUTHORIZATION_HEADER_KEY, this.getAuthorization());
    }

    if (!environment.production || !this.appContextService.isAppContextDisabled()) {
      const company = this.appContextService.getCompany();
      if (!noCompanyContext) {
        if (company) {
          headers = headers.set(ApiService.COMPANY_HEADER_KEY, `${company.id}`);
        }

        if (!noBranchContext) {
          const companyBranches = this.appContextService.getBranches();
          if (companyBranches) {
            const companyBranchesHeaderValue = companyBranches.map(companyBranch => companyBranch.id).join(',');
            headers = headers.set(ApiService.COMPANY_BRANCHES_HEADER_KEY, companyBranchesHeaderValue);
          }
        }
      }
    }

    if (localStorage.getItem('superadmin')) {
      headers = headers.set(ApiService.SUPERADMIN_HEADER_KEY, `${1}`);
    }

    _.each(this.apiRequestDataService._requestHeaders, (value, name) => {
      headers = headers.set(name, value);
    });

    let params: HttpParams = null;
    if (urlQueryParams) {
      params = new HttpParams();
      for (const key in urlQueryParams) {
        if (!_.isNil(urlQueryParams[key])) {
          params = params.set(key, urlQueryParams[key]); // Assumption: Each param has only one value.
        }
      }
    }

    const body = bodyJson ? JSON.stringify(bodyJson) : null;

    const responseType = isBase64 ? 'arraybuffer' : 'json';

    return this.http.request(method, url,
      {
        body: body,
        headers: headers,
        observe: 'body',
        params: params,
        responseType
      });
  }

  public requestToJsonApi(
    method: RequestMethod,
    url: string,
    urlQueryParams: any = null,
    bodyJson: any = null,
    noAuth = false,
    isBase64: boolean = false,
    noBranchContext: boolean = null,
    noCompanyContext: boolean = null,
  ) {
    return this.createApiRequest(method, url, urlQueryParams, bodyJson, noAuth, noBranchContext, noCompanyContext, isBase64);
  }

  public getRequestUrl(action: string, resource: string) {
    return this.configService.getApiBaseUrl() + (resource ? ('/' + resource) : '') + (action ? ('/' + action) : '');
  }

  public requestToApi(
    method: RequestMethod,
    url: string,
    urlQueryParams: any = null,
    bodyJson: any = null,
    noAuth = false,
    noBranchContext: boolean = null,
    noCompanyContext: boolean = null,
  ) {
    return this.createApiRequest(method, url, urlQueryParams, bodyJson, noAuth, noBranchContext, noCompanyContext);
  }

  public requestXMLHttpRequest(
    method: string,
    url: string,
    formData: FormData,
    callback: any,
    opts: any = null,
  ) {
    const xhr = new XMLHttpRequest();

    xhr.open(method, url, true);
    xhr.withCredentials = true;
    if (opts) {
      _.each(opts, (v, k) => xhr[k] = v);
    }
    xhr.responseType = 'arraybuffer';
    xhr.setRequestHeader(ApiService.AUTHORIZATION_HEADER_KEY, this.getAuthorization());
    xhr.setRequestHeader('Content-Type', 'application/json');
    _.each(this.apiRequestDataService._requestHeaders, (value, name) => {
      xhr.setRequestHeader(name, value);
    });
    xhr.onreadystatechange = function() {
      return callback(xhr);
    };

    xhr.send(formData);
  }

  public getAuthorization() {
    return this.getAccessToken() ? 'Bearer ' + this.getAccessToken() : null;
  }
}

import { Component, Input, ChangeDetectorRef, OnInit, ViewChild, ElementRef, Output, EventEmitter, OnChanges, } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { BaseBreadService } from '../../shared/services/base-bread.service';
import * as PDFJS from 'pdfjs-dist';
import * as _ from 'lodash';
import { ResizeService } from '../../shared/services/resize.service';

@Component({
  selector: 'xsi-pdf-viewer',
  templateUrl: 'pdf-viewer.component.html',
  styleUrls: ['pdf-viewer.component.scss'],
})
export class PdfViewerComponent implements OnInit {
  @Input() modelId;
  @Input() resourceName;
  @Input() nameOverride;
  @Input() inputPage = 1;
  @Input() disableScrollPagination = false;
  @Output() totalPages = new EventEmitter();
  @Output() currentPage = new EventEmitter();
  @ViewChild('pdfContainer') pdfContainer: any;
  @ViewChild('pdfViewer') pdfViewer: any;
  protected safeDocument;
  protected pdfDoc: PDFJS.PDFDocumentProxy;
  protected pdfSrc: any = '';
  protected pageNumber = 1;
  protected documentVisible = false;
  protected loading = false;
  screenWidth = 0;

  constructor(
    public sanitizer: DomSanitizer,
    private _baseBreadService: BaseBreadService,
    private _cd: ChangeDetectorRef,
    private resizeSvc: ResizeService
  ) { }

  ngOnChanges(changes) {
    if (changes.inputPage) {
      if (changes.inputPage.currentValue !== this.pageNumber) {
        this.pageNumber = changes.inputPage.currentValue;
        this.pdfViewer.page = changes.inputPage.currentValue;
        let pages = this.pdfViewer.iframe.nativeElement.contentDocument.getElementsByClassName('page');
        this._cd.markForCheck();
        _.forEach(pages, (o, index) => {
          if (parseInt(o.dataset.pageNumber, 10) !== this.pageNumber) {
            o.style.visibility = 'collapse';
          } else {
            o.style.visibility = '';
          }
        });
      }
    }
  }

  ngOnInit() {
    this.loading = true;
    this.resizeSvc.onResize$.subscribe((res) => {
      this.screenWidth = res;
      if (res > 768) {
        if (window.orientation === 90 || window.orientation === -90) {
          this.pdfViewer.scroll = 'W';
        } else {
          this.pdfViewer.scroll = 'H';
        }
        this.pdfViewer.rotateccw = false;
        this.loading = true;
        this.pdfViewer.refresh();
      } else {
        if (window.orientation === 90 || window.orientation === -90) {
          this.pdfViewer.scroll = 'W';
          this.pdfViewer.rotateccw = false;
        } else {
          this.pdfViewer.scroll = 'H';
          this.pdfViewer.rotateccw = true;
        }
        this.loading = true;
        this.pdfViewer.refresh();
      }
    });

    window.addEventListener("orientationchange", (event) => {
      if (this.screenWidth > 768) {
        if (window.orientation === 90 || window.orientation === -90) {
          this.pdfViewer.scroll = 'W';
        } else {
          this.pdfViewer.scroll = 'H';
        }
        this.loading = true;
        this.pdfViewer.rotateccw = false;
        this.pdfViewer.refresh();
      } else {
        if (window.orientation === 90 || window.orientation === -90) {
          this.pdfViewer.scroll = 'W';
          this.pdfViewer.rotateccw = false;
        } else {
          this.pdfViewer.scroll = 'H';
          this.pdfViewer.rotateccw = true;
        }
        this.loading = true;
        this.pdfViewer.refresh();
      }
    });
    this.refresh();
    this._cd.markForCheck();
  }

  refresh() {
    console.error(this.getUrl() + '&inline=1#toolbar=0&view=FitH');
    fetch(this.getUrl() + '&inline=1#toolbar=0&view=FitH').then((res) => {
      res.blob().then((b) => {
        console.error(b);
        this.pdfSrc = b;
        this.pdfViewer.pdfSrc = this.pdfSrc;
        this._cd.markForCheck();
        this.pdfViewer.refresh();
        console.error(this.pdfViewer);
      });
    });

    this.safeDocument = this.sanitizer.bypassSecurityTrustResourceUrl(this.getUrl() + '&inline=1#toolbar=0&view=FitH');
  }

  onDocumentLoad($event) {
    this.loading = false;
    let toolbar = this.pdfViewer.iframe.nativeElement.contentDocument.getElementsByClassName('toolbar')[0];
    let viewerContainer = this.pdfViewer.iframe.nativeElement.contentDocument.getElementById('viewerContainer');
    let mainContainer = this.pdfViewer.iframe.nativeElement.contentDocument.getElementById('mainContainer');
    let pages = this.pdfViewer.iframe.nativeElement.contentDocument.getElementsByClassName('page');
    toolbar.style.display = 'none';
    viewerContainer.style.overflow = 'hidden';
    mainContainer.style.backgroundColor = 'white';
    let pageCount = this.pdfViewer.PDFViewerApplication.pagesCount;
    this.totalPages.emit(pageCount);
    for (let x = 0; x < pages.length; x++) {
      pages[x].style.borderImage = 'none';
    }
    _.forEach(pages, (o, index) => {
      if (parseInt(o.dataset.pageNumber, 10) !== this.pageNumber) {
        o.style.visibility = 'collapse';
      } else {
        o.style.visibility = '';
      }
    });
    this.documentVisible = true;
    if (!this.disableScrollPagination) {
      viewerContainer.addEventListener('wheel', _.throttle((event) => {
        if (event.deltaY > 0 && this.pageNumber < pageCount) {
          this.pageNumber++;
          this.pdfViewer.page = this.pageNumber;
          this._cd.markForCheck();
        }
        if (event.deltaY < 0 && this.pageNumber > 1) {
          this.pageNumber--;
          this.pdfViewer.page = this.pageNumber;
          this._cd.markForCheck();
        }
      }, 100));
    }
  }

  getUrl() {
    if (this.modelId) {
      const name = this.nameOverride;
      return this._baseBreadService.getResourceDownloadUrl(this.resourceName, { id: this.modelId, name }, name) + `&_timestamp=${new Date().getTime()}`;
    }
    else {
      return null;
    }
  }

  onPageChange(event) {
    if (this.pageNumber != event && !this.loading) {
      this.currentPage.emit(event);
    }
  }

  // loadPdfPage() {
  //   this.pdfDoc.getPage(this.pageNumber).then((page) => {
  //     const scale = 1;
  //     const viewport = page.getViewport({scale: document.querySelector('.wrapper.childLocked').clientHeight / page.getViewport({scale}).width});

  //     const canvas = this.pdfCanvas.nativeElement;
  //     const context = canvas.getContext('2d');
  //     canvas.height = viewport.height;
  //     canvas.width = viewport.width;

  //     const renderContext = {
  //       canvasContext: context,
  //       viewport
  //     };
  //     const renderTask = page.render(renderContext);
  //     renderTask.promise.then(function() {
  //     });
  //   });
  // }
}

import { Component, OnInit, HostBinding, ChangeDetectionStrategy, ViewChild, ViewContainerRef, ElementRef, ComponentFactoryResolver, ChangeDetectorRef, OnChanges, Input, EventEmitter } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  host: { 'class': 'modal ng-animate invisible' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent implements OnInit, OnChanges {
  @HostBinding('class.invisible') hidden: boolean = true;

  @ViewChild('content', { read: ViewContainerRef }) contentContainer: ViewContainerRef;

  public _contentComponent: any;
  @Input('contentComponentClass')
  private _contentComponentClass: any;
  protected _eventEmitter = new EventEmitter();

  private _isInitialized: boolean = false;
  private _initDoneResolveCallbacks: any = [];
  private _subscriptions: any = [];
  private _busy: boolean;
  title = '';
  landscapeLock = false;

  constructor(protected _elementRef: ElementRef, protected _componentResolver: ComponentFactoryResolver, protected _cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    // this.createContentComponent();
  }

  ngOnChanges(changes) {
    if (changes.contentComponentClass) {
      this.createContentComponent();
    }
  }

  ngOnDestroy() {
    _.forEach(this._subscriptions, (s) => { s.unsubscribe(); });
  }

  createContentComponent(contentComponentClass = null) {
    if (contentComponentClass) {
      this._contentComponentClass = contentComponentClass;
    }
    if (this._contentComponentClass) {
      this.contentContainer.clear();
      this.loadComponent(this._contentComponentClass, 0).then(
        (component: any) => {
          this._contentComponent = component;
          // component._hostElement.nativeElement.classList.add('modal-tab-content', 'active');
          // component._nativeElement.classList.add('modal-tab-content', 'active');

          // if (_.isFunction(this._contentComponent.instance.setParent)) {
          //   this._contentComponent.instance.setParent(this);
          // }

          // Init done:
          this.resolveInitialize();
        },
        (err) => { console.error(err); }
      );
    } else {
      // this.resolveInitialize();
    }
  }

  loadComponent(component, index) {
    return Promise.resolve(this._componentResolver.resolveComponentFactory(component)).then((factory) => {
      const c = this.contentContainer.createComponent(factory, index, this.contentContainer.injector);
      return c;
    });
  }

  destroyComponents() {
    if (this._contentComponent) {
      this._contentComponent.destroy();
    }
  }

  initialize() {
    return new Promise((resolve, reject) => {
      if (this._isInitialized) {
        resolve();
      } else {
        this._initDoneResolveCallbacks.push(resolve);
      }
    });
  }

  resolveInitialize() {
    _.forEach(this._initDoneResolveCallbacks, (resolve) => { resolve(); });
    this._initDoneResolveCallbacks = [];
    this._isInitialized = true;
  }

  show() { // TODO: Review - Do we want to pass the parent in show?
    //this._elementRef.nativeElement.classList.remove('hidden');

    if (!this.hidden) {
      console.warn('Modal is already shown.');
      return;
    }

    this.hidden = false;
    this._eventEmitter.emit({ name: 'open' });
    this._cd.markForCheck();
  }

  hide() {
    //this._elementRef.nativeElement.classList.add('hidden');

    this.hidden = true;
    this._cd.markForCheck();
    this._eventEmitter.emit({ name: 'close' });
  }

  open() {
    this.show();
  }

  close() {
    this.hide();
    this.reset();
  }

  reset() {
    this._contentComponentClass = null;
    this._contentComponent = null;
    this._isInitialized = false;
    this._eventEmitter = new EventEmitter();
  }

  getEventEmitter() {
    return this._eventEmitter;
  }

  subscribeToEvents(fxn1, fxn2) {
    return this._eventEmitter.subscribe(fxn1, fxn2);
  }

  getContentComponent() {
    return this._contentComponent ? this._contentComponent : null;
  }

  // for modal spinner
  startBusy() {
    this._busy = true;
    this._cd.markForCheck();
  }

  endBusy() {
    this._busy = false;
    this._cd.markForCheck();
  }
}

import { RequestMethod } from '../../enums/request-methods';
import { ApiService } from '../../api.service';
import { BreadServiceDecorator } from '../../base-bread.service';
import { BaseBreadWithApprovalService } from '../../base-bread-with-approval.service';
import { Injectable } from '@angular/core';

interface SetFlagBody {
  id: number,
  flagName: string,
  value?: boolean, // default: true
  timestamp?: string, // ignored on backend for now
}

@BreadServiceDecorator({
  resourceName: 'lmsEnrollmentLesson'
})

@Injectable()
export class LmsEnrollmentLessonService extends BaseBreadWithApprovalService {
  constructor(public apiService: ApiService) {
    super(apiService);
  }

  setFlag(requestData: SetFlagBody) {
    const requestBody = { properties: requestData };

    return this.apiService.requestToJsonApi(RequestMethod.POST, this.getRequestUrl('setFlag', 'lmsEnrollmentLesson'), {}, requestBody);
  }

  checkPrerequisites(id: number) {
    return this.apiService.requestToJsonApi(RequestMethod.POST, this.getRequestUrl('checkPrerequisites', 'lmsEnrollmentLesson'), {}, { properties: { id } });
  }
}

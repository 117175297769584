import { BreadServiceDecorator } from '../../base-bread.service';
import { BasePublicApiService } from './base-public-api.service';
import { PublicApiService } from '../../public-api.service';
import { Injectable } from '@angular/core';

@BreadServiceDecorator({
  resourceName: 'locationCity',
  moduleName: ''
})

@Injectable()
export class LocationCityPublicApiService extends BasePublicApiService {
  constructor(public apiService: PublicApiService) {
    super(null, null, apiService, null);
  }
}

import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup as ControlGroup, Validators, FormControl as Control } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { AppContextService } from '../../shared/services/app-context.service';
import { AppConfigService, AppFeature } from '../../shared/services/app-config.service';

import { getXhrResponseErrorMessage } from '../../shared/utils/utils';
import { CompanyService, CompanyBranchService } from '../../shared/services/api';
import { Company } from '../../shared/models/company/company.model';

import { MessageModalService } from '../../commons/message-modal/message-modal';
import { SecUserService } from '../../shared/services/sec-user.service';

import * as _ from 'lodash';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit {

  loginForm: ControlGroup;
  loginCredentials: Object = {username: null, password: null};
  ui: any = {};
  _resetBusy = false;
  showForgotPassword = false;

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    public appContextService: AppContextService,
    private _appConfigService: AppConfigService,
    private _companyService: CompanyService,
    private _companyBranchService: CompanyBranchService,
    private _messageModalService: MessageModalService,
    private secUserService: SecUserService,
    protected _cd: ChangeDetectorRef
  ) { }

  checkRequiredProfile() {
    // set to check for lmsuser
    return this._appConfigService.hasFeature(AppFeature.LOGIN__REQUIRE_LMSUSER_PROFILE) ? this.authService.getActiveLmsUser() : true;
  }

  ngOnInit(): void {
    this.loginForm = this._formBuilder.group({
      'username': ['', Validators.required],
      'password': ['', Validators.required],
    });
    // this.getBackground();
    if (localStorage.getItem('auth.user_info')) {
      if (this.authService.isSuperadminUser()) {
        if (localStorage.getItem('active_branch')) {
          this.router.navigate(['/lms']);
        }
        else {
          this.router.navigate(['/location']);
        }
      }
    }
  }

  getBackground() {
    // const backgroundSrc = this._appService.getBackgroundSrc('loginBackground');
    // this.background = `url(${backgroundSrc})`;
  }

  logIn() {
    this.ui.errMsg = null;
    this.ui.busy = true;
    this.appContextService.clearData();
    this.authService.clearStoredUserInfo();
    this.authService.logIn(this.loginForm.value)
      .subscribe((data) => {
        this.authService.storeUserInfo(data);
        this.loadCurrentUserDetails();
      }, (err) => {
        if (err.status === 0) {
          this.ui.errMsg = 'Failed to connect to server';
        } else if (Math.floor(err.status / 100) === 4) {
          this.ui.errMsg = getXhrResponseErrorMessage(err) || 'Username or password is incorrect.';
        } else {
          this.ui.errMsg = 'Unknown error';
        }
        this.ui.busy = false;
        this._cd.markForCheck();
      });
  }

  loadCurrentUserDetails() {
    console.log('load');
    this.authService.getCurrentUserDetails()
      .subscribe((data) => {
        const userInfo = this.authService.getStoredUserInfo();
        userInfo.userDetails = data.data;
        this.authService.storeUserInfo(userInfo);
        if (!this.authService.isSuperadminUser() && !this.checkRequiredProfile()) {
          this.logOut();
          this.ui.errMsg = 'Username or password is incorrect.';
          this._cd.markForCheck();
        } else {
          // if (userInfo.userDetails.companyBranchAccesses.length === 1) {
          //   this.appContextService.setActiveBranch(null);
          //   this.appContextService.setCompanyBranches(userInfo.userDetails.companyBranchAccesses[0].company, [userInfo.userDetails.companyBranchAccesses[0].companyBranch]);
          //   this.router.navigate(['/lms']);
          // } else {
          //   this.router.navigate(['/location']);
          // }
          let company = null;
          let companyBranch = null;

          let queryOptions = {
            filter: {
              conditions: [{
                compound: true,
                filter: {
                  joinOp: 'or',
                  conditions: this.buildCompanyAccessConditions()
                }
              }]
            }
          };

          this._companyService.browse({}, queryOptions).subscribe((res: any) => {
              const records = res.data.records;
              if (this.getCompanyAccess().length === 1) {
                company = records[0];
                let queryOptions = {
                  filter: {
                    conditions: [
                      {
                        compound: true,
                        filter: this.getCompanyFilter(company)
                      },
                      {
                        compound: true,
                        filter: {
                          joinOp: 'or',
                          conditions: this.buildBranchAccessConditions()
                        }
                      }
                    ]
                  }
                };
                this.getCompanyBranch(queryOptions).subscribe((res: any) => {
                  const records = res.data.records;
                  if (records.length === 1) {
                    companyBranch = records[0];
                    this.storeLocation({company, companyBranch});
                    this.ui.busy = false;
                    this.router.navigate(['/lms']);
                  } else {
                    this.ui.busy = false;
                    this.router.navigate(['/location']);
                  }
                });
              } else {
                this.ui.busy = false;
                this.router.navigate(['/location']);
              }
            }, (err) => {
              this.ui.errMsg = 'Failed to load user information';
              this.ui.busy = false;
              this._cd.markForCheck();
          });
        }
      }, (err) => {
        this.ui.errMsg = 'Failed to load user information';
        this.ui.busy = false;
        this._cd.markForCheck();
      });
  }

  logOut() {
    this.ui.loggingOut = true;
    this.authService.logOut()
      .subscribe((data) => {
        this.completeLogout(false);
      }, (err) => {
        this.completeLogout(true);
      });
  }

  completeLogout(error: boolean = false) {
    // Remove client-side session data whether or not log out request succeeded.
    this.authService.clearStoredUserInfo();
    this.appContextService.clearData();
    // this._router.navigate(['/login']);
  }

  redirectToSignUp() {
    this.router.navigate(['/register']);
  }

  getCompanyBranch(queryOptions: any = {}) {
    return this._companyBranchService.browse({}, queryOptions);
  }

  getCompanyFilter(company: Company) {
    return !company ? {} : {
      conditions: [{
        property: 'id',
        association: 'company',
        op: 'eq',
        value: company.id,
        dataType: 'long',
      }]
    };
  }

  storeLocation(data: any) {
    let dataBranches = data.companyBranch;
    let company = data.company;
    let branches = dataBranches ? _.isArray(dataBranches) ? dataBranches : [dataBranches] : null;
    this.appContextService.setActiveBranch(_.isArray(branches) ? branches[0] : branches);
    this.appContextService.setCompanyBranches(company, branches);
  }

  buildCompanyAccessConditions(){
    let conditions = [];

    this.getCompanyAccess().forEach(function(item){
      conditions.push({
        property: 'id',
        value: item,
        dataType: 'Long',
      });
    });

    return conditions;
  }

  getCompanyAccess() {
    var companyAccess = [];

    for (let i = 0; i < this.authService.getStoredUserInfo().userDetails.companyBranchAccesses.length; i++) {
      if (companyAccess.indexOf(this.authService.getStoredUserInfo().userDetails.companyBranchAccesses[i].company.id) === -1 ) {
        companyAccess.push(this.authService.getStoredUserInfo().userDetails.companyBranchAccesses[i].company.id);
      }
    }
    return companyAccess;
  }

  buildBranchAccessConditions(){
    let conditions = [];

    let branchAccess = this.getBranchAccess();
    if (branchAccess) {
      branchAccess.forEach(function(item){
        conditions.push({
          property: 'id',
          value: item,
          dataType: 'Long',
        });
      });
    }

    return conditions;
  }

  getBranchAccess() {
    var branchAccess = [];

    for (let i = 0; i < this.authService.getStoredUserInfo().userDetails.companyBranchAccesses.length; i++) {
      let companyBranch = this.authService.getStoredUserInfo().userDetails.companyBranchAccesses[i].companyBranch;
      if (companyBranch) {
        if (branchAccess.indexOf(companyBranch.id) === -1 ) {
          branchAccess.push(companyBranch.id);
        }
      } else {
        return null; // has access to all
      }
    }
    return branchAccess;
  }

  generateNewPassword(email:string = null, username:string = null) {
    username = username === '' ? this.loginForm.value.username : username;

    let obj = {
      username: username,
      entityType: 'employee',
      email: email,
    };

    this._resetBusy = true;
    this.secUserService.generateNewPassword(obj).subscribe(
      (res) => {
        this._resetBusy = false;
        this._messageModalService.alert('Your new password has been sent to your registered email.');
      },
      (err) => {
        this._resetBusy = false;
        this._messageModalService.error('Failed to reset password');
      },
    );
  }
}

import { Component, OnInit, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import {Location} from '@angular/common';
import { LmsCertificateIssuanceService } from '../../shared/services/api';
import { AuthService } from '../../shared/services/auth.service';
import { CertificateComponent } from '../../commons/certificate/certificate.component';
import { BaseBreadService } from '../../shared/services/base-bread.service';

import * as _ from 'lodash';

@Component({
  selector: 'app-certificates',
  templateUrl: './certificates.component.html',
  styleUrls: ['./certificates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CertificatesComponent implements OnInit {
  @ViewChild('modal', { static: true }) modal;

  config = {
    imageProperty: 'image',
    property: 'lmsCertificate.name',
    onClick: (x) => { this.openModal(x); },
  };

  model = [];
  loading = false;
  error = false;
  constructor(
    private _location: Location,
    private _lmsCertificateIssuanceService: LmsCertificateIssuanceService,
    private authService: AuthService,
    protected _baseBreadService: BaseBreadService,
    protected _cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    let queryOptions = {
      filter: {
        conditions: [
          {
            property: 'lmsUser.id',
            op: 'eq',
            value: this.authService.getActiveLmsUser().object.id,
            dataType: 'long'
          }
        ]
      }
    };

    this.loading = true;
    this._lmsCertificateIssuanceService.browse({}, queryOptions).subscribe((res: any) => {
      this.model = res.data.records;
      _.forEach(this.model, (o) => {
        o._imageSource = this._baseBreadService.getResourceDownloadUrl('lmsCertificate', { id: o.lmsCertificate.id, name: o.lmsCertificate.name }, 'logo_image') + `&_timestamp=${new Date().getTime()}`;
        o._busy = true;
      });
      this.loading = false;
      this._cd.markForCheck();
    }, (err) => {
      this.loading = false;
      this.error = true;
      this._cd.markForCheck();
    });
  }

  back() {
    this._location.back();
  }

  openModal(certificate) {
    this.modal.title = _.get(certificate, 'lmsCertificate.name');
    this.modal.createContentComponent(CertificateComponent);
    this.modal.initialize().then((res) => {
      this.modal.getEventEmitter().subscribe((res) => {
        if (res.name === 'open') {
          this.modal._contentComponent.instance.init(_.get(certificate, 'lmsLesson.name', ''), _.get(certificate, 'lmsCertificate'));
        }
      });
      this.modal.open();
    });
  }

}


import { Subject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class ResizeService {

  get onResize$(): Observable<any> {
    return this.resizeSubject.asObservable().pipe(distinctUntilChanged());
  }

  private resizeSubject: Subject<any>;

  constructor() {
    this.resizeSubject = new Subject();
  }

  onResize(size) {
    this.resizeSubject.next(size);
  }
}

import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { AppContextService } from '../../shared/services/app-context.service';

import { LmsEnrollmentLessonService } from '../../shared/services/api';
import { LmsEnrollmentLessonTestService } from '../../shared/services/api/lms/lms-enrollment-lesson-test.service';
import { forkJoin } from 'rxjs';

import * as _ from 'lodash';
import { MessageModalService } from '../../commons/message-modal/message-modal';

@Component({
  selector: 'app-home-dashboard',
  templateUrl: './home-dashboard.component.html',
  styleUrls: ['./home-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeDashboardComponent implements OnInit {

  loading = false;
  pendingCourseCount = null;
  pendingAssessmentCount = null;

  courseConditions = [
    {
      compound: true,
      filter: {
        joinOp: 'or',
        conditions: [
          {
            property: 'passed',
            op: 'eq',
            value: false,
            dataType: 'boolean',
          },
          {
            property: 'passed',
            op: 'isNull',
          }
        ]
      }
    },
    {
      compound: true,
      filter: {
        joinOp: 'or',
        conditions: [
          {
            property: 'testCompleted',
            op: 'eq',
            value: false,
            dataType: 'boolean',
          },
          {
            property: 'testCompleted',
            op: 'isNull',
          }
        ]
      }
    },
    {
      compound: true,
      filter: {
        joinOp: 'or',
        conditions: [
          {
            property: 'completed',
            op: 'eq',
            value: false,
            dataType: 'boolean',
          },
          {
            property: 'completed',
            op: 'isNull',
          }
        ]
      }
    },
    {
      compound: true,
      filter: {
        joinOp: 'or',
        conditions: [
          {
            property: 'started',
            op: 'eq',
            value: false,
            dataType: 'boolean',
          },
          {
            property: 'started',
            op: 'isNull',
          }
        ]
      }
    }
  ];

  assessmentConditions = [
    {
      compound: true,
      filter: {
        joinOp: 'or',
        conditions: [
          {
            property: 'test.passed',
            op: 'eq',
            value: false,
            dataType: 'boolean',
          },
          {
            property: 'test.passed',
            op: 'isNull',
          }
        ]
      }
    },
    {
      compound: true,
      filter: {
        joinOp: 'or',
        conditions: [
          {
            property: 'test.taken',
            op: 'eq',
            value: false,
            dataType: 'boolean',
          },
          {
            property: 'test.taken',
            op: 'isNull',
          }
        ]
      }
    },
  ];

  constructor(
    private _router: Router,
    private _authService: AuthService,
    public _appContextService: AppContextService,
    private lmsEnrollmentLessonService: LmsEnrollmentLessonService,
    private lmsEnrollmentLessonTestService: LmsEnrollmentLessonTestService,
    private messageModalService: MessageModalService,
    protected _cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {

    this.loading = true;
    let obs = [];
    obs.push(this.lmsEnrollmentLessonService.getAggregatedData(
      {
        queryOptions: {
          aggregation: {
            timeProperty: '',
            segmentation: {
              time: '',
              cycleTime: false,
              properties: ['lmsEnrollment.lmsUser'],
            },
            properties: []
          },
          filter: {
            joinOp: 'and',
            conditions: this.courseConditions
          }
        }
      }
    ));

    obs.push(this.lmsEnrollmentLessonTestService.getAggregatedData(
      {
        queryOptions: {
          aggregation: {
            timeProperty: '',
            segmentation: {
              time: '',
              cycleTime: false,
              properties: ['testerLmsUser'],
            },
            properties: []
          },
          filter: {
            joinOp: 'and',
            conditions: this.assessmentConditions
          }
        }
      }
    ));

    forkJoin(obs).subscribe((res: any) => {
      let courses = res[0].data;
      let assessments = res[1].data;

      this.pendingCourseCount = _.sumBy(courses, '_count');
      this.pendingAssessmentCount = _.sumBy(assessments, '_count');

      this.loading = false;
      this._cd.markForCheck();
    }, (err) => {
      this.loading = false;
      this.messageModalService.alert('Failed to load data.');
      this._cd.markForCheck();
    });

  }

  logOut() {
    // this.ui.loggingOut = true;
    this._authService.logOut()
      .subscribe((data) => {
        this.completeLogout(false);
      }, (err) => {
        this.completeLogout(true);
      });
  }

  completeLogout(error: boolean = false) {
    // Remove client-side session data whether or not log out request succeeded.
    this._authService.clearStoredUserInfo();
    this._appContextService.clearData();
    // this.ui.loggingOut = false;
    this._router.navigate(['/login']);
    // this._router.navigate([this._loginService.getLoginRoute()]);
  }

}

import { Observable, forkJoin } from 'rxjs';
import * as _ from 'lodash';

export class FileUtils {
  static readFileAsync(file) {
    return new Observable(observer => {
      let fileModel = null;
      if (file) {
        const reader = new FileReader();

        reader.onload = (readerEvt) => {
          const binaryString = (readerEvt.target as FileReader).result as string;
          const base64File = btoa(binaryString);
          fileModel = {
            data: base64File,
            originalFilename: file.name,
            mimeType: file.type,
            sizeBytes: file.size,
          };
          observer.next(fileModel);
          observer.complete();
        };
        reader.readAsBinaryString(file);
      }
    });
  }

  static createFileInputTrigger(inputChangeEventListener: (x: any) => void, multiple = true, el = null) {
    el = (el || document.body);
    const input: any = document.createElement('input');
    el.appendChild(input);
    input.style = 'display: none;-x: 0';
    input.type = 'file';
    if (multiple) {
      input.multiple = '<a non-empty string>';
    }
    input.addEventListener('change', inputChangeEventListener);
    return input;
  }

  static destroyFileInputTrigger(input, inputChangeEventListener: (x: any) => void, el = null) {
    el = (el || document.body);
    input.removeEventListener('change', inputChangeEventListener);
    el.removeChild(input);
  }

  static createFileInputChangeListener(callback: (x: any) => void): (x: any) => void {
    return (event: any) => {
      const observables: any = [];

      if (event.target) {
        _.forEach(event.target.files, (file) => {
          observables.push(FileUtils.readFileAsync(file));
        });

        forkJoin(observables).subscribe((files) => {
          callback(files);
        });
      }
    };
  }

  static promptForSelect(callback: (x: any) => {}, multiple = false) {
    let changeListener: (x: any) => void = null;
    let domElement: any = null;
    const onChange = (x: any) => {
      FileUtils.destroyFileInputTrigger(domElement, changeListener);
      callback(multiple ? x : x[0]);
    };
    changeListener = FileUtils.createFileInputChangeListener(onChange);
    domElement = FileUtils.createFileInputTrigger(changeListener, multiple);
    domElement.click();
  }

  static promptForSingleSelect(callback: (x: any) => {}) {
    return this.promptForSelect(callback, false);
  }

  static promptForMultipleSelect(callback: (x: any) => {}) {
    return this.promptForSelect(callback, true);
  }
}

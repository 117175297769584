import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { BaseControlValueAccessor, BASE_CONTROL_VALUE_ACCESSOR } from '../../shared/utils/base-control-value-accessor';

export const CHECKBOX_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckboxComponent),
    multi: true
};

@Component({
  selector: 'xsi-checkbox',
  templateUrl: 'checkbox.html',
  styleUrls: ['checkbox.scss'],
  providers: [CHECKBOX_CONTROL_VALUE_ACCESSOR],
  host: {
    '(click)': 'onClick($event)',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent extends BaseControlValueAccessor {
  @Input('disabled') _disabled: boolean;
  @Input() readonly: boolean;
  @Input() required: boolean;
  @Input() noBackground: boolean = false;

  constructor(protected _cd: ChangeDetectorRef) {
    super(_cd);
  }

  // BaseControlValueAccessor function
  writeValue(value: any) {
    if (value !== this._value) {
      this._value = value;
      this._cd.markForCheck();
    }
  }

  onClick(event: Event) {
    this.onTouchedCallback();
  }

  onChange(event) {
    this.setValue(event.target.checked);
  }
}

import * as moment from 'moment';
import * as _ from 'lodash';

export class DisplayTextUtils {
  static employeeDisplayText(employeeProperty = null) {
    return function(o) {
      if (!o) return;

      if (employeeProperty) {
        // let emp = o[employeeProperty];
        let emp = DisplayTextUtils.getValueOfObject(o, employeeProperty);
        if (!emp) return;
        // return emp.fullNameLastNameFirst;
        return emp.name || `${emp.lastName || ''}, ${emp.firstName || ''} ${emp.middleName || ''} ${emp.suffix || ''}`;
      } else {
        // return o.fullNameLastNameFirst;
        return o.name || `${o.lastName || ''}, ${o.firstName || ''} ${o.middleName || ''} ${o.suffix || ''}`;
      }
    }
  }

  static getValueOfObject(obj, path) {
    let splittedPath = path.split('.');
    let result = obj;

    for (let x = 0; x < splittedPath.length; x++) {
        result = result[splittedPath[x]];
    };

    return result;
  }

  static id(o) {
    return o.id;
  }

  static idWithDocumentNumberDisplayText(o) {
    if (!o) return null;

    let segments = _.filter([o.id, o.documentNumber], x => x);
    return _.join(segments, ' ');
  }

  static dayOfWeek(date) {
    const lookup = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return lookup[moment(date).day()] || null;
  }
}

import { Component, ChangeDetectionStrategy, ChangeDetectorRef, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { BaseBreadService } from '../../../shared/services/base-bread.service';
import { MessageModalService } from '../../message-modal/message-modal';
import { DomSanitizer } from '@angular/platform-browser';

import { FileInputComponent } from '../file-input.component';

export const FILE_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => LMSFileInputComponent),
  multi: true,
};

@Component({
  selector: 'xsi-lms-file-input',
  templateUrl: 'lms-file-input.component.html',
  styleUrls: [
    '../image-input.component.scss',
    'lms-file-input.component.scss',
  ],
  providers: [FILE_INPUT_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LMSFileInputComponent extends FileInputComponent {
  constructor(
    protected _cd: ChangeDetectorRef,
    protected _baseBreadService: BaseBreadService,
    protected _messageModalService: MessageModalService,
    protected sanitizer: DomSanitizer,
  ) {
    super(_cd, _baseBreadService, _messageModalService, sanitizer);
  }
}


import { forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

export const NOOP_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => NoopControlValueAccessor),
    multi: true
};

export class NoopControlValueAccessor implements ControlValueAccessor {
  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (_: any) => void = () => {};

  writeValue(value: any) {
    console.warn('NoopControlValueAccessor: quick fix to value accessor issues encountered after switching to angular2.0, does virtually nothing\nTODO: Implement a proper fix.');
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

// angular's date pipe now accepts iso string, but not on this version
@Pipe({name: 'xsiDate'})
export class DatePipe implements PipeTransform {
  transform(value:any, args:string = 'YYYY-MM-DD') : any {
    return value ? moment.utc(value).format(args) : null ;
  }
}

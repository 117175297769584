import { Injectable, Component, OnDestroy, HostBinding, ComponentFactoryResolver, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import * as _ from 'lodash';

class MessageType {
  static INFO: String = 'info';
  static WARN: String = 'warn';
  static ERROR: String = 'error';
}

@Component({
  selector: 'xsi-message-modal',
  templateUrl: 'message-modal.html',
  host: { 'class': 'modal' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageModal implements OnDestroy {
  @HostBinding('class.invisible') hidden: boolean = true;
  @ViewChild('yesButton') yesButton;

  protected yesButtonLabel;
  protected noButtonLabel;
  protected message;
  protected messageType;

  protected _yesCallback;
  protected _noCallback;

  constructor(protected _cd: ChangeDetectorRef) {
  }

  theme = '';

  show(config, onYes = null, onNo = null) {
    setTimeout(() => {
      this.yesButton.nativeElement.focus();
    });

    if (!this.hidden) {
      throw 'Message modal is already shown';
    }

    this._yesCallback = onYes;
    this._noCallback = onNo;
    if (config) {
      this.yesButtonLabel = config.yesButtonLabel;
      this.noButtonLabel = config.noButtonLabel;
      this.message = config.message;
      this.messageType = config.messageType;
    }
    this.hidden = false;
    this._cd.markForCheck();
  }

  ngOnDestroy() {
    console.debug('MessageModal destroyed');
  }

  onYes() {
    let yesCallback = this._yesCallback;
    this._yesCallback = null;

    this.hidden = true;

    if (_.isFunction(yesCallback)) {
      yesCallback();
    }
    this._cd.markForCheck();
  }

  onNo() {
    this.hidden = true;
    if (_.isFunction(this._noCallback)) {
      this._noCallback();
    }
    this._noCallback = null;
    this._cd.markForCheck();
  }
}

@Injectable()
export class MessageModalService {

  _contentContainer;
  _cachedComponent;

  constructor(protected _componentResolver: ComponentFactoryResolver) {
  }

  initialize(contentContainer) {
    if (!this._contentContainer) {
      this._contentContainer = contentContainer;
    } else {
      console.error("Message modal already initialized");
    }
  }

  message(config, yesCallback = null, noCallback = null) {
    let show = () => {
      return this._cachedComponent.instance.show(config,
        () => { if (_.isFunction(yesCallback)) { yesCallback(); } },
        () => { if (_.isFunction(noCallback)) { noCallback(); } }
      );
    };
    if (this._cachedComponent) {
      show();
    } else {
      Promise.resolve(this._componentResolver.resolveComponentFactory(MessageModal)).then((factory) => {
        let componentRef = this._contentContainer.createComponent(factory, this._contentContainer.length, this._contentContainer.injector);
        this._cachedComponent = componentRef;
        show();
        // return componentRef.instance.show(config,
        //   () => {
        //     if (_.isFunction(yesCallback)) {
        //       yesCallback();
        //     }
        //     componentRef.destroy();
        //   },
        //   () => {
        //     if (_.isFunction(noCallback)) {
        //       noCallback();
        //     }
        //     componentRef.destroy();
        //   }
        // );
      });
    }
  }

  alert(message: string, callback?: any) {
    return this.message({ message: message, yesButtonLabel: 'OK' }, callback);
  }

  info(message: string, callback?: any) {
    return this.message({ message: message, yesButtonLabel: 'OK', messageType: MessageType.INFO }, callback);
  }

  warn(message: string, callback?: any) {
    return this.message({ message: message, yesButtonLabel: 'OK', messageType: MessageType.WARN }, callback);
  }

  error(message: string, callback?: any) {
    return this.message({ message: message, yesButtonLabel: 'OK', messageType: MessageType.ERROR }, callback);
  }

  confirm(message: any, yesCallback?: any, noCallback?: any) {
    return this.message(this.getModalConfig(message), yesCallback, noCallback);
  }

  private getModalConfig(source) {
    if (_.isObject(source)) return source;
    return { message: source, yesButtonLabel: 'Yes', noButtonLabel: 'No' };
  }
}

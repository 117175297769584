import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup as ControlGroup, Validators, FormControl as Control } from '@angular/forms';

import * as _ from 'lodash';

@Component({
  selector: 'xsi-pagination',
  templateUrl: 'pagination.component.html',
  styleUrls: ['pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class PaginationComponent implements OnInit {
  @Input()  maxFilter: any;
  @Input()  pagination: any = {
    total: 1000,
    max: 20,
    offset: 0,
    numItems: null
  };
  @Input()  disabled: boolean = false;
  @Output() refresh = new EventEmitter();

  @ViewChild('pageDisplay') pageDisplay;

  currentPage: number = 1;

  theForm: ControlGroup;

  constructor(private _formBuilder: FormBuilder, protected _cd: ChangeDetectorRef) {

  }

  ngOnInit() {
    this.currentPage = this.getCurrentPage();
    this.theForm = this._formBuilder.group(this.createControls());
    this._cd.markForCheck();
  }

  createControls() {
    let controls: any = {};
    controls.max = new Control(20, Validators.required);
    return controls;
  }

  lastPage() {
    return Math.ceil(this.pagination.total / this.pagination.max - 1);
  }

  backward(): boolean {
    return this.pagination.offset > 0;
  }

  forward(): boolean {
    if (this.pagination.total == null) return this.pagination.numItems;
    return this.pagination.total > this.pagination.max + this.pagination.offset;
  }

  forwardToEnd(): boolean {
    if (this.pagination.total == null) return false;
    return this.forward();
  }

  displayStart(): number {
    if (this.pagination.total == null) {
      return this.pagination.numItems || true ? this.pagination.offset + 1 : null;
    } else {
      return this.pagination.total > 0 ? this.pagination.offset + 1 : 0;
    }
  }

  displayEnd(): number {
    if (this.pagination.total == null) {
      return this.pagination.numItems ? this.pagination.offset + this.pagination.numItems : null; 
    } else {
      return Math.min(this.pagination.offset + this.pagination.max, this.pagination.total);
    }
  }

  updatePage(pageNumber: number = 0) {
    this.pagination.offset = pageNumber * this.pagination.max;
    this.currentPage = this.getCurrentPage();
    // this.refresh.emit(true);
    this.refreshEmit();
    this._cd.markForCheck();
  }

  refreshEmit(dataToEmit = null) {
    this.refresh.emit(_.isNil(dataToEmit) ? true : dataToEmit);
  }

  getCurrentPage() {
    return Math.ceil(this.pagination.offset / this.pagination.max)+1;
  }

  currentPageChange() {
    let cp = (!this.currentPage ? 1 : this.currentPage);

    if (this.currentPage - 1 > this.lastPage()) cp = this.lastPage() + 1;

    this.updatePage(cp - 1);
  }

  previousPage() {
    if (this.backward()) {
      this.updatePage(this.currentPage - 2);
    }
  }

  nextPage() {
    if (this.forward()) {
      this.updatePage(this.currentPage);
    }
  }

  markForCheck() {
    this._cd.markForCheck();
  }
}

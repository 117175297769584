import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { BaseBreadService } from '../../shared/services/base-bread.service';

import { CalendarConfig } from './definitions';

import * as _ from 'lodash'

@Injectable()
export class LmsBaseCalendarEventService {
  private _items: BehaviorSubject<any[]> = new BehaviorSubject([]);

  get items() {
    return this._items.asObservable();
  }

  editItem(event: any, eventService: BaseBreadService) {
    const observable = eventService.edit(event.id, event);
    observable.subscribe();
    return observable;
  }

  fetchEvents(calendarConfig: CalendarConfig[], queryOptions: any = {}, pagination: any = {}) {
    const observableServices = calendarConfig.map((config) => {
      if (!_.isNil(config.additionalFilterCondition) && _.isArray(config.additionalFilterCondition)) {
        queryOptions.filter.conditions.push(...config.additionalFilterCondition);
      }
      return config.service.browse(pagination, queryOptions);
    });

    return new Promise((resolve, reject) => {
      forkJoin(observableServices)
        .subscribe(
          (res) => {
            const events: any = [];

            res.forEach((response: any, index) => {
              let records = response.data.records;

              if (!_.isNil(calendarConfig[index].preprocessFn) && _.isFunction(calendarConfig[index].preprocessFn)) {
                records = calendarConfig[index].preprocessFn(records);
              }

              events.push(...records);
            });

            this._items.next([...events]);
            resolve([...events]);
          },
          (err) => {
            reject(err);
          },
        );
    });
  }
}


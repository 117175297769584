import { RequestMethod } from '../../enums/request-methods';
import { ApiService } from '../../api.service';
import { BaseBreadService, BreadServiceDecorator } from '../../base-bread.service';
import { Injectable } from '@angular/core';

@BreadServiceDecorator({
  resourceName: 'test'
})

@Injectable()
export class TestService extends BaseBreadService {
  constructor(public apiService: ApiService) {
    super(null, null, apiService);
  }

  submitAnswers(test) {
    const requestData = { properties: test };
    return this.apiService.requestToJsonApi(RequestMethod.POST, this.getRequestUrl('setAnswers', 'test'), {}, requestData);
  }
}

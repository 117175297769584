import { RequestMethod } from '../../enums/request-methods';
import { ApiService } from '../../api.service';
import { ConfigService } from '../../config.service';
import { BaseBreadService, BreadServiceDecorator } from '../../base-bread.service';
import { Injectable } from '@angular/core';

@BreadServiceDecorator({
  resourceName: 'lmsUser'
})

@Injectable()
export class LmsUserService extends BaseBreadService {
  constructor(public apiService: ApiService, private configService: ConfigService) {
    super(null, null, apiService);
  }

  register(requestData: any) {
    const action = 'register';
    const resource = 'lmsUser';
    const registerUrl = this.configService.getPublicApiBaseUrl() + (resource ? ('/' + resource) : '') + (action ? ('/' + action) : '');

    return this.apiService.requestToJsonApi(RequestMethod.POST, registerUrl, {}, requestData);
  }
}

import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { DisplayTextUtils } from '../../../../shared/utils/display-text-utils';
import { MessageModalService } from '../../../../commons/message-modal/message-modal';
import { TestTemplatePartQuestionService } from '../../../../shared/services/api/test/test-template-part-question.service';
import { TestTemplateService } from '../../../../shared/services/api/test/test-template.service';
import { AssessmentTestPreviewPaginationComponent } from './assessment-test-preview-pagination/assessment-test-preview-pagination.component';

import * as _ from 'lodash';

@Component({
  selector: 'app-assessment-test-preview',
  templateUrl: './assessment-test-preview.component.html',
  styleUrls: ['./assessment-test-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssessmentTestPreviewComponent implements OnInit {
  @ViewChild(AssessmentTestPreviewPaginationComponent, {static: false}) testPreviewPaginationComponent: AssessmentTestPreviewPaginationComponent;

  @Input() templateId = null;
  @Input() testService = null;
  @Input() questionService = null;
  @Input() partSource = null;

  @Output() submitAnswers = new EventEmitter();

  testTemplateModel = null;
  currentPartData = { id: 0, questions: [] };
  currentPartIndex = 0;
  pagination = null;
  loading = false;

  private _questionAndAnswer = [];
  private _displayTextUtils = DisplayTextUtils;

  constructor(
    protected _testTemplateService: TestTemplateService,
    protected _testTemplatePartQuestionService: TestTemplatePartQuestionService,
    protected _cd: ChangeDetectorRef,
    protected _modalService: MessageModalService,
  ) { }

  ngOnInit(): void {
    this.loadQuestions();
  }

  loadQuestions() {
    if (this.templateId) {
      this.loading = true;
      this.testService.read(this.templateId).subscribe((res) => {
        this.testTemplateModel = res.data;
        for (let x = 0; x < this.testTemplateModel.parts.length; x++) {
          if (!this.testTemplateModel.parts[x].questions.length) {
            this.testTemplateModel.parts.splice(x, 1);
          }
        }
        if (!this.testTemplateModel.numQuestionsPerPage) { this.testTemplateModel.numQuestionsPerPage = 1; }
        this.initializeListOfQuestions();
      }, (err) => {
        this.loading = false;
        this._cd.markForCheck();
        this._modalService.alert('The server threw an error');
      });
    } else {
      this.loading = false;
      this._cd.markForCheck();
    }
  }

  initializeListOfQuestions() {
    this.initializePaginationPartData();
    this.sortModelContents();
    this.updateListOfQuestions(this.testTemplateModel.parts[this.currentPartIndex]);
  }

  initializePaginationPartData() {
    this.initializePagination();
    this.currentPartData = { id: 0, questions: [] };
    this.currentPartIndex = 0;
  }

  initializePagination() {
    this.pagination = {
      total: 0,
      currentPage: 0,
      max: this.testTemplateModel.numQuestionsPerPage,
      offset: 0,
    };
  }

  updateListOfQuestions(currentPart) {
    if (currentPart) {
      return new Promise((resolve, reject) => {
        // this.loading = true;
        this.fetchTestTemplatePartQuestion(this.pagination, this.createTemplatePartQuestionFilter(currentPart)).subscribe((res) => {
          if (res.data.records.length) {
            this.updatePagination(res.data.pagination.total);
            this.currentPartData = _.cloneDeep(currentPart);
            this.currentPartData.questions = this.assignQuestionNumber(res.data.records);
            this.sortAnswers();

            if (this._questionAndAnswer.length) { this.setAnswerToQuestions(); }
          } else {
            this.testPreviewPaginationComponent.currentPage = 0;
            this.testPreviewPaginationComponent.markForCheck();
          }

          this.loading = false;
          this._cd.markForCheck();
          resolve(res.data);
        }, (err) => {
          this.loading = false;
          this.pagination.total = 0;
          this._cd.markForCheck();
          this._modalService.alert('The server threw an error.');
          reject(err);
        });
      });
    }
  }

  sortAnswers() {
    _.forEach(this.currentPartData.questions, (el) => {
      if (el.questionType) {
        if (el.questionType && (el.questionType.substr(0, 15) === 'multiple_choice')) {
          el.answerOptions = _.orderBy(el.answerOptions, ['rank', 'answer']);
        }
      } else {
        if (el.testQuestion.questionType && (el.testQuestion.questionType.substr(0, 15) === 'multiple_choice')) {
          el.testQuestion.answerOptions = _.orderBy(el.testQuestion.answerOptions, ['rank', 'answer']);
        }
      }
    });
  }

  setAnswerToQuestions() {
    _.forEach(this.currentPartData.questions, (question) => {
      const foundQuestionEntry = this.getQuestionEntry(question);

      if (foundQuestionEntry) {
        if (question.questionType && (question.questionType.substr(0, 15) === 'multiple_choice')) {
          _.forEach(foundQuestionEntry.answers, (sa) => {
            const foundAnswerOption = _.find(question.answerOptions, (question) => {
              return sa.id === question.id;
            });

            if (foundAnswerOption) { foundAnswerOption.value = true; }
          });
        } else if (question.questionType && (question.questionType === 'fill_in_the_blanks')) {
          question.fillInTheBlanksAnswer_ = foundQuestionEntry.answers[0];
        } else {
          question.essayAnswer_ = foundQuestionEntry.answers[0];
        }
      }
    });
  }

  fetchTestTemplatePartQuestion(pagination, filter) {
    return this.questionService.browse(pagination, {filter, sort: [{dir: 'asc', property: 'rank'}]});
  }

  updatePagination(paginationTotal) {
    if (this.pagination) {
      // update pagination being called before pagination component has been loaded. Temporary fix.
      setTimeout(() => {
        this.pagination.total = Number(paginationTotal);
        if (this.testPreviewPaginationComponent) {
          this.disableEnableNextButton();
          this.disableEnablePrevButton();
          this.testPreviewPaginationComponent.markForCheck();
        }
      });
    }
  }

  createTemplatePartQuestionFilter(testTemplatePart) {
    return {
      conditions: [
        {
          property: this.partSource + '.id',
          value: testTemplatePart.id,
          dataType: 'long'
        }
      ]
    };
  }

  assignQuestionNumber(questions) {
    return _.map(questions, (el, index) => {
      el.questionNumber = this.pagination.offset + (index + 1);
      return el;
    });
  }

  sortModelContents() {
    this.testTemplateModel.parts = _.sortBy(this.testTemplateModel.parts, ['rank', 'name']);
    this.testTemplateModel.parts.forEach( (part: any) => {
      part.questions = _.sortBy(part.questions, ['rank', 'name']);
      part.questions.forEach( (question: any) => {
        question.answerOptions = _.sortBy(question.answerOptions, ['rank', 'answer']);
      });
    });
  }

  disableEnableNextButton() {
    console.log(this.testTemplateModel.parts.length - 1)
    if (this.currentPartIndex === (this.testTemplateModel.parts.length - 1)) {
      this.testPreviewPaginationComponent.toggleButton('next', !this.testPreviewPaginationComponent.forward());
      this.testPreviewPaginationComponent.toggleButton('nextChapter', true);
    } else {
      if (!this.testPreviewPaginationComponent.forward()) {
        this.testPreviewPaginationComponent.toggleButton('nextChapter', false);
        this.testPreviewPaginationComponent.toggleButton('next', true);
      } else {
        this.testPreviewPaginationComponent.toggleButton('nextChapter', true);
        this.testPreviewPaginationComponent.toggleButton('next', false);
      }

    }
  }

  disableEnablePrevButton() {
    if (this.currentPartIndex === 0) {
      this.testPreviewPaginationComponent.toggleButton('prev', !this.testPreviewPaginationComponent.backward());
      this.testPreviewPaginationComponent.toggleButton('previousChapter', true);
    } else {
      if (!this.testPreviewPaginationComponent.backward()) {
        this.testPreviewPaginationComponent.toggleButton('previousChapter', false);
        this.testPreviewPaginationComponent.toggleButton('prev', true);
      } else {
        this.testPreviewPaginationComponent.toggleButton('previousChapter', true);
        this.testPreviewPaginationComponent.toggleButton('prev', false);
      }
    }
  }

  pageChange(event) {
    if (event === 'prev' && this.pagination.offset < 0) {
      this.currentPartIndex -= 1;
      const paginationConfig = this.getOffset(this.getTotalNumberOfQuestions());
      this.pagination.offset = paginationConfig.offset;
      if (this.testPreviewPaginationComponent) { this.testPreviewPaginationComponent.currentPage = paginationConfig.currentPage; }
    }

    this.setQuestions();
  }

  getTotalNumberOfQuestions() {
    return this.testTemplateModel.parts[this.currentPartIndex].questions.length;
  }

  getOffset(totalNumberOfQuestions) {
    const quotient = Math.floor(totalNumberOfQuestions / this.testTemplateModel.numQuestionsPerPage);
    const remainder = totalNumberOfQuestions % this.testTemplateModel.numQuestionsPerPage;
    const obj = {
      offset: (remainder ? quotient : (quotient - 1)) * this.testTemplateModel.numQuestionsPerPage,
      currentPage: (remainder ? (quotient + 1) : quotient)
    };

    return obj;
  }

  setQuestions() {
    this.updateListOfQuestions(this.testTemplateModel.parts[this.currentPartIndex]).then((res: any) => {
      if (!res.records.length) {
        if (this.testTemplateModel.parts[this.currentPartIndex + 1]) {
          if (this.testPreviewPaginationComponent) { this.testPreviewPaginationComponent.currentPage += 1; }

          this.initializePagination();
          this.currentPartIndex += 1;
          this.updateListOfQuestions(this.testTemplateModel.parts[this.currentPartIndex]);
        }
      }
    });
  }

  getEntryFrom(questionRow, entryToGet) {
    const entry = '';
    const question = '';
    const entryNumber = '';

    if (questionRow.testQuestion[entryToGet]) {  return questionRow.testQuestion[entryToGet]; }
    return questionRow[entryToGet];

    // if (questionRow.testQuestion[entryToGet]) {
    //   question += questionRow.testQuestion[entryToGet];
    // } else {
    //   question += questionRow[entryToGet];
    // }

    // if (_.get(questionRow, 'questionNumber')) entryNumber = `${_.get(questionRow, 'questionNumber')}.`;

    // if (entryNumber) {
    //   entry = `${entryNumber} ${question}`;
    // } else {
    //   entry = question;
    // }

    // return entry;
  }

  onSubmitClick() {
    this._modalService.confirm('Are you sure you want to submit your test?', () => {
      if (this.getOverallTotalOfQuestions() !== this._questionAndAnswer.length) {
        this._modalService.confirm(
          {
            message: 'Some items were not answered. Do you still want to submit your test?',
            yesButtonLabel: 'Submit',
            noButtonLabel: 'Cancel',
          },
          () => {
            this.submitAnswers.emit({
              testModel: this.testTemplateModel,
              questionAndAnswer: this._questionAndAnswer,
              thankYouMessage: this.testTemplateModel.thankYouPageMessageText,
            });
          },
        );
      } else {
        this.submitAnswers.emit({
          testModel: this.testTemplateModel,
          questionAndAnswer: this._questionAndAnswer,
          thankYouMessage: this.testTemplateModel.thankYouPageMessageText,
        });
      }
    });
  }

  getOverallTotalOfQuestions() {
    return _.sumBy(this.testTemplateModel.parts, (part) => {
      return part.questions.length;
    });
  }

  testAnswer(questionRow, answerOption) {
    const foundQuestionEntry = this.getQuestionEntry(questionRow);
    const questionType = (questionRow.questionType || questionRow.testQuestion.questionType);

    if (!foundQuestionEntry) {
      this.createNewEntryForQuestionAndAnswer(questionRow, answerOption);
    } else {
      if (questionType && (questionType.substr(0, 15) === 'multiple_choice')) {
        if (questionType === 'multiple_choice_single_answer') { this.clearOtherAnswerOptions(questionRow, answerOption); }
        this.setAnswerForMultipleChoiceTypeQuestion(questionType, foundQuestionEntry, answerOption);
      } else {
        this.setAnswerForEssayOrFillInTheBlanks(foundQuestionEntry, answerOption);
      }
    }
  }

  clearOtherAnswerOptions(questionRow, answerOption) {
    const answerOptions = (questionRow.answerOptions || questionRow.testQuestion.answerOptions);

    if (answerOption.value) {
      _.forEach(answerOptions, (ao) => {
        if (answerOption.id !== ao.id) { ao.value = false; }
      });
    }
  }

  getQuestionEntry(questionRow) {
    return _.find(this._questionAndAnswer, (el) => {
      return (el.id === questionRow.id);
    });
  }

  createNewEntryForQuestionAndAnswer(questionRow, answerOption) {
    this._questionAndAnswer.push({
      id: questionRow.id,
      answers: [answerOption]
    });
  }


  setAnswerForMultipleChoiceTypeQuestion(questionType, foundQuestionEntry, answerOption) {
    if (answerOption.value) {
      if (questionType === 'multiple_choice_single_answer') {
        foundQuestionEntry.answers = [];
        foundQuestionEntry.answers.push(answerOption);
      } else {
        foundQuestionEntry.answers.push(answerOption);
      }
    } else {
      _.remove(foundQuestionEntry.answers, (el) => {
        return el.id === answerOption.id;
      });
    }

    if (!foundQuestionEntry.answers.length) { this.removeQuestionAndAnswer(foundQuestionEntry); }
  }

  setAnswerForEssayOrFillInTheBlanks(foundQuestionEntry, answerOption) {
    if (answerOption) { foundQuestionEntry.answers[0] = answerOption; }
    else { this.removeQuestionAndAnswer(foundQuestionEntry); }
  }

  removeQuestionAndAnswer(questionAndAndAnswerEntry) {
    _.remove(this._questionAndAnswer, (el) => {
      return el.id === questionAndAndAnswerEntry.id;
    });
  }

  getApplicantFullName() {
    if (this.testTemplateModel && this.testTemplateModel.employee) { return this._displayTextUtils.employeeDisplayText()(this.testTemplateModel.employee); }
    else { return ''; }
  }

}

import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppRouterComponent } from './app-router.component';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { BranchSelectorComponent } from './branch-selector/branch-selector.component';
import { RegistrationComponent } from './registration/registration.component';
import { CommonsModule } from '../commons/commons.module';
import { HomeDashboardComponent } from './home-dashboard/home-dashboard.component';
import { CurriculumComponent } from './curriculum/curriculum.component';
import { AssessmentsComponent } from './assessments/assessments.component';
import { AssessmentTestComponent } from './assessments/assessment-test/assessment-test.component';
import { CertificatesComponent } from './certificates/certificates.component';
import { AssessmentTestPreviewComponent } from './assessments/assessment-test/assessment-test-preview/assessment-test-preview.component';
import { AssessmentTestPreviewPaginationComponent } from './assessments/assessment-test/assessment-test-preview/assessment-test-preview-pagination/assessment-test-preview-pagination.component';

import { AppConfigService } from '../shared/services/app-config.service';
import { ConfigService } from '../shared/services/config.service';
import { AuthService } from '../shared/services/auth.service';
import { ApiRequestDataService } from '../shared/services/api-request-data.service';
import { ApiService } from '../shared/services/api.service';
import { BaseBreadService } from '../shared/services/base-bread.service';
import { SecUserService } from '../shared/services/sec-user.service';
import { AppUserService } from '../shared/services/user.service';
import { MessageModalService } from '../commons/message-modal/message-modal';
import { LMSMessageModalService } from '../commons/message-modal/lms/lms-message-modal';
import { HttpClientModule } from '@angular/common/http';
import { API_SERVICE_PROVIDERS, AppContextService } from '../shared/services/api';
import { PublicApiService } from '../shared/services/public-api.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentLoader } from '../shared/services/component-loader';
import { ProfileModule } from './profile/profile.module';
import { CalendarComponent } from './calendar/calendar.component';
import { CourseContentComponent } from './course-content/course-content.component';
import { LmsBaseCalendarEventService } from './calendar/base-calendar.service';

import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { ResizeService } from '../shared/services/resize.service';

@NgModule({
  declarations: [
    AppRouterComponent,
    [
      AppComponent,
      LoginComponent,
      BranchSelectorComponent,
      RegistrationComponent,
      HomeDashboardComponent,
      CurriculumComponent,
      CertificatesComponent,
      AssessmentsComponent,
      AssessmentTestComponent,
      AssessmentTestPreviewComponent,
      AssessmentTestPreviewPaginationComponent,
      CalendarComponent,
      CourseContentComponent
    ]
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CommonsModule.forRoot(),
    ProfileModule.forRoot(),
    PdfJsViewerModule
  ],
  providers: [
    Title,
    { provide: APP_BASE_HREF, useValue: '' },
    AppConfigService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: (appConfigService: AppConfigService) => function() { return appConfigService.loadConfig(); },
    //   deps: [AppConfigService],
    //   multi: true
    // },
    ConfigService,
    // AppService,
    ApiService,
    ApiRequestDataService,
    AuthService,
    // LoginService,
    SecUserService,
    AppContextService,
    PublicApiService,
    API_SERVICE_PROVIDERS,
    BaseBreadService,
    // AuthGuard,
    ComponentLoader,
    LmsBaseCalendarEventService,
    ResizeService
  ],
  bootstrap: [AppRouterComponent]
})
export class AppModule { }

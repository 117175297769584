import { NgModule, ModuleWithProviders } from '@angular/core';
import { ProfileDetailsComponent } from './profile-details/profile-details.component';
import { ProfileAttachmentComponent } from './profile-attachment/profile-attachment.component';
import { ProfileComponent } from './profile.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    ProfileComponent,
    ProfileDetailsComponent,
    ProfileAttachmentComponent
  ],
  exports: [
    ProfileComponent,
    ProfileDetailsComponent,
    ProfileAttachmentComponent
  ],
  entryComponents: [],
})
export class ProfileModule {
  static forRoot(): ModuleWithProviders<ProfileModule> {
    return {
      ngModule: ProfileModule,
      providers: [],
    };
  }
}

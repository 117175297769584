import { ApiService } from '../../api.service';
import { BaseBreadService, BreadServiceDecorator } from '../../base-bread.service';
import { Injectable } from '@angular/core';

@BreadServiceDecorator({
  resourceName: 'customer'
})

@Injectable()
export class CustomerService extends BaseBreadService {
  constructor(public apiService: ApiService) {
    super(null, null, apiService);
  }

  saveCustomer(customerDetails: any) {
    return !customerDetails.id ? this.add(customerDetails) : this.edit(customerDetails.id, customerDetails);
  }
}

import { Directive, Input, ElementRef, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({
  selector: '[formControl][xsiDisabledIf]'
})
export class DisabledIfDirective implements OnChanges {
  constructor(private _el: ElementRef) { }

  @Input('formControl') testControl: FormControl = null;
  @Input('xsiDisabledIf') xsiDisabledIf = false;

  ngOnChanges(changes) {
    if (changes.xsiDisabledIf) this.disabledIfChanges(changes.xsiDisabledIf.currentValue);
    if (changes.testControl) this.formControlChanges(changes.testControl.currentValue);
  }

  private formControlChanges(control) {
    control.registerOnDisabledChange((res) => {
      if ((!!res) !== (!!this.xsiDisabledIf)) {
        this.updateControlStatus(this.xsiDisabledIf, control);
      }
    });
  }

  private disabledIfChanges(xsiDisabledIf) {
    this.updateControlStatus(xsiDisabledIf);
  }

  private updateControlStatus(disabledProp, control = null) {
    let c = control || this.testControl;
    let isControlDirty = false;

    if (c.dirty || c.root.dirty) isControlDirty = true;

    if (disabledProp) {
      c.disable();
    } else {
      if (c.root.status !== 'DISABLED') c.enable();
    }

    if (isControlDirty) c.markAsDirty();
  }
}

import { RequestMethod } from '../../enums/request-methods';

import { ApiService } from '../../api.service';
import { BaseBreadService, BreadServiceDecorator } from '../../base-bread.service';
import { AppContextService } from '../../app-context.service';

import * as _ from 'lodash';
import { Injectable } from '@angular/core';

@BreadServiceDecorator({
  resourceName: 'appSetting'
})

@Injectable()
export class AppSettingService extends BaseBreadService {
  constructor(public apiService: ApiService, public appContextService: AppContextService) {
    super(null, null, apiService);
  }

  save(object: any, noBranchContext: boolean = null) {
    let fxn = _.isArray(object) ? this.saveMany : this.saveOne;
    return fxn.call(this, object, noBranchContext);
  }

  saveOne(object: any, noBranchContext: boolean = null) {
    this.buildResourceForAdding(object);
    return this.apiService.requestToJsonApi(
      RequestMethod.POST, this.getRequestUrl('writeByName', null), null,
      { 'properties': object },
      false, false, noBranchContext,
    );
  }

  saveMany(objects: any, noBranchContext: boolean = null) {
    _.each(objects, (object) => {
      this.buildResourceForAdding(object);
    });
    return this.apiService.requestToJsonApi(
      RequestMethod.POST, this.getRequestUrl('writeByName', null), null,
      { 'properties': objects },
      false, false, noBranchContext,
    );
  }

  readByName(object: any, noBranchContext: boolean = null) {
    let obj = _.cloneDeep(object || {});
    if (_.isNil(obj.company)) {
      console.log('nil company', this.appContextService.getCompany());
      obj.company = this.appContextService.getCompany();
    }
    if (_.isNil(obj.companyBranch)) {
      obj.companyBranch = this.appContextService.getBranch();
    }
    return this.apiService.requestToJsonApi(
      RequestMethod.POST, this.getRequestUrl('readByName', null), null,
      { 'properties': obj },
      false, false, noBranchContext,
    );
  }
}

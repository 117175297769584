import { Directive, Input, OnInit, ElementRef, Renderer2, OnChanges } from '@angular/core';

@Directive({ selector: '[xsiFocus]' })
export class FocusDirective implements OnChanges {

  @Input('xsiFocus') isFocused: boolean;

  constructor(private hostElement: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes) {
    if (changes.isFocused.currentValue) {
      this.renderer.selectRootElement(this.hostElement.nativeElement).focus();
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({ name: 'sort', pure: false })
export class SortPipe implements PipeTransform {
  transform(items:any, property:string) : any {
    items.sort((a: any, b: any) => {
      let _a: any = _.get(a, property);
      let _b: any = _.get(b, property);
      if (_a < _b) {
        return -1;
      } else if (_a > _b) {
        return 1;
      } else {
        return 0;
      }
    });
    return items;
  }
}

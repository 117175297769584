import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { FileUtils } from '../../shared/utils/file-utils';

import { BaseBreadService } from '../../shared/services/base-bread.service';
import { MessageModalService } from '../message-modal/message-modal';
import { DomSanitizer } from '@angular/platform-browser';

export const FILE_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FileInputComponent),
  multi: true
};

@Component({
  selector: 'xsi-file-input',
  templateUrl: 'file-input.html',
  styleUrls: ['file-input.component.scss'],
  providers: [FILE_INPUT_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileInputComponent implements ControlValueAccessor {
  @Input() maxSizeBytes = 26214400; // 25mb
  @Input() disabled: boolean;

  protected _value: any = null;
  protected _isDisabled: boolean;
  protected _valueSource: string;

  constructor(
    protected _cd: ChangeDetectorRef,
    protected _baseBreadService: BaseBreadService,
    protected _messageModalService: MessageModalService,
    protected sanitizer: DomSanitizer,
  ) { }

  private onTouchedCallback = () => {};
  private onChangeCallback = (fn: any) => {};

  // ControlValueAccessor interface function
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  // ControlValueAccessor interface function
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // ControlValueAccessor interface function
  writeValue(value: any) {
    if (value !== this._value) {
      this._value = value;
      this.updateDisplay();
      this._cd.markForCheck();
    }
  }

  // ControlValueAccessor interface function
  setDisabledState(isDisabled: boolean) {
    this._isDisabled = isDisabled;
    this._cd.markForCheck();
  }

  setInternalValue(v: any) {
    if (v !== this._value) {
      this._value = v;
      this.updateDisplay();
      this.onChangeCallback(this._value);
      this._cd.markForCheck();
    }
  }

  updateDisplay() {
    // This is called whenever this._value changes. If you need to update the display accordingly, do it here.
  }

  downloadFile(resourceName: string, id: number, nameOverride: string) {
    let name = nameOverride;
    this._valueSource = this._baseBreadService.getResourceDownloadUrl(resourceName, { id: id, name: name }, name) + `&_timestamp=${new Date().getTime()}`;
    this._cd.markForCheck();
  }

  sanitizedSource() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this._valueSource+"&inline=1");
  }

  // ngAfterViewInit() {
  //   if (this.disabled) {
  //     this._isDisabled = this.disabled;
  //     this._cd.markForCheck();
  //   }
  // }

  clear() {
    this.setInternalValue(null);
  }

  onSelectClick() {
    FileUtils.promptForSingleSelect((event) => {
      if (this.maxSizeBytes !== null && event.sizeBytes > this.maxSizeBytes) {
        console.error('File too large');
        this._messageModalService.error('File is too big.');
        return;
      }
      this.setInternalValue(event);

      return {};
    });
  }

  onClearClick() {
    this.clear();
  }
}

import { Component, Input, Output, ChangeDetectionStrategy, ChangeDetectorRef, forwardRef, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, AbstractControl, Validator } from '@angular/forms';

import { FileInputComponent, FILE_INPUT_CONTROL_VALUE_ACCESSOR } from './file-input.component';

import { BaseBreadService } from '../../shared/services/base-bread.service';
import { MessageModalService } from '../message-modal/message-modal';
import { DomSanitizer } from '@angular/platform-browser';

import * as _ from 'lodash';

export const IMAGE_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ImageInputComponent),
  multi: true
};

export const IMAGE_INPUT_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => ImageInputComponent),
  multi: true
};

@Component({
  selector: 'xsi-image-input',
  templateUrl: 'image-input.html',
  styleUrls: ['image-input.component.scss'],
  providers: [IMAGE_INPUT_CONTROL_VALUE_ACCESSOR, IMAGE_INPUT_VALIDATOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageInputComponent extends FileInputComponent implements Validator {
  @Input() maxSizeBytes: number = 26214400; // 25mb
  @Input() imageWidth: number = null;
  @Input() imageHeight: number = null;
  @Input() disabled: boolean = false;
  @Input() requiredIfDoesNotExist: boolean = false;
  @Input() defaultImageName: string = null;
  protected exists: boolean;

  protected _busy: boolean = false;
  protected _load: boolean = false;
  protected _imageSource: string = null;
  protected _error: boolean;

  constructor(
    _cd: ChangeDetectorRef,
    _baseBreadService: BaseBreadService,
    _messageModalService: MessageModalService,
    sanitizer: DomSanitizer,
  ) {
    super(_cd, _baseBreadService, _messageModalService, sanitizer);
  }

  setInternalValue(v: any) {
    this.exists = !_.isNil(v); // Do this first so validator can see updated value of 'exists'.
    super.setInternalValue(v);
  }
  // photoURL() {
  //   return this.sanitizer.bypassSecurityTrustUrl(this..url);
  // }

  updateDisplay() {
    this._load = false;
    if (this._value) {
      this._imageSource = `data:${this._value.mimeType};base64,${this._value.data}`;
    } else {
      this._imageSource = null;
    }
    this._cd.markForCheck();
  }

  startBusy() {
    this._busy = true;
    this._cd.markForCheck();
  }

  endBusy() {
    this._busy = false;
    this._cd.markForCheck();
  }

  downloadImage(resourceName: string, id: number, nameOverride: string) {
    this.startBusy();
    this._load = false;
    let name = nameOverride || this.defaultImageName;
    this._imageSource = this._baseBreadService.getResourceDownloadUrl(resourceName, { id: id, name: name }, name) + `&_timestamp=${new Date().getTime()}` ;
    this._cd.markForCheck();
  }

  onImageLoad(event: Event) {
    this._load = true;
    this.clearError();
    this.endBusy();
    this._cd.markForCheck();
    this.exists = true;
  }

  onImageError(event: Event) {
    this._load = false;
    this.setError();
    this.endBusy();
    this._imageSource = null;
    this._cd.markForCheck();
    this.exists = false; // FOR NOW: If error, assume file doesn't exist.
  }

  clearError() {
    this._error = false;
    this._cd.markForCheck();
  }

  setError() {
    this._error = true;
    this._cd.markForCheck();
  }

  onClearClick() {
    this.clearError();
    super.onClearClick();
  }

  public validate(c: AbstractControl): any {
    if (!this.requiredIfDoesNotExist) return null;
    if (this.exists || this._value) return null;
    return { required: true };
  }
}

// TODO: Clean up, refactor
import { FieldErrorDirective } from './directives/field-error.directive';
import { FocusDirective } from './directives/focus.directive';
import { BusyDirective } from './directives/busy.directive';
import { ParenthesesPipe } from './pipes/parentheses.pipe';
import { DisabledIfDirective } from './directives/disabled-if.directive';
import { DisabledDirective } from './directives/disabled.directive';
import { DatePipe } from './pipes/date.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { OrderByPipe } from './pipes/orderby-pipes';
import { NamePipe } from './pipes/name.pipe';
import { FilterPipe } from './pipes/filter.pipe';

export const COMMON_DIRECTIVES = [
  FocusDirective,
  BusyDirective,
  FieldErrorDirective,
  DisabledIfDirective,
  DisabledDirective,
];

export const COMMON_PIPES = [
  ParenthesesPipe,
  DatePipe,
  SortPipe,
  OrderByPipe,
  DatePipe,
  NamePipe,
  FilterPipe
];

import { Component, Input, Output, OnChanges, ViewChild, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, forwardRef, Renderer2 } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { Dropdown2Component } from '../dropdown/dropdown2.component';

import * as _ from 'lodash';

export const AUTOCOMPLETE_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AutocompleteInput2Component),
    multi: true
};

@Component({
  selector: 'xsi-autocomplete-input',
  templateUrl: 'autocomplete-input2.html',
  styleUrls: ['../dropdown/dropdown2.component.scss', 'autocomplete-input2.component.scss'],
  providers: [AUTOCOMPLETE_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteInput2Component extends Dropdown2Component implements OnChanges {
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // BEGIN: Redeclaration of decorated Dropdown2Component members

  @ViewChild('dropdown', { static: false }) dropdown;
  @ViewChild('optionsList', { static: false }) optionsList;
  @ViewChild('dropdownContainer', { static: false }) dropdownContainer;
  @ViewChild('defaultTemplate', { static: false }) defaultTemplate;

  @Input() validTrigger: boolean;
  @Input() formControl;
  @Input() options: Array<any>;
  @Input() disabled: boolean;
  @Input() readonly: boolean;
  @Input() placeholder: string;
  @Input() displayText: any; // function or property name
  @Input() loop: boolean = true;
  @Input() required: boolean = false;
  @Input() clipOptions: boolean = false;
  @Input() styles;

  @Input() equalityCriteria: any = null;

  @Input() disableViewEditButton: boolean = true;

  @Output() optionSelect = new EventEmitter();

  @Output() escapePressed = new EventEmitter();

  // END: Redeclaration of decorated Dropdown2Component members
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  @Input() requireInput: boolean = false;
  @Input() fxnOptions = null; // function for fetching options
  @Output() inputTextChange = new EventEmitter();

  _isLoading: boolean;
  _isFocused: boolean;
  inputText: string = null; // input
  lastUserInputText: string = null; // for storing last user-specified (vs component-supplied, e.g., due to activation of option) input text

  updateOptionsDebounced;
  titleText = null;

  constructor(
    _renderer: Renderer2,
    _cd: ChangeDetectorRef) {
    super(_renderer, _cd);
    _.bindAll(this, 'setOptions');
    _.bindAll(this, 'clearInput');

    if (this.updateOptions) {
      this.updateOptionsDebounced = _.debounce(this.updateOptions, 200);
    }
  }

  ngOnInit() {
    // if (_.isUndefined(this.optionTemplate)) {
    //   this.optionTemplate = this.defaultTemplate;
    // }
  }

  ngOnChanges(changes): any {
    // super.ngOnChanges(changes);

    if (changes.formControl) {
      this.subscribeToFormControlStatusChanges();
    }
  }

  ngOnDestroy() {
    if (this._formControlStatusChangesSubscription) {
      this._formControlStatusChangesSubscription.unsubscribe();
    }
  }

  updateOptions(search, callback) {
    return this.fxnOptions(search, callback);
  }

  triggerAutocomplete(keyword = null) {
    this._isLoading = true;
    if (_.isNil(keyword)) keyword = this.inputText;
    if (keyword || !this.requireInput) {
      if (_.isFunction(this.updateOptionsDebounced)) {
        this.updateOptionsDebounced(keyword, (arg) => {
          if (!this._isFocused) return;
          this._isLoading = false;
          this.setOptions(arg);
          super.open();

          // quick fix for non-entity autocomplete
          setTimeout(() => {
            this.setDropdownInvert();
            this._cd.markForCheck();
          });
        });
      }
    }
  }

  // FIXME: REMOVE THIS QUICK FIX

  getDisplayText(o) {
    if (_.isUndefined(o) || _.isNull(o)) return null;
    if (this.displayText) {
      if (_.isFunction(this.displayText)) {
        return this.displayText(o);
      } else {
        return _.get(o, this.displayText);
      }
    } else {
      return o instanceof Object ? o['name'] : o;
    }
  }

  setInputText(text) {
    this.inputText = text;
    this.inputTextChange.emit(this.inputText);
    this._cd.markForCheck();
    setTimeout(() => { this.lastUserInputText = null; }, 200); // To compensate for the replacement of "setTimeout(() => { this.close(); }, 200);" with "this.close()" in dropdown's onBlur()
  }

  clearInput() {
    if (this.getValue() == null) {
      this.setInputText(null);
    }
    this.options = null;
    this._cd.markForCheck();
  }

  onInputTextChange() {
    this.lastUserInputText = this.inputText;
    this.inputTextChange.emit(this.inputText);
    this.setActiveIndex(null);
    this.setDropdownInvert();
    this.open();
  }

  syncModelToInputText() {
    if (this.dropdownActive) {
      this.setInputText(this.activeIndex != null ? this.options[this.activeIndex] : null);
    } else {
      this.setInputText(this.titleText = this.getValue() ? this.getDisplayText(this.getValue()) : null);
    }
  }

  //////////

  open(keyword = null) {
    this._isFocused = true;
    this.triggerAutocomplete(keyword);
  }

  close() {
    super.close();
    this._isLoading = false;
    this._isFocused = false;
    this.syncModelToInputText();
    setTimeout(() => {
      this.options = null;
      this._cd.markForCheck();
    }, 150);
  }

  setActiveIndex(index) {
    super.setActiveIndex(index);
    if (this.activeIndex != null) {
      this.setInputText(this.getDisplayText(this.options[this.activeIndex]));
    }
  }

  onClick(event) {
    // Don't remove this. Intentionally overridden function with empty body to disable base class default behavior
  }

  onKeydown(event) {
    let key = event.keyCode;

    if (this.dropdownActive) {
      switch (key) {
        case 13:
          this.selectOption(this.activeIndex);
          event.preventDefault();
          break;
        case 38:
        case 40:
          var step = { 38: -1, 40: +1 };
          this.moveActiveIndex(step[key], event);
          this.adjustScrollForSelected();
          return false; // break;
        case 27:
        // case 8:

          console.error('escaped');
          this.close();
          this.escapePressed.emit();
          break;
        default:
          break;
      }
    } else {
      switch (key) {
        case 13:
          event.preventDefault();
          break;
        case 40:
          this.open();
          break;
        case 8:
          this.selectOption(null);
          break;
        case 27:
          this.escapePressed.emit();
          break;
        default:
          break;
      }
    }
  }

  isAllowedOption(x) {
    return true;
  }

  writeValue(value: any) {
    // console.debug('AutocompleteInput2Component.writeValue', value);
    if (value !== this._value) {
      this._value = value;
      this.setInputText(this.titleText = this.getDisplayText(this.getValue()));
      this._cd.markForCheck();
    }
  }

  setValue(v: any) {
    // console.debug('AutocompleteInput2Component.setValue', v);
    if (v !== this._value) {
      this._value = v;
      this.onChangeCallback(this._value);
      this._cd.markForCheck();
      if (v === 'Show All') {
        this.setInputText(v);
        this.optionSelect.emit(v);
      } else {
        this.setInputText(this.titleText = this.getDisplayText(this._value));
      }
    }
  }
}

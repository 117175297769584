import { AbstractControl, FormControl } from '@angular/forms';
import * as _ from 'lodash';
// import { HrApplicantTrackingUtils } from '../../modules/hr-applicant-tracking/common/utils';

export class CustomValidators{
  static validateHex(c) {
    let error = null;
    let hexregex = /#\b[0-9A-F]{6}\b/gi;

    if (c.value['backgroundColor'] && !hexregex.test(c.value['backgroundColor'])) {
      error = { invalidHex: true };
    }

    c.controls['backgroundColor'].setErrors(error);

    return error;
  }


  static validateEmailFactory() {
    return (c: AbstractControl) => {
      let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)+$/i;
      let tempEmail = '';
      tempEmail = c.value === '' ? null : c.value;
      return !tempEmail ? null : (EMAIL_REGEXP.test(c.value) ? null : {
        validateEmail: true
      });
    };
  }

  static validateDateFactory() {
    return (c: AbstractControl) => {
      let DATE_REGEXP = /^\d{2}\/\d{2}\/\d{1,4}$/;
      let tempDate = '';

      tempDate = c.value === '' ? null : c.value;

      return !tempDate ? null : (DATE_REGEXP.test(c.value) ? null : {
        validateDate: true
      });
    };
  }

  static emptyValidator() {
    return (formControl: FormControl) => {
      return _.isEmpty(formControl.value) ? { required: true } : null;
    };
  }

  static createControlGroupRequiredValidator(requiredFields, ignoredFields) {

    function checkItemValidity(control, requiredFields, ignoredFields) {
      let error = { required: true };
      _.forEach(control.controls, (control) => {
        if (control.errors) {
          delete control.errors['required'];
        }
      });
      let hasValue = _.find(control.controls, (subcontrol, key) => { return !(_.isNil(subcontrol.value) || subcontrol.value === '') && !_.includes(ignoredFields, key); });
      if (hasValue) {
        let noValueArr = _.filter(control.controls, (subcontrol, key) => { return (_.isNil(subcontrol.value) || subcontrol.value === '') && _.includes(requiredFields, key); });
        if (noValueArr.length) {
          _.forEach(noValueArr, (control) => {
            control.setErrors(error);
          });
          return error;
        }
      }
      return null;
    }

    return _.partialRight(checkItemValidity, requiredFields, ignoredFields);
  }

  // static atLeastOneChoice(control) {
  //   let error = {atLeastOneChoice: true};

  //   if (control.controls['questionType'].value && control.controls['questionType'].value !== 'essay') {
  //     let clonedAnswerOptions = _.cloneDeep(control.controls['answerOptions'].value);
  //     HrApplicantTrackingUtils.removeEmptyEntry(clonedAnswerOptions);

  //     if (!clonedAnswerOptions.length) error = {atLeastOneChoice: true};
  //     else error = null;

  //     _.forEach(control.controls['answerOptions'].controls, (answerOption) => {
  //       answerOption.controls['answer'].setErrors(error);
  //       answerOption.controls['rank'].setErrors(error);
  //       answerOption.controls['numPoints'].setErrors(error);
  //     });
  //   } else {
  //     error = null;
  //   }

  //   return error;
  // }

  static evaluateControls(controls, requiredFields, overrideError = null) {
    let data = _.pick(controls, requiredFields);
    let error = { required: true };
    let hasValue = _.find(data, (subcontrol) => { return subcontrol.value; });

    if (hasValue) {
      let noValueArr = _.filter(controls, (subcontrol, key) => { return !subcontrol.value && _.includes(requiredFields, key); });

      if (noValueArr.length) {
        _.forEach(noValueArr, (control) => {
          control.setErrors(overrideError ? overrideError : error);
        });
        return overrideError ? overrideError : error;
      }
    } else {
      _.forEach(data, (control) => {
        control.setErrors(overrideError ? overrideError : null);
      });

      return overrideError ? overrideError : null;
    }
    return null;
  }

  static checkDependentsArrayValidity(arrayName, property) {
    return function(control) {
      let error = {required:  true};
      let questions = control.value.questions;

      if (questions) {
        let hasQuestion = false;

        questions.some((el) => {
          if (el.hrTestQuestion && el.hrTestQuestion.id) {
            hasQuestion = true;
            return true;
          }
        });

        if (hasQuestion) {
          error = null;
        }

        let questionsControls = control.controls[arrayName].controls;
        _.forEach(questionsControls, (qc) => {
          if (control.controls['name'].value && control.controls['rank']) {
            if (!qc.controls[property].value) {
              qc.controls[property].setErrors(error);
            } else {
              qc.controls[property].setErrors(null);
            }
          } else {
            qc.controls[property].setErrors(null);
          }
        });

        return CustomValidators.evaluateControls(control.controls, ['name', 'rank']);
      } else {
        return null;
      }
    }
  }

  static atLeastOneValidator(context, arrayName, controlProperty) {
    return function(c) {
      let error = {atLeastOneEmployee: true};
      let controlArrayValues = c.controls[arrayName].value;
      let hasEmployee = false;

      controlArrayValues.some((el) => {
        if (el.employee && el.employee.id) {
          hasEmployee = true;
          return true;
        }
      });

      if (hasEmployee) {
        error = null;
      }

      // setTimeout(() => {
        c.controls[arrayName].controls.forEach((el, ind, arr) => {
          el.controls[controlProperty].setErrors(error);

          if (el.controls[controlProperty].value && el.controls[controlProperty].value.id) {
            if (_.find(context._invalidEmployees, {employee: {id: el.controls[controlProperty].value.id}})) {
              el.controls[controlProperty].setErrors({[context.errorValue]: true});
              context.employeeTableFlagForValidation = true;
            } else {
              el.controls[controlProperty].setErrors(null);
            }
          }
        });
      // });

      return error;
    }
  }

  static createRequiredIfValidator(fieldName, closure: any) {
    return function(c) {
      let ret = closure(c);
      let error = null;
      if (ret) {
        let controlValue = (fieldName ? c.get(fieldName) : c).value;
        if (controlValue == null || controlValue === '') {
          error = { required: true };
        }
      }
      if (fieldName) {
        c.controls[fieldName].setErrors(error);
      }
      return error;
    }
  }

  static createCustomValidator(fieldName, closure: any, message: string) {
    return function(c) {
      let ret = closure(c);
      let error = null;
      if (!ret) {
        error = { error: message };
      }
      if (c) {
        if (fieldName) {
          c.controls[fieldName].setErrors(error);
        }
      }
      return error;
    }
  }
}

import { Component, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

import { Slug } from '../../commons/utils/slug';
import { LmsEnrollmentService } from '../../shared/services/api/lms/lms-enrollment.service';
import { AuthService } from '../../shared/services/auth.service';

import * as _ from 'lodash';

@Component({
  selector: 'app-curriculum',
  templateUrl: './curriculum.component.html',
  styleUrls: ['./curriculum.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CurriculumComponent implements OnInit, OnDestroy {

  config = [
    [
      {
        header: true,
        property: 'lmsCourse.name',
        class: 'bg-yellow'
      }
    ],
    [
      {
        label: 'Courses',
        onClick: (x) => { this.navigateTo(x, 'courses'); },
      },
      {
        label: 'Assessments',
        onClick: (x) => { this.navigateTo(x, 'assessments'); },
      }
    ],
    [
      {
        label: 'Progress',
        property: (x) => {
          if (x.numEnrollmentLessonsPassed === 0 || _.isNull(x.numEnrollmentLessonsPassed)) {
            return 0 + '%';
          } else if (x.numEnrollmentLessonsPassed > x.numEnrollmentLessons) {
            return '100%';
          } else {
            return Number(((x.numEnrollmentLessonsPassed || 0) / (x.numEnrollmentLessons || 0)) * 100).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '%';
          }
        }
      }
    ]
  ];

  model = [];
  loading = false;
  _subscriptions = [];
  selectedCurriculum = null;
  queryOptions = {};
  clonedModel: any;
  error = false;

  constructor(
    private _location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private _lmsEnrollmentService: LmsEnrollmentService,
    private authService: AuthService,
    protected _cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.queryOptions = {
      filter: {
        joinOp: 'and',
        conditions: [
          {
            property: 'lmsUser.id',
            op: 'eq',
            value: this.authService.getActiveLmsUser().object.id,
            dataType: 'long'
          },
          {
            op: 'isNull',
            property: 'lmsLesson'
          }
        ]
      },
      sort: [
        {
          property: 'passed',
          dir: 'asc'
        },
        {
          property: 'testCompleted',
          dir: 'asc'
        },
        {
          property: 'dateTestCompleted',
          dir: 'desc'
        },
        {
          property: 'completed',
          dir: 'asc'
        },
        {
          property: 'dateCompleted',
          dir: 'desc'
        },
        {
          property: 'started',
          dir: 'asc'
        },
        {
          property: 'dateStarted',
          dir: 'asc'
        },
        {
          property: 'id',
          dir: 'asc'
        },
      ]
    };

    this.loading = true;
    const s = this._lmsEnrollmentService.browse({}, this.queryOptions).subscribe((res: any) => {
      this.model = res.data.records;
      this.clonedModel = _.cloneDeep(res.data.records);
      this.loading = false;
      this._cd.markForCheck();
    }, (err) => {
      this.error = true;
      this.loading = false;
      this._cd.markForCheck();
    });

    this._subscriptions.push(s);
  }

  ngOnDestroy() {
    _.forEach(this._subscriptions, (s) => s.unsubscribe());
  }

  back() {
    this._location.back();
  }

  navigateTo(x, page) {
    if (page === 'assessments') {
      this.router.navigate([page, Slug.getSlug(x, 'lmsCourse.name', 'id', 'lmsCourse.id')], { relativeTo: this.route });
    } else {
      this.router.navigate([page, Slug.getSlug(x, 'lmsCourse.name', 'id', 'lmsCourse.id')], { relativeTo: this.route });
    }
  }

  optionSelected(event) {
    console.log(event);
    if (event === 'Show All') {
      this.model = this.clonedModel;
    } else {
      this.model = _.filter(this.clonedModel, (o) => o.lmsCourse.id === this.selectedCurriculum.lmsCourse.id);
    }
  }

}

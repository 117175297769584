import { Directive, ElementRef, Input, HostBinding, OnChanges, OnDestroy } from '@angular/core';

@Directive({
  selector: '[formControl][xsiDisabledIf]',
})
export class DisabledDirective implements OnChanges, OnDestroy {

  @Input('xsiDisabledIf') xsiDisabledIf: boolean;
  @Input('formControl') formControl: any = null;

  protected _formControlStatusChangesSubscription: any;

  ngOnChanges(changes) {
    if (changes.formControl) {
      this.updateFormControlDisabled();
      this.subscribeToFormControlStatusChanges();
    }
    if (changes.xsiDisabledIf) {
      this.updateFormControlDisabled();
    }
  }

  ngOnDestroy() {
    this.removeFormControlStatusChangeSubscription();
  }

  subscribeToFormControlStatusChanges() {
    this.removeFormControlStatusChangeSubscription();
    if (this.formControl) {
      this._formControlStatusChangesSubscription = this.formControl.statusChanges.subscribe((x: any) => {
        setTimeout(() => {
          this.updateFormControlDisabled();
        });
      });
    }
  }

  removeFormControlStatusChangeSubscription() {
    if (this._formControlStatusChangesSubscription) {
      this._formControlStatusChangesSubscription.unsubscribe();
    }
  }

  updateFormControlDisabled() {
    if ((!!this.xsiDisabledIf) !== (!!this.formControl.disabled)) {
      if (this.xsiDisabledIf) {
        this.formControl.disable();
      } else {
        this.formControl.enable();
      }
    }
  }

}

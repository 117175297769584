import * as _ from 'lodash';

export class Slug {
  static getSlug(model, property = null, idProperty = null, idProperty2 = null) {
    const name = !property ? _.defaultTo(_.get(model, 'displayName'), _.get(model, 'name')) : _.get(model, property);
    let cleanString = name ? name.toString().toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '')             // Trim - from start of text
      .replace(/-+$/, '') :           // Trim - from end of text
    '';

    return `${cleanString}-${!idProperty ? model.id : _.get(model, idProperty)}-${!idProperty2 ? model.id : _.get(model, idProperty2)}`;
  }

  static getIDFromSlug(slug: string) {
    return slug ? Number(slug.split('-').pop()) : undefined;
  }
}

import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Location } from '@angular/common';
import { CourseComponent } from '../course/course.component';
import * as _ from 'lodash';

import { Slug } from '../../commons/utils/slug';
import { Router } from '@angular/router';

import { LmsEnrollmentLessonService } from '../../shared/services/api/lms/lms-enrollment-lesson.service';

@Component({
  selector: 'app-course-list',
  templateUrl: './course-list.component.html',
  styleUrls: ['./course-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CourseListComponent implements OnInit, OnDestroy {
  @ViewChild('modal', { static: true }) modal;

  config = [
    [
      {
        header: true,
        property: 'lmsLesson.name',
        onClick: (x) => { this.openModal(x); },
      },
    ],
    [
      {
        label: 'Content: ',
        property: (x) => {
          if (x.completed) {
            return 'Completed';
          } else if (x.started) {
            return 'Started';
          } else {
            return 'Pending';
          }
        },
        leftAlign: true,
      },
      {
        label: 'Assessment: ',
        property: (x) => {
          if (x.passed) {
            return 'Passed';
          } else {
            return 'Pending';
          }
        },
        leftAlign: true,
      }
    ],
    [
      {
        label: 'Course: ',
        property: (x) => {
          if (x.passed) {
            return 'Passed';
          } else if (x.testCompleted) {
            return 'Test Taken';
          } else if (x.completed) {
            return 'Content completed';
          } else if (x.started) {
            return 'Content Started';
          } else {
            return 'Pending';
          }
        },
        leftAlign: true,
      }
    ]
  ];

  model = [];
  title = '';
  loading = false;
  error = false;
  _subscriptions = [];
  queryOptions = {};

  constructor(
    private _location: Location,
    private router: Router,
    private lmsEnrollmentLessonService: LmsEnrollmentLessonService,
    private _cd: ChangeDetectorRef,
  )
  {
    let url = router.url.split('/')[4];
    // let id = Number(url.split('-').pop());

    let ids = url.split('-').slice(-2);

    let enrollmentId = ids[0];
    let courseId = ids[1];

    // this.title = _.startCase(url.slice(0, url.lastIndexOf('-')).replace(/-/g, " "));

    this.queryOptions = {
      filter: {
        joinOp: 'and',
        conditions: [
          {
            property: 'lmsEnrollment.id',
            value: enrollmentId,
            op: 'eq',
            dataType: 'long'
          },
        ]
      },
      sort: [
        {
          property: 'passed',
          dir: 'asc'
        },
        {
          property: 'testCompleted',
          dir: 'asc'
        },
        {
          property: 'dateTestCompleted',
          dir: 'desc'
        },
        {
          property: 'completed',
          dir: 'asc'
        },
        {
          property: 'dateCompleted',
          dir: 'desc'
        },
        {
          property: 'started',
          dir: 'asc'
        },
        {
          property: 'dateStarted',
          dir: 'asc'
        },
        {
          property: 'id',
          dir: 'asc'
        },
      ]
    };
    this.loading = true;
    let s = this.lmsEnrollmentLessonService.browse({}, this.queryOptions).subscribe((res: any) => {
      this.model = res.data.records;
      if (this.model) {
        this.title = _.get(this.model[0], 'lmsCourse.name');
      }
      this.loading = false;
      this._cd.markForCheck();
    }, (err) => {
      this.error = true;
      this.loading = false;
      this._cd.markForCheck();
    });
    this._subscriptions.push(s);

  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    _.forEach(this._subscriptions, (s) => s.unsubscribe());
  }

  back() {
    this._location.back();
  }

  openModal(course) {
    this.modal.title = _.get(course, 'lmsLesson.name');
    this.modal.createContentComponent(CourseComponent);
    this.modal.initialize().then((res) => {
      this.modal.getEventEmitter().subscribe((res) => {
        if (res.name === 'open') {
          this.modal._contentComponent.instance.init(course.id);
        } else if (res.name === 'close') {
          this.loading = true;
          let s = this.lmsEnrollmentLessonService.browse({}, this.queryOptions).subscribe((res: any) => {
            this.model = res.data.records;
            this.title = this.model[0].lmsCourse.name;
            this.loading = false;
            this._cd.markForCheck();
          }, (err) => {
            this.error = true;
            this.loading = false;
            this._cd.markForCheck();
          });
          this._subscriptions.push(s);
        }
      });
      this.modal.open();
    });
  }
}

import { ApiService } from './api.service';
import { BaseBreadService, BreadServiceDecorator } from './base-bread.service';
import { RequestMethod } from './enums/request-methods';
import { Injectable } from '@angular/core';

@BreadServiceDecorator({
  resourceName: "secUser"
})

@Injectable()
export class SecUserService extends BaseBreadService {
  constructor(public apiService: ApiService) {
    super(null, null, apiService);
  }

  changePassword(object: any) {
    return this.apiService.requestToJsonApi(RequestMethod.POST, this.getRequestUrl('changePassword', null), null, object);
  }

  generateNewPassword(object: any) {
    return this.apiService.requestToJsonApi(RequestMethod.POST, this.getRequestUrl('generateNewPassword', null), null, object);
  }
}

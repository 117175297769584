import { ChangeDetectorRef, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

export const BASE_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => BaseControlValueAccessor),
    multi: true
};

export class BaseControlValueAccessor implements ControlValueAccessor {
  protected onTouchedCallback: () => void = () => {};
  protected onChangeCallback: (_: any) => void = () => {};
  protected _value: any;
  protected _disabled: boolean;

  constructor(protected _cd: ChangeDetectorRef) {
  }

  // ControlValueAccessor interface function
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // ControlValueAccessor interface function
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  // ControlValueAccessor interface function
  writeValue(value: any) {
    if (value !== this._value) {
      this._value = value;
    }
  }

  // ControlValueAccessor interface function
  setDisabledState(isDisabled: boolean) {
    this._disabled = isDisabled;
    this._cd.markForCheck();
  }

  getValue() {
    return this._value;
  }

  setValue(v: any) {
    if (v !== this._value) {
      this._value = v;
      this.onChangeCallback(this._value);
      this._cd.markForCheck();
    }
  }
}

import { ApiService } from '../../api.service';
import { RequestMethod } from '../../enums/request-methods';
import { BreadServiceDecorator } from '../../base-bread.service';
import { BaseBreadWithApprovalService } from '../../base-bread-with-approval.service';
import { Injectable } from '@angular/core';

@BreadServiceDecorator({
  resourceName: 'lmsEnrollment'
})

@Injectable()
export class LmsEnrollmentService extends BaseBreadWithApprovalService {
  constructor(public apiService: ApiService) {
    super(apiService);
  }

  acceptAgreement(requestData) {
    return this.apiService.requestToJsonApi(RequestMethod.POST, this.getRequestUrl('acceptAgreement', 'lmsEnrollment'), {}, requestData);
  }

  checkPrerequisites(id: number) {
    return this.apiService.requestToJsonApi(RequestMethod.POST, this.getRequestUrl('checkPrerequisites', 'lmsEnrollment'), {}, { properties: { id } });
  }
}

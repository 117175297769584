import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { MessageModalService } from '../../../commons/message-modal/message-modal';
import { TestService } from '../../../shared/services/api/test/test.service';
import { TestPartQuestionService } from '../../../shared/services/api/test/test-part-question.service';
import { TestModel } from '../../../shared/models/test/test.model';
import { TestAnswerOption } from '../../../shared/models/test/test-answer-options.model';

import * as moment from 'moment';
import * as _ from 'lodash';
import { TestTemplateService } from '../../../shared/services/api';

interface QuestionAndAnswer {
  answers: TestAnswerOption[];
  id: number
}

@Component({
  selector: 'app-assessment-test',
  templateUrl: './assessment-test.component.html',
  styleUrls: ['./assessment-test.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssessmentTestComponent implements OnInit {

  testId = null;
  private _subscription = null;
  private _thankYouMessage = null;
  private _testSent = false;
  private _accessCode = null;
  private _matchedAccessCode = true;
  private _test = null;
  private _isSubmitting = false;
  private DEFAULT_THANK_YOU_MESSAGE = 'Thank you for taking the exam';
  private dateTaken: string = null;
  loading = false;

  constructor(
    protected _testService: TestService,
    protected _testTemplateService: TestTemplateService,
    protected _testPartQuestionService: TestPartQuestionService,
    protected _modalService: MessageModalService,
    protected _cd: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
  }

  init(test) {
    this.loading = true;
    this.dateTaken = moment().format('YYYY-MM-DD HH:mm:ss.0');
    this.testId = test.id;
    this.checkAccessCode();
  }

  checkAccessCode() {
    // if (!this._accessCode) {
    //   this._modalService.alert('Invalid access code');
    // } else {

      if (!this._test) {
        const obs = [];
        this._testService.read(this.testId).subscribe((res) => {
          this._test = res.data;
          const testTemplate = this._test.testTemplate;
          const numTakes = this._test.numTakes ? this._test.numTakes : 0; // if test numTakes is null, behave as 0

          const checkInterval = () => {
            const seconds = moment().diff(moment(this._test.dateTaken), 'seconds');
            const mins = Math.floor(seconds / 60);
            if (testTemplate.minRetakeIntervalMinutes > 0) {
              if (!(mins >= testTemplate.minRetakeIntervalMinutes)) {
                const dateTime = moment(this._test.dateTaken).add(testTemplate.minRetakeIntervalMinutes, 'minutes').format('MM/DD/YYYY HH:MM:SS');

                this._test = null;
                this.testId = null;
                const verb = numTakes > 0 ? 'retake' : 'take'; // If data is not manipulated by the user, numTakes will always be > 0 when this part of the code is reached. Nevertheless, don't assume numTakes > 0.
                const waitMinutes = testTemplate.minRetakeIntervalMinutes - mins;
                const hours = Math.floor(waitMinutes / 60);
                const minutes = waitMinutes % 60;
                const timeStrArr = [];
                if (hours) {
                  timeStrArr.push(hours + ' ' + (hours == 1 ? 'hour' : 'hours'));
                }
                if (minutes || !hours) {
                  timeStrArr.push(minutes + ' ' + (minutes == 1 ? 'minute' : 'minutes'));
                }
                this._modalService.alert('You cannot ' + verb + ' this assessment now. Please wait for at least ' + timeStrArr.join(' ') + ' for your next attempt.');
                this._cd.markForCheck();
              } else {
                this.testAccessCode();
              }
            } else {
              this.testAccessCode();
            }
          };

          // if template maxNumTakes is null, don't do numTakes check
          if (!_.isNull(testTemplate.maxNumTakes)) {

            // numTakes checker
            if (numTakes < testTemplate.maxNumTakes) {
              // if test dateTaken or testTemplate minRetakeIntervalMinutes is null, don't do interval check
              if (_.isNull(this._test.dateTaken) || _.isNull(testTemplate.minRetakeIntervalMinutes)) {
                this.testAccessCode();
              } else {
                checkInterval();
              }
            } else {
              this._test = null;
              this.testId = null;
              this._modalService.alert('You can no longer take this test.');
            }
          } else {
            // if test dateTaken or testTemplate minRetakeIntervalMinutes is null, don't do interval check
            if (_.isNull(this._test.dateTaken) || _.isNull(testTemplate.minRetakeIntervalMinutes)) {
              this.testAccessCode();
            } else {
              checkInterval();
            }
          }
          this.loading = false;
          this._cd.markForCheck();
        }, (err) => {
          this._modalService.alert('The server threw an error');
          this.loading = false;
          this._cd.markForCheck();
        });
      } else {
        this.testAccessCode();
        this.loading = false;
        this._cd.markForCheck();
      }
    // }
  }

  testAccessCode() {
    if (this._test) {
      if ((this._test.accessCode || null) === (this._accessCode || null)) {
        this._matchedAccessCode = true;
        console.log('match');
      } else {
        this._matchedAccessCode = false;
        if (this._accessCode) {
          this._modalService.alert('Invalid access code');
        }
      }
    }
  }

  onSubmitClick(event: { questionAndAnswer: QuestionAndAnswer[], testModel: TestModel, thankYouMessage: string }) {
    const { questionAndAnswer, testModel, thankYouMessage } = event;

    this._isSubmitting = true;
    this._cd.markForCheck();
    this._testService
      .submitAnswers(this.formatQuestionRequest(questionAndAnswer, testModel))
      .subscribe(
        (res) => {
          this.testComplete(thankYouMessage);
          this._isSubmitting = false;
          this._cd.markForCheck();
        },
        (err) => {
          this._isSubmitting = false;
          this._modalService.alert('The server threw an error');
          this._cd.markForCheck();
        },
      );
  }

  testComplete(tyMesage: string) {
    this._testSent = true;
    this._thankYouMessage = (tyMesage || this.DEFAULT_THANK_YOU_MESSAGE);
  }

  formatQuestionRequest(questionAndAnswer: QuestionAndAnswer[], testModel: TestModel) {
    const questionWithAnswers = _.map(questionAndAnswer, (el) => {
      el.answers = this.formatAnswers(el.answers);
      return el;
    });

    testModel.parts.forEach((part) => {
      part.questions.forEach((question: any) => {
        const questionHasAnswer = _.find(questionWithAnswers, questionWithAnswer => questionWithAnswer.id === question.id);
        if (questionHasAnswer) { question.answers = questionHasAnswer.answers; }
      });
    });

    testModel.dateTaken = this.dateTaken;
    return testModel;
  }

  formatAnswers(answers: TestAnswerOption[]) {
    return _.map(answers, (ans, index) => {
      if (_.isObject(ans)) {
        delete ans.id;
      } else {
        ans = {
          answer: ans,
          rank: index + 1,
          numPoints: 0
        };
      }

      return ans;
    });
  }

}

import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { ImageInputComponent } from './image-input.component';

import { BaseBreadService } from '../../shared/services/base-bread.service';
import { MessageModalService } from '../message-modal/message-modal';
import { DomSanitizer } from '@angular/platform-browser';

export const PROFILE_IMAGE_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ProfileImageInputComponent),
  multi: true
};

@Component({
  selector: 'xsi-profile-image-input',
  templateUrl: 'profile-image-input.html',
  providers: [PROFILE_IMAGE_INPUT_CONTROL_VALUE_ACCESSOR],
  styleUrls: ['image-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileImageInputComponent extends ImageInputComponent {
  @Input() maxSizeBytes: number = 26214400; // 25mb
  @Input() imageWidth: number = null;
  @Input() imageHeight: number = null;
  @Input() disabled: boolean;
  @Input() title: string = 'Profile Image';
  @Input() objectFit: string = 'contain'; // https://developer.mozilla.org/en-US/docs/Web/CSS/object-fit
  @Input() placeholder: string = 'assets/img/placeholder.png';

  constructor(_cd: ChangeDetectorRef, _baseBreadService: BaseBreadService, _messageModalService: MessageModalService, sanitizer: DomSanitizer, protected http: HttpClient) {
    super(_cd, _baseBreadService, _messageModalService, sanitizer);
  }

  downloadImage(resourceName: string, id: number, name: string = this.defaultImageName) {
    this.startBusy();
    this.clearError();
    this._load = false;
    this._imageSource = null;
    const imageSource = this._baseBreadService.getResourceDownloadUrl(resourceName, { id: id, name: name }, name) + `&_timestamp=${new Date().getTime()}` ;
    this.http.get(imageSource).subscribe(
      (response) => {
        this.endBusy();
        this._load = true;
        this._imageSource = imageSource;
      },
      (error) => {
        this.setError();
        this.endBusy();
        this._imageSource = imageSource;
        this.exists = error.status !== 404;
      }
    );
    this._cd.markForCheck();
  }
}

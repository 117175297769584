import { TimeType, Gender, MaritalStatus, HighSchoolEducationalAttainment, EmploymentStatus, TrainingLocation } from './definitions';

export const timeTypes = ['Hour', 'Minute'];
export const genders = [null, 'male', 'female'];
export const maritalStatuses = ['single', 'married', 'widower_widow', 'separated'];
export const highestEducationalAttainments = ['primary', 'secondary', 'vocational', 'undergraduate', 'graduate'];
export const employmentStatus = ['unemployed w/o experience', 'unemployed w/ experience', 'employed'];
export const trainingOptions = ['San Pedro, Laguna', 'CDO'];

// always assumes that the object passed has lastName, firstName and middleName properties
export const displayName = (nameObj: { lastName: string, firstName: string, middleName: string }) => {
  const lastName = nameObj.lastName || '';
  const firstName = nameObj.firstName || '';
  const middleName = nameObj.middleName || '';

  if ((lastName === '' && firstName === '' && middleName === '')) return null;

  return lastName ? `${lastName}, ${firstName} ${middleName}` : `${firstName}`;
}

export const timeTypeDisplayText = (o: TimeType) => {
  if (o === 'Hour') return 'Hour';
  if (o === 'Minute') return 'Minute';

  return null;
}

export const genderDisplayText = (o: Gender) => {
  if (o === 'male') return 'Male';
  if (o === 'female') return 'Female';

  return null;
}

export const maritalStatusDisplayText = (o: MaritalStatus) => {
  if (o === 'single') return 'Single';
  if (o === 'married') return 'Married';
  if (o === 'widower_widow') return 'Widower/Widow';
  if (o === 'separated') return 'Separated';

  return null;
}

export const highestEducationalAttainmentDisplayText = (o: HighSchoolEducationalAttainment) => {
  if (o === 'primary') return 'Primary';
  if (o === 'secondary') return 'Secondary';
  if (o === 'vocational') return 'Vocational';
  if (o === 'undergraduate') return 'Undergraduate';
  if (o === 'graduate') return 'Graduate';

  return null;
}

export const enrollmentLessonDisplayText = (o) => {
  let displayText = `[${o.id}]`;

  if (o.lmsCourse) {
    displayText += ` ${o.lmsCourse.name}`;
    if (o.lmsLesson) displayText += ` - ${o.lmsLesson.name}`;
  }

  return displayText;
}

export const employmentStatusDisplayText = (o: EmploymentStatus) => {
  if (o === 'unemployed w/o experience') return 'Unemployed w/o Experience';
  if (o === 'unemployed w/ experience') return 'Unemployed w/ Experience';
  if (o === 'employed') return 'Employed';

  return null;
}

export const trainingDisplayText = (o: TrainingLocation) => {
  if (o === 'San Pedro, Laguna') return 'San Pedro, Laguna';
  if (o === 'CDO') return 'CDO';
}

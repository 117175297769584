import { Component, EventEmitter, Output, AfterViewInit, Input, OnChanges, OnDestroy, ViewContainerRef, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { NoopControlValueAccessor, NOOP_CONTROL_VALUE_ACCESSOR } from '../../shared/utils/noop-control-value-accessor';

import * as moment from 'moment';
import * as Pikaday from 'pikaday';

@Component({
  selector: 'xsi-datepicker',
  templateUrl: 'test-datepicker.component.html',
  styleUrls: ['test-datepicker.component.scss'],
  providers: [NOOP_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class DatePickerComponent extends NoopControlValueAccessor implements AfterViewInit, OnChanges, OnDestroy{
  private picker: any = null;
  private viewFormat = 'MM/DD/YYYY';
  private el: any;
  // private _calendarContainerId: string;
  // private _fieldId: string;
  // private _buttonTriggerId: string;

  @Input() model: any;
  @Input() formControl: any = new FormControl();
  @Input() invertHorizontal: boolean = false;
  @Input() disabledField = false;
  @Input() borderOverride: any;
  @Input() maxYear = 9999;
  @Input() minYear = 0;
  @Input() yearRange = 10;

  @Input() mondayFirst: boolean = false;
  @Input() singleDayName: boolean = false;

  @Output() enterKeyAndValid: EventEmitter<any> = new EventEmitter();

  @ViewChild('input') inputField: any;
  @ViewChild('container') container: any;
  @ViewChild('trigger') trigger: any;
  @ViewChild('datepickerContainer') datepickerContainer: any;

  setDatepickerContainerTop;
  datepickerContainerTop: number = 0;
  datepickerStyles = {
    margin: 0,
    top: 0,
  };

  constructor(viewContainer: ViewContainerRef, private _cd: ChangeDetectorRef) {
    super();
    // let randLetter = String.fromCharCode(65 + Math.floor(Math.random() * 26));
    // let uniqid = randLetter + Date.now();
    this.el = viewContainer.element.nativeElement;
    // this._calendarContainerId = 'container' + uniqid;
    // this._fieldId = 'field' + uniqid;
    // this._buttonTriggerId = 'trigger' + uniqid;
    this.initMouseEvents();


    this.setDatepickerContainerTop = () => {
      if (!this.picker) return;
      if (this.picker.isVisible()) {
        this.datepickerStyles.top = this.datepickerContainer.nativeElement.getBoundingClientRect().top;
        this._cd.markForCheck();
      }
    };
  }

  @Output() modelChange = new EventEmitter();

  // CONFIG FOR TIME

  // showTime: false,
  // showSeconds: true,
  // use24hour: true,
  // incrementHourBy: 1,
  // incrementMinuteBy: 1,
  // incrementSecondBy: 1,
  // autoClose: true,
  // timeLabel: null

  ngAfterViewInit() {
    this.picker = new Pikaday({
      i18n: {
        months        : ['January','February','March','April','May','June','July','August','September','October','November','December'],
        weekdays      : ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
        weekdaysShort : this.singleDayName ? ['S','M','T','W','T','F','S'] : ['Sun','Mon','Tue','Wed','Thu','Fri','Sat']
      },
      firstDay: Number(this.mondayFirst),
      field: this.inputField.nativeElement,
      container: this.container.nativeElement,
      format: this.viewFormat,
      maxYear: this.maxYear,
      minYear: this.minYear,
      yearRange: this.yearRange,
      onSelect: (date) => {
        this.modelChange.emit(moment(date).format('YYYY-MM-DD 00:00:00.00'));
        this.formControl.markAsDirty();
        this.formControl.setValue(moment(date).format(this.viewFormat));
        this.formControl.updateValueAndValidity();
        this.close();
      },
      onOpen: () => {
        this.onOpenDatepicker();
        this.setDatepickerPosition();
      },
      showTime: false
    });

    this.picker.setDate(this.model, true);
    this.picker.hide();

    // BEGIN: DELETE ME
    // this.picker = {
    //   show: () => {},
    //   hide: () => {},
    //   destroy: () => {},
    //   setDate: () => {},
    // }
    // END: DELETE ME

    // this.formControl.validator = Validators.compose([this.formControl.validator, this.dateValidator()]);

    setTimeout(() => {
      this.datepickerStyles.top = this.inputField.nativeElement.getBoundingClientRect().top;
      this._cd.markForCheck();
    }, 0);
  }

  toggleScrollEventListener(add: boolean) {
    if (add) {
      window.addEventListener('scroll', this.setDatepickerContainerTop, true);
    } else {
      window.removeEventListener('scroll', this.setDatepickerContainerTop , true);
    }
  }

  isLastDay(date) {
    return new Date(date.getTime() + 86400000).getDate() === 1;
  }

  onFieldBlur() {
    this.close();
  }

  onOpenDatepicker() {
    if (!this.picker) return;
    if (this.formControl.value === '') {
      this.modelChange.emit('');
      this.picker.gotoToday();
    }
  }

  ngOnChanges(changes) {
    // setTimeout(() => {
      if (changes.model) {
        this.setValue(changes.model.currentValue);
      }else if (changes.formControl) {
        this.setValue(changes.formControl.currentValue.value);
      }
    // }, 0);

    if (changes.formControl) {
      this.subscribeToFormControlStatusChanges();
    }
  }

  setValue(val) {
    if (!this.picker) return;

    this.picker.setDate(val, true);
    if (val) {
      this.formControl.setValue(moment(val).format(this.viewFormat));
      this._cd.markForCheck();
    }
  }

  close() {
    // console.error('MODEL ', this.model);
    // console.error('picker ', this.picker.getDate());
    // console.error(moment(this.picker.getDate()).format('YYYY-MM-DD 00:00:00.00'));

    this.picker.hide();
    // console.error('emitting onClose');
    // this.modelChange.emit(moment(this.picker.getDate()).format('YYYY-MM-DD 00:00:00.00'));
    this.toggleScrollEventListener(false);
    this._isOpen = false;
  }

  private _isOpen = false;

  toggleDatePicker() {
    if (this._isOpen) {
      this.close();
    }else{
      this.picker.show();
      this._isOpen = true;
    }
  }

  ngOnDestroy() {
    if (this.picker) this.picker.destroy();
    window.removeEventListener('scroll', this.setDatepickerContainerTop , true);

    if (this._formControlStatusChangesSubscription) {
      this._formControlStatusChangesSubscription.unsubscribe();
    }
  }

  private initMouseEvents(): void {
    let body = document.getElementsByTagName('body')[0];

    body.addEventListener('click', (e) => {
      if (!this._isOpen || !e.target) return;
      if (this.el !== e.target && !this.el.contains(e.target)) {
        this.close();
      }
    }, false);
  }

  onKeyup(event) {
    if (event.which === 9 || (event.which >= 16 && event.which <= 18) || (event.which >= 37 && event.which <= 40)) return;

    if (this.formControl.value === '') {
      this.modelChange.emit(this.formControl.value);
    }

    if (event.which == 13) {
      console.log(this.model, event);
      this.modelChange.emit(this.model);
      this.enterKeyAndValid.emit(this.model);
      this.close();
    }

    this.formControl.validator = Validators.compose([this.formControl.validator, this.dateValidator()]);
    if (event.which === 8 && event.target.selectionStart === 0) return;
    this.formControl.updateValueAndValidity();
  }

  buttonClick(event: MouseEvent) {
    this.picker.show();
    this.setDatepickerPosition();
  }

  setDatepickerPosition() {
    this.toggleScrollEventListener(true);
    this.setDatepickerContainerTop();
    this.setDatepickerInvert();
  }

  setDatepickerInvert() {
    let marginBottom = Number(window.getComputedStyle(this.container.nativeElement).marginBottom.slice(0, -2));
    let datepickerBottom = this.datepickerContainer.nativeElement.getBoundingClientRect().bottom + this.container.nativeElement.offsetHeight + marginBottom;
    this.datepickerStyles.margin = datepickerBottom < window.innerHeight ?
                                  this.datepickerContainer.nativeElement.offsetHeight + marginBottom :
                                  -(this.container.nativeElement.offsetHeight + marginBottom);
  }

  dateValidator() {
    let externalContext = this;

    return function(c: FormControl) {
      // let DATE_REGEXP = /^\d{2}\/\d{2}\/\d{1,4}$/;
      // let DATE_REGEXP = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/\d{4}$/;
      // let tempDate = '';

      // let lastDayPassed = false;

      // tempDate = c.value === '' ? null : c.value;

      // if (DATE_REGEXP.test(moment(c.value).format('MM/DD/YYYY'))) {
      //   let formattedDate = moment(c.value).format('MM/DD/YYYY');
      //   let splittedDate = formattedDate.split('/');
      //   let daysInMonth = moment(splittedDate[0] + '/' + splittedDate[2], 'MM/YYYY').daysInMonth();

      //   if (daysInMonth >= parseInt(splittedDate[1])) lastDayPassed = true;
      // }

      // return !tempDate ? null : (DATE_REGEXP.test(moment(c.value).format('MM/DD/YYYY')) ? (lastDayPassed ? null : { invalidDate: true }) : {
      //   invalidDate: true
      // });

      let DATE_REGEXP = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/\d{4}$/;
      let tempDate = '';

      let lastDayPassed = false;

      tempDate = c.value === '' ? null : c.value;

      if (DATE_REGEXP.test(c.value)) {
        let splittedDate = c.value.split('/');
        let daysInMonth = moment(splittedDate[0] + '/' + splittedDate[2], 'MM/YYYY').daysInMonth();

        if (daysInMonth >= parseInt(splittedDate[1])) lastDayPassed = true;
      }

      return !tempDate ? null : (DATE_REGEXP.test(c.value) ? (lastDayPassed ? null : { invalidDate: true }) : {
        invalidDate: true
      });
    }
  }

  protected _formControlStatusChangesSubscription: any;
  protected _lastFormControlStatusValue: any;

  subscribeToFormControlStatusChanges() {
    if (this._formControlStatusChangesSubscription) {
      this._formControlStatusChangesSubscription.unsubscribe();
      this._lastFormControlStatusValue = null;
    }
    if (this.formControl) {
      this._formControlStatusChangesSubscription = this.formControl.statusChanges.subscribe((x: any) => {
        if (this._lastFormControlStatusValue != x) {
          this._cd.markForCheck();
          //console.debug('new status value %s -> %s', this._lastFormControlStatusValue, x);
        }
        this._lastFormControlStatusValue = x;
      });
    }
  }
}

//////////////////////////////////////////////////

import { Component, OnInit, Input, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-list-table',
  templateUrl: './list-table.component.html',
  styleUrls: ['./list-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListTableComponent implements OnInit {

  @Input() config;
  @Input() model;

  constructor(protected _cd: ChangeDetectorRef,) { }

  ngOnInit(): void {
  }

  isConfigArray() {
    return _.isArray(this.config);
  }

  getItemValue(item, property) {

    if (_.isFunction(property)) {
      return property(item);
    }

    return _.get(item, property);
  }

  onImageLoad(item) {
    item._load = true;
    item._busy = false;
    item.error = false;
    item.exists = true;
    this._cd.markForCheck();
  }

  onImageError(item) {
    console.log('error', item);
    item._busy = false;
    item._load = false;
    item.error = true;
    item._imageSource = null;
    this._cd.markForCheck();
  }

}

import {Directive, ElementRef, Input, OnChanges} from '@angular/core';
// import { ErrorMsgsService } from '../services/error-msgs.service';

import * as _ from 'lodash';

@Directive({
  selector: '[xsiFieldError]'
})
export class FieldErrorDirective implements OnChanges {
  private el: HTMLElement;
  private tempValue = '';

  @Input('xsiFieldError') fieldError: any;
  @Input('formControl') formControl: any;

  protected _formControlStatusChangesSubscription: any;

  constructor(el: ElementRef) {
    this.el = el.nativeElement;
  }

  ngOnChanges(changes) {
    if (changes.formControl) {
      this.updateErrorMessage();
      this.subscribeToFormControlStatusChanges();
    }
    if (changes.fieldError) {
      this.updateErrorMessage();
    }
  }

  ngOnDestroy() {
    this.removeFormControlStatusChangeSubscription();
  }

  subscribeToFormControlStatusChanges() {
    this.removeFormControlStatusChangeSubscription();
    if (this.formControl) {
      this._formControlStatusChangesSubscription = this.formControl.statusChanges.subscribe(
        (x: any) => {
          setTimeout(() => { this.updateErrorMessage(); });
        }
      );
    }
  }

  removeFormControlStatusChangeSubscription() {
    if (this._formControlStatusChangesSubscription) {
      this._formControlStatusChangesSubscription.unsubscribe();
    }
  }

  updateErrorMessage() {
    if (!this.formControl || this.formControl.enabled) {
      this.el.title = this.getErrorMessage() || '';
    } else {
      this.el.title = '';
    }
  }

  getErrorMessage() {
    if (this.fieldError) {
      return this.fieldError;
    } else if (this.formControl && !this.formControl.valid) {
      if (this.formControl.errors) {
        let errors: Array<string> = [];
        Object.keys(this.formControl.errors).forEach((x) => {
          let errVal = this.formControl.errors[x];
          // let error = _.isString(errVal) ? errVal : ErrorMsgsService.getErrorMsg(x);
          // if (error) {
          //   errors.push(error);
          // }
        });
        // TODO: Remove 'Error: ' prefix (added temporarily so we can easily identify controls on which this logic took effect).
        return 'Error: ' + errors.join(', ');
      } else {
        return 'Unknown Error';
      }
    }
    return null;
  }
}

// import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
// import { ErrorMsgsService } from '../services/error-msgs.service';

// import { MessageTooltipDirective } from './tooltip/message-tooltip.directive';

// @Directive({
//   selector: '[xsiFieldError]'
// })
// export class FieldErrorDirective extends MessageTooltipDirective implements OnChanges {
//   @Input('xsiFieldError') xsiTooltipFieldError: any;

//   protected _formControlStatusChangesSubscription: any;

//   constructor(el: ElementRef) {
//     super(el);
//   }

//   ngOnChanges(changes: SimpleChanges) {
//     if (changes.formControl) {
//       this.setTooltipMessage();
//       this.subscribeToFormControlStatusChanges();
//     }
//     if (changes.xsiTooltipFieldError) {
//       this.setTooltipMessage();
//     }
//   }

//   ngOnDestroy() {
//     this.removeFormControlStatusChangeSubscription();
//   }

//   subscribeToFormControlStatusChanges() {
//     this.removeFormControlStatusChangeSubscription();
//     if (this.formControl) {
//       this._formControlStatusChangesSubscription = this.formControl.statusChanges.subscribe(
//         (x: any) => {
//           setTimeout(() => { this.setTooltipMessage(); });
//         }
//       );
//     }
//   }

//   removeFormControlStatusChangeSubscription() {
//     if (this._formControlStatusChangesSubscription) {
//       this._formControlStatusChangesSubscription.unsubscribe();
//     }
//   }

//   setTooltipMessage() {
//     this.tooltipMessage = (!this.formControl || this.formControl.enabled)
//       ? this.getErrorMessage() || ''
//       : '';
//   }

//   getErrorMessage() {
//     if (this.xsiTooltipFieldError) return this.xsiTooltipFieldError;

//     if (this.formControl && !this.formControl.valid) {
//       if (!this.formControl.errors) return 'Unknown Error';

//       let errors: Array<string> = [];
//       Object.keys(this.formControl.errors).forEach((x) => {
//         let error = ErrorMsgsService.getErrorMsg(x);
//         if (error) errors.push(error);
//       });

//       return errors.join(', ');
//     }

//     return null;
//   }
// }

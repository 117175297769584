import { Injectable } from '@angular/core';

@Injectable()
export class ApiRequestDataService {
  public _requestHeaders: { name?: string, value?: string } = {};

  setRequestHeader(name: string, value: string) {
    this._requestHeaders[name] = value;
  }

  unsetRequestHeader(name: string) {
    delete this._requestHeaders[name];
  }
}

import { Component, OnInit, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { BaseBreadService } from '../../../shared/services/base-bread.service';
import { AuthService } from '../../../shared/services/auth.service';
import { FileUtils } from '../../../shared/utils/file-utils';
import * as moment from 'moment';
import { MessageModalService } from '../../../commons/message-modal/message-modal';

@Component({
  selector: 'app-profile-attachment',
  templateUrl: './profile-attachment.component.html',
  styleUrls: ['./profile-attachment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileAttachmentComponent implements OnInit {

  protected _eventEmitter = new EventEmitter();
  model = null;

  constructor(
    private baseBreadService: BaseBreadService,
    private authService: AuthService,
    protected messageModalService: MessageModalService,
    protected cd: ChangeDetectorRef
  )
  { }

  ngOnInit(): void {
    this._eventEmitter.emit(true);
    this.loadData();
  }

  loadData() {
    let queryOptions = {
      filter: {
        conditions: [
          {
            property: 'lmsUser.id',
            op: 'eq',
            value: this.authService.getActiveLmsUser().object.id,
            dataType: 'long'
          }
        ]
      }
    };

    this.baseBreadService.browseResource('lmsUserFile', {}, queryOptions).subscribe((res) => {
      this._eventEmitter.emit(false);
      this.model = res.data.records;
      this.cd.markForCheck();
    }, (err) => {
      this.messageModalService.error('Failed to load data.');
      this._eventEmitter.emit(false);
      this.cd.markForCheck();
    });
  }

  formatDate(date) {
    return moment(date).format('MM/DD/YYYY');
  }

  resourceUpload() {

    let maxSizeBytes = 26214400; // 25mb

    FileUtils.promptForSingleSelect((event) => {
      if (maxSizeBytes !== null && event.sizeBytes > maxSizeBytes) {
        console.error('File too large');
        this.messageModalService.error('File is too big.');
        return;
      }

      let model = {
        file: event,
        lmsUser: this.authService.getActiveLmsUser().object,
        name: event.originalFilename,
        // creatorEmployee: this.authService.getStoredUserInfo()
      };
      this._eventEmitter.emit(true);
      this.baseBreadService.addResource('lmsUserFile', model).subscribe((res) => {
        this.loadData();
      }, (err) => {
        this._eventEmitter.emit(false);
        this.messageModalService.error('Failed to upload.');
      });

      return {};
    });
  }

  getEventEmitter() {
    return this._eventEmitter;
  }

}

import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { LmsEnrollmentLessonService } from '../../shared/services/api/lms/lms-enrollment-lesson.service';
import { MessageModalService } from '../../commons/message-modal/message-modal';
import { AuthService } from '../../shared/services/auth.service';

import * as _ from 'lodash';
import { BaseBreadService } from '../../shared/services/base-bread.service';
import { HttpClient } from '@angular/common/http';

import { getXhrResponseErrorMessage } from '../../shared/utils/utils';
import { LmsEnrollmentLessonContentService, LmsLessonService } from '../../shared/services/api';
import { forkJoin } from 'rxjs';
import { CourseContentComponent } from '../../app/course-content/course-content.component';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss'],
})
export class CourseComponent implements OnInit {
  @ViewChild('modal', { static: true }) modal;

  model = null;
  loading = false;
  courseStatus = '';

  protected _busy: boolean = false;
  protected _load: boolean = false;
  protected _imageSource: string = null;
  protected _error: boolean;
  protected exists: boolean;
  contents = [];
  prerequisiteLessons = [];
  prerequisiteCertificates = [];
  prerequisiteCourses = [];
  lessonType = '';

  constructor(
    protected _cd: ChangeDetectorRef,
    private lmsEnrollmentLessonService: LmsEnrollmentLessonService,
    private lmsEnrollmentLessonContentService: LmsEnrollmentLessonContentService,
    private lmsLessonService: LmsLessonService,
    protected _modalService: MessageModalService,
    private authService: AuthService,
    protected _baseBreadService: BaseBreadService,
    protected http: HttpClient,
  ) { }

  ngOnInit(): void {
  }

  init(enrollment) {
    this.loading = true;
    this.lmsEnrollmentLessonService.read(enrollment).subscribe((res: any) => {
      this.model = res.data;
      this.courseStatus = this.setCourseStatus(this.model);
      this.getPreRequisites(this.model.lmsLesson);
      this.loadImages(this.model);
      this.lessonType = _.get(this.model, 'lmsLesson.lmsLessonType.name');

      if (this.model.started) {

        let queryOptions = {
          filter: {
            conditions: [
              {
                property: 'lmsEnrollmentLesson.id',
                op: 'eq',
                value: this.model.id,
                dataType: 'long'
              },
            ]
          }
        };

        this.lmsEnrollmentLessonContentService.browse({}, queryOptions).subscribe((res) => {
          this.contents = res.data.records;
          this.loading = false;
          this._cd.markForCheck();
        }, (err) => {
          this.loading = false;
          this._modalService.error('Failed to load data');
          this._cd.markForCheck();
        });
      } else {
        this.loading = false;
        this._cd.markForCheck();
      }
    }, (err) => {
      this.loading = false;
      this._modalService.error('Failed to load data');
      this._cd.markForCheck();
    });
  }

  onImageLoad(event: Event) {
    this._load = true;
    // this.clearError();
    this.exists = true;
  }

  onImageError(event: Event) {
    this._load = false;
    // this.setError();
    this._imageSource = null;
  }

  loadImages(model) {
    this.downloadImage('lmsEnrollmentLesson', model.id, 'lms_lesson_image');
  }

  downloadImage(resourceName: string, id: number, name: string = null) {
    // this.startBusy();
    // this.clearError();
    this._load = false;
    this._imageSource = null;
    const imageSource = this._baseBreadService.getResourceDownloadUrl(resourceName, { id: id, name: name }, name) + `&_timestamp=${new Date().getTime()}`;
    this._imageSource = imageSource;
    this.http.get(imageSource).subscribe(
      (response) => {
        // this.endBusy();
        this._load = true;
        this._imageSource = imageSource;
      },
      (error) => {
        // this.setError();
        // this.endBusy();
        this.exists = error.status !== 404;
      }
    );
    this._cd.markForCheck();
  }

  startLessonContent() {
    this.loading = true;
    let obs = [];
    obs.push(this.lmsEnrollmentLessonService.setFlag({ id: this.model.id, flagName: 'started', value: true }));

    let queryOptions = {
      filter: {
        conditions: [
          {
            property: 'lmsEnrollmentLesson.id',
            op: 'eq',
            value: this.model.id,
            dataType: 'long'
          },
        ]
      }
    };

    obs.push(this.lmsEnrollmentLessonContentService.browse({}, queryOptions));
    forkJoin(obs).subscribe((res: any) => {
      this.model = _.get(res[0], 'data');
      this.contents = _.get(res[1], 'data.records');
      this.courseStatus = this.setCourseStatus(this.model);
      this.loading = false;
      this._cd.markForCheck();
    }, (err) => {
      let msg = getXhrResponseErrorMessage(err, null) || 'Operation failed';
      this.loading = false;
      this._modalService.error(msg);
      this._cd.markForCheck();
    });
  }

  completeLessonContent() {
    this.loading = true;
    this.lmsEnrollmentLessonService.setFlag({ id: this.model.id, flagName: 'completed', value: true }).subscribe((res) => {
      this.model = res.data;
      this.courseStatus = this.setCourseStatus(this.model);
      this.loading = false;
      this._cd.markForCheck();
    }, (err) => {
      this.loading = false;
      let msg = getXhrResponseErrorMessage(err, null) || 'Operation failed';
      this._modalService.error(msg);
      this._cd.markForCheck();
    });
  }

  openModal(content) {
    this.modal.title = _.get(content, 'lmsCertificate.name');
    this.modal.landscapeLock = true;
    this.modal.createContentComponent(CourseContentComponent);
    this.modal.initialize().then((res) => {
      this.modal.getEventEmitter().subscribe((res) => {
        if (res.name === 'open') {
          this.modal._contentComponent.instance.init(content);
        }
      });
      this.modal.open();
    });
  }

  setCourseStatus(x) {
    if (x.passed) {
      return 'Passed';
    } else if (x.testCompleted) {
      return 'Test Taken';
    } else if (x.completed) {
      return 'Content completed';
    } else if (x.started) {
      return 'Content Started';
    } else {
      return 'Pending';
    }
  }

  getPreRequisites(lmsLesson) {
    if (lmsLesson) {

      this.prerequisiteLessons = _.map(_.filter(lmsLesson.prerequisiteLessons, (o) => _.get(o, 'prerequisiteLmsLesson')), 'prerequisiteLmsLesson.name');
      this.prerequisiteCourses = _.map(_.filter(lmsLesson.prerequisiteCourses, (o) => _.get(o, 'preRequisiteLmsCourse')), 'preRequisiteLmsCourse.name');
      this.prerequisiteCertificates = _.map(_.filter(lmsLesson.prerequisiteCertificates, (o) => _.get(o, 'preRequisiteLmsCertificate')), 'preRequisiteLmsCertificate.name');
      this._cd.markForCheck();
    }
  }

}

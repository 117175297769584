import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Company } from '../models/company/company.model';
import { CompanyBranch } from '../models/company-branch/company-branch.model';
import { Employee } from '../models/employee/employee.model';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppContextService {
  static EMPLOYEE_STORAGE_KEY = 'app_context_employee';
  static COMPANY_BRANCHES_STORAGE_KEY = 'app_context';
  static ACTIVE_BRANCH_STORAGE_KEY = 'active_branch';
  static ACTIVE_USER_KEY = 'active_user';
  static DISABLE_APPCONTEXT_KEY = 'disableAppContext';
  public branchesUpdateEvent: Subject<any> = new Subject();

  public setCompanyBranches(company: Company, companyBranches: CompanyBranch[]) {
    const data = { company, companyBranches };
    localStorage.setItem(AppContextService.COMPANY_BRANCHES_STORAGE_KEY, JSON.stringify(data));
  }

  public setBranches(companyBranches: CompanyBranch[]) {
    this.setCompanyBranches(this.getCompany(), companyBranches);
  }

  public getCompany(): Company {
    const data = this.getCompanyBranches();
    return data ? data.company : null;
  }

  public getCompanyBranches(): { company: Company, companyBranches: CompanyBranch[] } {
    const data = localStorage.getItem(AppContextService.COMPANY_BRANCHES_STORAGE_KEY);
    return JSON.parse(data);
  }

  public getBranches(): CompanyBranch[] {
    const data = this.getCompanyBranches();
    return data ? data.companyBranches : null;
  }

  public getBranch() {
    return this.getActiveBranch();
  }

  public getActiveBranch() {
    const data = localStorage.getItem(AppContextService.ACTIVE_BRANCH_STORAGE_KEY);
    let activeBranch: CompanyBranch = data ? JSON.parse(data) : null;
    const branches = this.getBranches();

    // if there is no active branch, get the first branch.
    return !activeBranch && branches ? branches[0] : activeBranch;
  }

  public setActiveBranch(companyBranch: CompanyBranch) {
    localStorage.setItem(AppContextService.ACTIVE_BRANCH_STORAGE_KEY, JSON.stringify(companyBranch));
  }

  public hasCompanyContext() {
    return !!this.getCompany();
  }

  public hasBranchContext() {
    return !!this.getBranch();
  }

  public setEmployee(employee: Employee) {
    localStorage.setItem(AppContextService.EMPLOYEE_STORAGE_KEY, JSON.stringify(employee));
  }

  public getEmployee() {
    const data = localStorage.getItem(AppContextService.EMPLOYEE_STORAGE_KEY);
    return data ? JSON.parse(data) : null;
  }

  public clearData() {
    localStorage.removeItem(AppContextService.ACTIVE_BRANCH_STORAGE_KEY);
    localStorage.removeItem(AppContextService.COMPANY_BRANCHES_STORAGE_KEY);
    localStorage.removeItem(AppContextService.EMPLOYEE_STORAGE_KEY);
    localStorage.removeItem(AppContextService.ACTIVE_USER_KEY);
  }

  public disableAppContext() {
    localStorage.setItem(AppContextService.DISABLE_APPCONTEXT_KEY, '1');
  }

  public enableAppContext() {
    localStorage.removeItem(AppContextService.DISABLE_APPCONTEXT_KEY);
  }

  public isAppContextDisabled() {
    if (!environment.production) return false;

    return !!localStorage.getItem(AppContextService.DISABLE_APPCONTEXT_KEY);
  }

  public toggleAppContextDisabled() {
    if (this.isAppContextDisabled()) {
      this.enableAppContext();
    } else {
      this.disableAppContext();
    }
  }
}

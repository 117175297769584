import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ComponentFactoryResolver, ViewChild, ViewContainerRef, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ProfileDetailsComponent } from './profile-details/profile-details.component';
import { ProfileAttachmentComponent } from './profile-attachment/profile-attachment.component';
import { forkJoin, Observable, from } from 'rxjs';

import * as _ from 'lodash';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileComponent implements OnInit, OnDestroy {
  @ViewChild('content', { read: ViewContainerRef }) contentContainer: ViewContainerRef;

  _tabViews = [
    { label: 'Details', componentClass: ProfileDetailsComponent, default: true , componentRef: null, active: null},
    { label: 'Attachment', componentClass: ProfileAttachmentComponent, componentRef: null, active: null },
    // { label: 'Enrollment', componentClass: ProfileAttachmentComponent, componentRef: null, active: null }
  ];

  private _activeTabIndex = null;

  loading = true;
  subscriptions = [];

  constructor(private _location: Location, private _cd: ChangeDetectorRef, protected _componentResolver: ComponentFactoryResolver) { }

  ngOnInit(): void {
    let observables = [];
    this.loadNextComponent(null, observables);
  }

  ngOnDestroy() {
    _.forEach(this.subscriptions, (s) => { s.unsubscribe(); });
  }

  loadComponent(component, index) {
    return Promise.resolve(this._componentResolver.resolveComponentFactory(component)).then((factory) => {
      const c = this.contentContainer.createComponent(factory, index, this.contentContainer.injector);
      return c;
    });
  }

  loadNextComponent(index, observables = null): Observable<any> {
    this._tabViews = _.cloneDeep(this._tabViews); // Create our own copy of _tabViews.

    let retObservables = observables ? observables : [];
    let componentIndex = index != null ? index + 1 : 0;

    if (this._tabViews.length > componentIndex) {
      let object = this._tabViews[componentIndex];
      let x = this.loadComponent(object.componentClass, componentIndex);
      let observable = from(x);
      observable.subscribe(x => {
        this.loadNextComponent(componentIndex, retObservables);
      });
      retObservables.push(observable);
      return retObservables;
    }
    forkJoin(retObservables).subscribe(x => {
      _.forEach(this._tabViews, (object, index) => {
        console.log(x);
        object.componentRef = x[index];
        // object.componentRef._hostElement.nativeElement.classList.add('modal-tab-content');
        // object.componentRef._hostElement.nativeElement.classList.add('scrollbar-border');
        // object.componentRef._nativeElement.classList.add('modal-tab-content');
        // object.componentRef._nativeElement.classList.add('scrollbar-border');
        this._cd.markForCheck();

        // if (object.componentOptions) {
        //   if (!_.isUndefined(object.componentOptions)) {
        //     if (_.isFunction(object.componentOptions)) {
        //       object.componentOptions.call(this, object.componentRef.instance);
        //     } else {
        //       // In
        //       if (_.isFunction(object.componentRef.instance.setComponentOptions)) {
        //         object.componentRef.instance.setComponentOptions(object.componentOptions);
        //       } else {
        //         throw 'setComponentOptions is not a function of the \'' + object.title + '\' modal tab component';
        //       }
        //     }
        //   }
        // }

        if (_.isFunction(object.componentRef.instance.getEventEmitter)) {
          let s = object.componentRef.instance.getEventEmitter().subscribe((x) => {
            this.loading = x;
            this._cd.markForCheck();
          });
          this.subscriptions.push(s);
        }
        // object.secAuthAccessEnabled = this.componentHasUserAccess(object.componentRef.instance, null, 'read');
      });

      this.initializeTabStates();
    });
    return retObservables;
  }

  initializeTabStates(options: Object = null) {
    let hasActive = false;
    // If there is no tab active by default, make the first tab active.
    if (!hasActive && this._tabViews.length) {
      this.setActiveTab(0);
    }

    // Init done:
    // this.resolveInitialize();
  }

  setActiveTab(index) {
    if (this._activeTabIndex === index) {
      return;
    }

    this._activeTabIndex = index;
    // Deactivate before activating active tab so that deactivate hooks are called before activate hooks.
    // At most one tab will be active at any given time, even during active state transition.
    _.forEach(this._tabViews, (object, index) => {
      this.deactivateTabAtIndex(index);
    });
    if (this._activeTabIndex != null) {
      this.activateTabAtIndex(this._activeTabIndex);
    }
    this._cd.markForCheck();
  }

  getTabAtIndex(index) {
    return this._tabViews[index];
  }

  deactivateTabAtIndex(index) {
    let tabView = this.getTabAtIndex(index);
    if (!tabView.active) {
      return;
    }
    tabView.active = false;
    // tabView.componentRef.instance.onBeforeDeactivate();
    // TODO: Use Renderer
    tabView.componentRef.location.nativeElement.classList.remove('active');
    // tabView.componentRef._nativeElement.classList.remove('active');
    // tabView.componentRef.instance.setEditMode(false);
    // tabView.componentRef.instance.onAfterDeactivate();
    this._cd.markForCheck();
  }

  activateTabAtIndex(index) {
    let tabView = this.getTabAtIndex(index);

    if (tabView.active) {
      return true;
    }

    tabView.active = true;
    // tabView.componentRef.instance.onBeforeActivate();
    // TODO: Use Renderer
    // tabView.componentRef._hostElement.nativeElement.classList.add('active');

    tabView.componentRef.location.nativeElement.classList.add('active');
    // tabView.componentRef.instance.onAfterActivate();
    this._cd.markForCheck();
    return true;
  }

  back() {
    this._location.back();
  }

}

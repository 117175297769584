import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { ApiService } from './api.service';
import { AppContextService } from './app-context.service';

import { RequestMethod } from './enums/request-methods';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  static AUTH_USER_INFO_KEY: string = 'auth.user_info';

  constructor(private apiService: ApiService, private appContextService: AppContextService) { }

  public logIn(account: { username: string, password: string }) {
    return this.apiService.requestToJsonApi(
      RequestMethod.POST,
      this.apiService.getRequestUrl(null, 'login'),
      null,
      account,
      true,
    );
  }

  public logOut() {
    return this.apiService.requestToJsonApi(
      RequestMethod.POST,
      this.apiService.getRequestUrl(null, 'logout'),
      null,
      null,
    );
  }

  public getCurrentUserDetails() {
    return this.apiService.requestToJsonApi(
      RequestMethod.GET,
      this.apiService.getRequestUrl('getCurrentUserDetails', 'auth'),
      null,
      null,
    );
  }

  public userExist(username: { username: string }) {
    return this.apiService.requestToJsonApi(
      RequestMethod.POST,
      this.apiService.getRequestUrl('usernameExists', 'secUser'),
      { username: username.username },
      null,
    );
  }

  public getStoredUserInfo() {
    const data = localStorage.getItem(AuthService.AUTH_USER_INFO_KEY);
    return data ? JSON.parse(data) : null;
  }

  public hasStoredUserInfo() {
    return !_.isNil(this.getStoredUserInfo());
  }

  public storeUserInfo(data: object) {
    localStorage.setItem(AuthService.AUTH_USER_INFO_KEY, JSON.stringify(data));
    this.apiService.setAccessToken(data['access_token']);
    const employee = this.getActiveEmployeeUser();
    if (employee) {
      this.appContextService.setEmployee(employee.object);
    }
  }

  public clearStoredUserInfo() {
    localStorage.removeItem(AuthService.AUTH_USER_INFO_KEY);
    this.apiService.setAccessToken(null);
  }

  public getActiveUserProfile(type: string = null) {
    const userInfo = this.getStoredUserInfo();
    if (userInfo && userInfo.userDetails && userInfo.userDetails.profiles) {
      const profile = _.find(userInfo.userDetails.profiles, (obj) => !type || obj.type === type);
      return profile;
    } else {
      return null;
    }
  }

  public getActiveLmsUser() {
    return this.getActiveUserProfile('lmsUser');
  }

  public getActiveEmployeeUser() {
    return this.getActiveUserProfile('employee');
  }

  public getActiveCustomerUser() {
    return this.getActiveUserProfile('customer');
  }

  public getActiveSupplierUser() {
    return this.getActiveUserProfile('supplier');
  }

  public getUsername() {
    const userInfo = this.getStoredUserInfo();
    return userInfo ? userInfo.username : null;
  }

  public isSuperadminUser() {
    return this.getUsername() === 'superadmin';
  }

  public checkUserAccessToResource(resource: any) {
    if (this.isSuperadminUser()) return true;
    if (_.isNil(resource)) return false;

    if (_.isArray(resource)) {
      return !_.isNil(_.find(resource, (resourceName) => this.checkUserAccessToResource(resourceName)));
    } else {
      const userInfo = this.getStoredUserInfo();
      const roles = userInfo.roles;
      return !_.isNil(_.find(roles, (role: string) => role.startsWith(`ROLE_${resource.toUpperCase()}.`)));
    }
  }

  public isLoggedIn() {
    return !!localStorage.getItem('token');
  }
}

import { NgModule, ModuleWithProviders } from '@angular/core';
import { NgModel, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MessageModalService, MessageModal } from '../commons/message-modal/message-modal';
import { LMSMessageModalService, LMSMessageModal } from '../commons/message-modal/lms/lms-message-modal';
import { CommonModule } from '@angular/common';
import { CalendarSummaryComponent } from './calendar-summary/calendar-summary.component';
import { ListTableComponent } from './list-table/list-table.component';
import { Dropdown2Component } from './dropdown/dropdown2.component';
import { AutocompleteInput2Component } from './autocomplete/autocomplete-input2.component';
import { EntityAutocompleteInput2Component } from './autocomplete/entity-autocomplete-input2.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ConfigService } from '../shared/services/config.service';
import { NoopControlValueAccessor } from '../shared/utils/noop-control-value-accessor';
import { ModalComponent } from './modal/modal.component';
import { CourseComponent } from './course/course.component';
import { CourseListComponent } from './course-list/course-list.component';
import { COMMON_DIRECTIVES, COMMON_PIPES } from './common-directives';
import { CertificateComponent } from './certificate/certificate.component';
import { DatePickerComponent } from './test-datepicker/test-datepicker.component';
import { ProfileImageInputComponent } from './file-input/profile-image-input.component';
import { ImageInputComponent } from './file-input/image-input.component';
import { FileInputComponent } from './file-input/file-input.component';
import { LMSFileInputComponent } from './file-input/lms/lms-file-input.component';
import { LMSImageInputComponent } from './file-input/lms/lms-image-input.component';
import { PaginationComponent } from './pagination/pagination.component';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';

import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';

@NgModule({
  imports: [
    CommonModule,
    PdfJsViewerModule
  ],
  declarations: [
    MessageModal,
    LMSMessageModal,
    CalendarSummaryComponent,
    ListTableComponent,
    Dropdown2Component,
    AutocompleteInput2Component,
    EntityAutocompleteInput2Component,
    CheckboxComponent,
    ModalComponent,
    CourseComponent,
    CourseListComponent,
    CertificateComponent,
    DatePickerComponent,
    ProfileImageInputComponent,
    ImageInputComponent,
    FileInputComponent,
    LMSImageInputComponent,
    LMSFileInputComponent,
    PaginationComponent,
    PdfViewerComponent,
    COMMON_DIRECTIVES,
    COMMON_PIPES,
  ],
  exports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarSummaryComponent,
    ListTableComponent,
    Dropdown2Component,
    AutocompleteInput2Component,
    EntityAutocompleteInput2Component,
    CheckboxComponent,
    ModalComponent,
    CourseComponent,
    CourseListComponent,
    CertificateComponent,
    DatePickerComponent,
    ProfileImageInputComponent,
    ImageInputComponent,
    FileInputComponent,
    LMSImageInputComponent,
    LMSFileInputComponent,
    PaginationComponent,
    PdfViewerComponent,
    COMMON_DIRECTIVES,
    COMMON_PIPES,
  ],
  entryComponents: [
    MessageModal,
    LMSMessageModal
  ],
})
export class CommonsModule {
  static forRoot(): ModuleWithProviders<CommonsModule> {
    return {
      ngModule: CommonsModule,
      providers: [
        NgModel,
        ConfigService,
        // ComponentLoader,
        NoopControlValueAccessor
      ],
    };
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable, Component } from '@angular/core';
import { PublicApiService } from '../../public-api.service';
import { AppContextService } from '../../app-context.service';
import { BaseBreadService } from '../../base-bread.service';
import * as _ from 'lodash';
import { ConfigService } from '../../config.service';
import { ApiService } from '../../api.service';

@Component({
  providers: [ConfigService, PublicApiService]
})

@Injectable()
export class BasePublicApiService extends BaseBreadService {

  constructor(public http: HttpClient, configService: ConfigService, public publicApiService: PublicApiService, public apiService: ApiService) {
    super(http, configService, apiService);
  }

  // Default request URL format: [BASE]/[RESOURCE_NAME]/[ACTION]
  getRequestUrl(action: string, resource: string = null): string {
    let resourceComponents = [];
    if (this._moduleName) {
      resourceComponents.push(this._moduleName);
    }
    resourceComponents.push(resource ? resource : this.getResourceName());
    return this.publicApiService.getRequestUrl(action, _.join(resourceComponents, '/'));
  }
}

import { ApiService } from '../../api.service';
import { BaseBreadService, BreadServiceDecorator } from '../../base-bread.service';
import { Injectable } from '@angular/core';

@BreadServiceDecorator({
  resourceName: 'lmsCourseLesson'
})

@Injectable()
export class LmsCourseLessonService extends BaseBreadService {
  constructor(public apiService: ApiService) {
    super(null, null, apiService);
  }
}

import { Directive, Input, OnChanges, SimpleChanges, HostBinding } from '@angular/core';

@Directive({
  selector: '[xsiBusy]',
})
export class BusyDirective implements OnChanges {
  @HostBinding('class.xsi-spinner') spinner: boolean = true;
  @HostBinding('class.xsi-spinner-active') busy: boolean = false;

  @Input('xsiBusy') xsiBusy: boolean = false;

  ngOnChanges(changes: SimpleChanges) {
    this.busy = this.xsiBusy;
  }
}

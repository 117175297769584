import { Component, Input, Output, OnDestroy, EventEmitter, ViewChild, ViewContainerRef, Renderer2, ChangeDetectionStrategy, ChangeDetectorRef, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { AutocompleteInput2Component } from './autocomplete-input2.component';
import { TemplateRef } from '@angular/core';

import * as _ from 'lodash';

export const ENTITY_AUTOCOMPLETE_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EntityAutocompleteInput2Component),
    multi: true
};

@Component({
  selector: 'xsi-entity-autocomplete-input',
  templateUrl: 'autocomplete-input2.html',
  styleUrls: ['../dropdown/dropdown2.component.scss', 'autocomplete-input2.component.scss'],
  providers: [ENTITY_AUTOCOMPLETE_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityAutocompleteInput2Component extends AutocompleteInput2Component implements OnDestroy {
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // BEGIN: Redeclaration of decorated AutocompleteInput2Component/Dropdown2Component members

  @ViewChild('dropdown', { static: false }) dropdown;
  @ViewChild('optionsList', { static: false }) optionsList;
  @ViewChild('dropdownContainer', { static: false }) dropdownContainer;
  @ViewChild('subviews', { read: ViewContainerRef, static: false }) subviewsContainer;
  @ViewChild('defaultTemplate', { static: false }) defaultTemplate;

  @Input() validTrigger: boolean;
  @Input() borderOverride: any;
  @Input() formControl;
  @Input() options: Array<any>;
  @Input() disabled: boolean;
  @Input() readonly: boolean;
  @Input() placeholder: string;
  @Input() displayText: any; // function or property name
  @Input() loop: boolean = true;
  @Input() required: boolean = false;
  @Input() clipOptions: boolean = false;

  @Input() propertyArray = [];

  @Input() equalityCriteria: any = null;
  @Input() optionTemplate;
  @Input() styles;
  @Input() disableViewEditButton: boolean = true;

  @Input() isFocused = null;

  @Output() optionSelect = new EventEmitter();

  // END: Redeclaration of decorated Dropdown2Component members
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // BEGIN: Redeclaration of decorated AutocompleteInput2Component members

  @Input() requireInput: boolean = false;
  @Input() fxnOptions = null; // function for fetching options
  @Output() inputTextChange = new EventEmitter();
  @Output() newDataAdded = new EventEmitter();

  // END: Redeclaration of decorated Dropdown2Component members
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  @Input() service = null;
  @Input() resourceName: string;
  @Input() queryOptions = null;
  @Input() entityModalClass: any;
  @Input() entityClass: any;
  @Input() allowAddNew: boolean = false;
  @Input() noCompanyContext: boolean = null;
  @Input() noCompanyBranchContext: boolean = null;

  @Input() addNewOptions: any = null;

  @Input() lastUserInputTextProperty = null;

  @Input() optionTransform = null;

  @Input() list = false;

  @Input() showAllOption = false;
  @Input() overlaySelection = false;

  _isLoading: boolean;
  _isFocused: boolean;
  _modalSubscriptions = [];
  _entityModalComponentRef;

  _browseObservable;
  template: string;

  overlayOptions = [];

  constructor(
    _viewContainerRef: ViewContainerRef,
    _renderer: Renderer2,
    _cd: ChangeDetectorRef
    ) {
    super(_renderer, _cd);
  }

  ngOnInit() {
    if (_.isUndefined(this.optionTemplate)) {
      this.optionTemplate = this.defaultTemplate;
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    // this.removeAllModalSubscriptions();
  }

  createConditions(search) {
    let conditions = [];

    console.error('search ', search);

    if (search) {
      _.forEach(this.propertyArray, (prop) => {
        if (prop == 'id') {
          conditions.push({
            property: prop,
            value: search,
            op: 'eq',
            dataType: 'long',
          });
        } else {
          conditions.push({
            property: prop,
            value: search + '%',
            op: 'like'
          });
        }
      });
    } else {
      _.forEach(this.propertyArray, (prop) => {
        conditions.push({
          property: prop,
          op: 'isNotNull'
        });
      });
    }

    if (this.queryOptions) {
      conditions.push(...this.queryOptions.filter.conditions);
    }

    return conditions;
  }

  createQueryOptions(search) {
    let queryOptions = _.clone(this.queryOptions) || {};

    if (this.propertyArray.length) {
      let additionalFilter = {
        conditions: [
          {
            compound: true,
            filter: {
              joinOp: 'or', // use 'or', not 'and'; 'or' makes more sense when searching multiple properties (i.e., find match in any of the properties)
              conditions: this.createConditions(search)
            }
          }
        ]
      };
      queryOptions = {
        filter: queryOptions.filter ? {
          joinOp: 'and',
          conditions: [
            { compound: true, filter: queryOptions.filter },
            { compound: true, filter: additionalFilter }
          ],
        } : additionalFilter
      }
    } else {
      queryOptions.searchKeyword = search;
      queryOptions.autocomplete = true;
    }

    if (!_.isFunction(this.displayText)) {
      queryOptions.sort = [{ property: this.displayText || 'name' }];
    }

    return queryOptions;
  }

  updateOptions(search, doneCallback) {
    if (this._browseObservable) {
      console.debug('Unsubscribing from previous observable');
      this._browseObservable.unsubscribe();
      this._browseObservable = null;
    }

    this._isLoading = true;
    this._cd.markForCheck();
    // var queryOptions = this.queryOptions ? _.clone(this.queryOptions) : {};
    var queryOptions = this.createQueryOptions(search);

    // queryOptions.searchKeyword = search;
    // queryOptions.autocomplete = true;
    // if (!_.isFunction(this.displayText)) {
    //   queryOptions.sort = [{ property: this.displayText || 'name' }];
    // }

    // Moved to createQueryOptions
    // queryOptions.searchKeyword = search;
    // queryOptions.autocomplete = true;
    // if (!_.isFunction(this.displayText)) {
    //   queryOptions.sort = [{ property: this.displayText || 'name' }];
    // }

    const browse = this.resourceName
      ? (this.list ? this.service.listResource(this.resourceName, { max: 100, offset: 0, noCount: 1 }, queryOptions, null, this.noCompanyBranchContext, this.noCompanyContext)
        : this.service.browseResource(this.resourceName, { max: 100, offset: 0, noCount: 1 }, queryOptions, null, this.noCompanyBranchContext, this.noCompanyContext)
      )
      : (this.list ? this.service.list({ max: 100, offset: 0, noCount: 1 }, queryOptions, null, this.noCompanyBranchContext, this.noCompanyContext)
        : this.service.browse({ max: 100, offset: 0, noCount: 1 }, queryOptions, null, this.noCompanyBranchContext, this.noCompanyContext)
      );
    this._browseObservable = browse
      .subscribe(
        (response) => {
          let records = response.data.records;
          doneCallback(this.optionTransform ? _.map(records, x => {
            return _.isFunction(this.optionTransform) ? this.optionTransform(x) : _.get(x, this.optionTransform);
          }) : records);
          this._isLoading = false;
          this._browseObservable = null;
          setTimeout(() => {
            if (!this.overlaySelection) {
              this.setDropdownInvert();
              this._cd.markForCheck();
            }
          });
        },
        (err) => {
          this._isLoading = false;
          this._browseObservable = null;
          setTimeout(() => {
            if (!this.overlaySelection) {
              this.setDropdownInvert();
              this._cd.markForCheck();
            }
          });
        }
      ); // TODO: Fetch records in batches.
  }

  // initializeEntityModal() {
  //   this.removeAllModalSubscriptions();
  //   var s = this._entityModalComponentRef.instance.getEventEmitter().subscribe(
  //     (event) => {
  //       switch (event.name) {
  //         case 'open':
  //           break;
  //         case 'close':
  //           if (!event.forwarded) {
  //             this._entityModalComponentRef.destroy();
  //             this._entityModalComponentRef = null;
  //           }
  //           break;
  //         case 'data.saved':
  //           if (!event.forwarded) {
  //             this.setValue(_.cloneDeep(event.data));
  //             this.optionSelect.emit(this.getValue());
  //             this.newDataAdded.emit(event.data);
  //           }
  //           break;
  //       }
  //     }
  //   );
  //   this._modalSubscriptions.push(s);
  // }

  // onAddNewButtonClick() {
  //   this.showEntityModal();
  // }

  // showEntityModal(entity = null) {
  //   if (!this.entityModalClass) {
  //     console.error('No entity modal component attached');
  //     return;
  //   }

  //   let lastUserInputText = this.lastUserInputText;

  //   this._componentLoader.loadComponent(this.subviewsContainer, this.entityModalClass).then((componentRef) => {
  //     let modalEntity = entity ? entity : (this.entityClass ? new this.entityClass() : {});
  //     let hasPredefinedValue: boolean = false;

  //     if (lastUserInputText) {
  //       hasPredefinedValue = !_.isNil(lastUserInputText);
  //       if (_.isFunction(modalEntity.assignName)) {
  //         modalEntity.assignName(lastUserInputText); // Allow model to decide how to assign name; model must have an assignName function.
  //       } else {
  //         if (!this.lastUserInputTextProperty) modalEntity.name = lastUserInputText; // fallback: direct assignment
  //         else modalEntity[this.lastUserInputTextProperty] = lastUserInputText;
  //       }
  //     }

  //     if (this.addNewOptions) this.setModalEntityDefaultValues(modalEntity);

  //     this._entityModalComponentRef = componentRef;
  //     let c = componentRef.instance;
  //     c.getInitDonePromise().then((o) => {
  //       c.initialize().then(() => {
  //         this.initializeEntityModal();
  //         _.defer(() => {
  //           let ret = c.setEntity(_.cloneDeep(modalEntity));
  //           if (hasPredefinedValue) {
  //             // ASSUMPTION: The default view will not do the save action if not dirty.
  //             //             So, mark as dirty to allow user to save without having to change the predefined value(s).
  //             // NOTE: EntityModalComponent.markAsDirty() marks only the ACTIVE tab as dirty.
  //             c.markAsDirty();
  //           }
  //           this._cd.markForCheck(); // CD FIXME
  //           c.show();
  //         });
  //       });
  //     });
  //   });
  // }

  // removeAllModalSubscriptions() {
  //   _.forEach(this._modalSubscriptions, (s) => { s.unsubscribe(); });
  // }

  // setModalEntityDefaultValues(modalEntity) {
  //   let obj = _.isFunction(this.addNewOptions) ? this.addNewOptions() : this.addNewOptions;

  //   _.forEach(_.keys(obj), (key) => {
  //     modalEntity[key] = obj[key];
  //   });
  // }

  getDisplayText(o) {
    if (this.entityClass && _.isFunction(this.entityClass.getEntityDisplayText)) {
      return this.entityClass.getEntityDisplayText(o);
    } else {
      return super.getDisplayText(o);
    }
  }

  // showModalFromList(event, index) {
  //   this.showEditModal(this.options[index].id);
  // }

  // showModalFromInput(event) {
  //   this.showEditModal(this.getValue().id);
  // }

  // _showViewEditIcon = false;

  // showEditModal(id) {
  //   this.service.read(id).subscribe((res) => {
  //     this.showEntityModal(res.data);
  //   });
  // }

  open(keyword = null) {
    if (this.overlaySelection) {
      this._isFocused = true;
      this.triggerAutocomplete(keyword);
    } else {
      super.open();
    }
  }

  triggerAutocomplete(keyword = null) {
    if (this.overlaySelection) {
      this.overlayOptions = [];
      this._isLoading = true;
      if (_.isNil(keyword)) keyword = this.inputText;
      if (keyword || !this.requireInput) {
        if (_.isFunction(this.updateOptionsDebounced)) {
          this.updateOptionsDebounced(keyword, (arg) => {
            this.dropdownActive = true;
            if (!this._isFocused) return;
            this._isLoading = false;
            this.overlayOptions.push(...arg);
            this.overlayOptions = _.sortBy(this.overlayOptions, 'id');
            this._cd.markForCheck();
          });
        }
      }
    } else {
      super.triggerAutocomplete(keyword);
    }
  }

  onKeydown(event) {
    if (this.overlaySelection) {
      let key = event.keyCode;

      if (this.dropdownActive) {
        switch (key) {
          case 13:
            // this.selectOption(this.activeIndex);
            event.preventDefault();
            break;
          case 38:
          case 40:
            var step = { 38: -1, 40: +1 };
            // this.moveActiveIndex(step[key], event);
            // this.adjustScrollForSelected();
            return false; // break;
          case 27:
          // case 8:

            // console.error('escaped');
            this.close();
            this.escapePressed.emit();
            break;
          default:
            break;
        }
      } else {
        switch (key) {
          case 13:
            event.preventDefault();
            break;
          case 40:
            this.open();
            break;
          case 8:
            // this.selectOption(null);
            break;
          case 27:
            this.escapePressed.emit();
            break;
          default:
            break;
        }
      }
    } else {
      super.onKeydown(event);
    }
  }

  onInputTextChange() {
    if (this.overlaySelection) {
      this.lastUserInputText = this.inputText;
      this.inputTextChange.emit(this.inputText);
      // this.setActiveIndex(null);
      // this.setDropdownInvert();
      this.open();
    } else {
      super.onInputTextChange();
    }
  }

  selectionClick(item) {
    this.setValue(item);
    this.optionSelect.emit(item);
  }
}

import { NgModule, Injectable } from '@angular/core';
import { Routes, RouterModule, CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { BranchSelectorComponent } from './branch-selector/branch-selector.component';
import { AppComponent } from './app.component';
import { RegistrationComponent } from './registration/registration.component';
import { HomeDashboardComponent } from './home-dashboard/home-dashboard.component';
import { CurriculumComponent } from './curriculum/curriculum.component';
import { AssessmentsComponent } from './assessments/assessments.component';
import { CourseListComponent } from '../commons/course-list/course-list.component';
import { ProfileComponent } from './profile/profile.component';
import { CertificatesComponent } from './certificates/certificates.component';
import { CalendarComponent } from './calendar/calendar.component';
import { AuthService } from '../shared/services/auth.service';
import { ApiService } from '../shared/services/api.service';
import { AppConfigService, AppFeature } from '../shared/services/app-config.service';
import { RequestMethod } from '../shared/services/enums/request-methods';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private _authService: AuthService, private _apiService: ApiService, private _appConfigService: AppConfigService, private router: Router) {
  }

  checkRequiredProfile() {
    return this._appConfigService.hasFeature(AppFeature.LOGIN__REQUIRE_LMSUSER_PROFILE) ? this._authService.getActiveLmsUser() : true;
  }

  canActivate() {
    return new Promise<any>((resolve, reject) => {
      let userInfo = this._authService.getStoredUserInfo();
      if (!this._authService.isSuperadminUser() && !this.checkRequiredProfile()) {
        resolve(false);
        this.router.navigate(['login']);
        return;
      }
      if (!userInfo) {
        this.router.navigate(['login']);
        resolve(false);
      } else {
        this._apiService.requestToJsonApi(RequestMethod.POST, this._apiService.getRequestUrl('usernameExists', 'secUser'), null, { username: userInfo.username })
          .subscribe(
            (res) => { resolve(res.data && res.data.exists); },
            (err) => { this.router.navigate(['login']); resolve(false); }
          );
      }
    });
  }
}

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'location', component: BranchSelectorComponent, canActivate: [AuthGuard]},
  { path: 'register', component: RegistrationComponent},
  { path: 'lms', component: AppComponent, canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: HomeDashboardComponent },
      { path: 'curriculum', component: CurriculumComponent },
      { path: 'curriculum/courses/:curriculum', component: CourseListComponent },
      { path: 'curriculum/assessments/:curriculum', component: AssessmentsComponent },
      { path: 'assessments', component: AssessmentsComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'certificates', component: CertificatesComponent },
      { path: 'calendar', component: CalendarComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule { }

import { Component, OnInit, ChangeDetectionStrategy, HostListener, AfterViewInit, ElementRef, OnChanges } from '@angular/core';
import { ResizeService } from '../../shared/services/resize.service';

import * as _ from 'lodash';

@Component({
  selector: 'app-course-content',
  templateUrl: './course-content.component.html',
  styleUrls: ['./course-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CourseContentComponent implements OnInit, AfterViewInit {

  model = null;
  resourceName = 'lmsEnrollmentLessonContent';
  docViewerOverrideName = 'content';
  page = 1;
  totalPages = 0;
  resizeDebounced;
  isLandscape = false;
  screenWidth = 0;

  constructor(private elementRef: ElementRef, private resizeSvc: ResizeService) {
    this.resizeDebounced = _.debounce(this.detectScreenSize, 200);

    this.resizeSvc.onResize$.subscribe((res) => {
      this.screenWidth = res;
      if (res > 768) {
        this.isLandscape = true;
      } else {
        if (window.orientation === 90 || window.orientation === -90) {
          this.isLandscape = true;
        } else {
          this.isLandscape = false;
        }
      }
    });

    window.addEventListener("orientationchange", (event) => {
      if (this.screenWidth > 768) {
        this.isLandscape = true;
      } else {
        if (window.orientation === 90 || window.orientation === -90) {
          this.isLandscape = true;
        } else {
          this.isLandscape = false;
        }
      }
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.detectScreenSize();
  }

  init(content) {
    this.model = content;
  }

  nextPage() {
    this.page += 1;
  }

  previousPage() {
    if (this.page !== 1) {
      this.page -= 1;
    }
  }

  getTotalPages(totalPages) {
    this.totalPages = totalPages;
  }

  detectScreenSize(event = null) {
    let width = 0;
    if (!event) {
      width = window.innerWidth;
    } else {
      width = event.target.innerWidth;
    }

    this.resizeSvc.onResize(width);
  }

  onPageChange(page) {
    if (page !== this.page) {
      console.log(page);
      this.page = page;
    }
  }

  @HostListener("window:resize", ['$event'])
  onResize(event) {
    this.resizeDebounced(event);
  }

}

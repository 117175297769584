import { Component, Input, Output, OnChanges, OnDestroy, ViewChild, EventEmitter, Renderer2, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { NoopControlValueAccessor, NOOP_CONTROL_VALUE_ACCESSOR } from '../../shared/utils/noop-control-value-accessor';

import { BaseControlValueAccessor, BASE_CONTROL_VALUE_ACCESSOR } from '../../shared/utils/base-control-value-accessor';

import * as _ from 'lodash';

export const DROPDOWN_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => Dropdown2Component),
    multi: true
};

@Component({
  selector: 'xsi-dropdown',
  templateUrl: 'dropdown2.component.html',
  styleUrls: ['dropdown2.component.scss'],
  providers: [DROPDOWN_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Dropdown2Component extends BaseControlValueAccessor implements OnChanges, OnDestroy {
  @ViewChild('dropdown') dropdown: ElementRef;
  @ViewChild('optionsList') optionsList: ElementRef;
  @ViewChild('dropdownContainer') dropdownContainer: ElementRef;

  @Input() validTrigger: boolean;
  @Input() formControl: any;
  @Input() options: Array<any>;
  @Input() disabled: boolean;
  @Input() readonly: boolean;
  @Input() placeholder: string;
  @Input() displayText: any; // function or property name
  @Input() loop: boolean = true;
  @Input() required: boolean;
  @Input() clipOptions: boolean = false;

  @Input() equalityCriteria: any = null;

  @Output() optionSelect = new EventEmitter();

  dropdownActive: boolean = false;
  activeIndex: number = null;

  setDropdownContainerTop;

  onWindowScroll;

  dropdownContainerHeight: number = 0;
  dropdownContainerWidth: number = 0;
  dropdownContainerTop: number = 0;

  dropdownStyles = {
    margin: '0',
    width: '0',
    top: '0',
    left: '0',
    right: '0',
  };

  constructor(_renderer: Renderer2, _cd: ChangeDetectorRef) {
    super(_cd);
    this.onWindowScroll = (event) => {
      if (event.target == this.optionsList.nativeElement || event.target.localName === 'input') return;
      this.close();
    }
    this.setDropdownContainerTop = () => {
      if (!this.dropdownActive) return;

      let adjustWidth = Number(window.getComputedStyle(this.dropdown.nativeElement).marginRight.slice(0, -2));
      this.dropdownStyles.top = `${this.dropdownContainer.nativeElement.getBoundingClientRect().top}px`;
      this.dropdownStyles.width = `${this.dropdownContainer.nativeElement.offsetWidth + adjustWidth}px`;

      this._cd.markForCheck();
    };
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.onWindowScroll , true);

    if (this._formControlStatusChangesSubscription) {
      this._formControlStatusChangesSubscription.unsubscribe();
    }
  }

  toggleScrollEventListener(add: boolean) {
    if (add) {
      window.addEventListener('scroll', this.onWindowScroll, true);
    } else {
      window.removeEventListener('scroll', this.onWindowScroll , true);
    }
  }

  ngOnChanges(changes) {
    if (changes.options) {
      this.updateValueFromOptions();
    }

    if (changes.formControl) {
      this.subscribeToFormControlStatusChanges();
    }
  }

  isEqualToValue(itemToCompareFrom) {
    if (!this.equalityCriteria) return _.isEqual(this.getValue(), itemToCompareFrom);
    if (_.isString(this.equalityCriteria)) return this.getValue()[this.equalityCriteria] === itemToCompareFrom[this.equalityCriteria];
    if (_.isArray(this.equalityCriteria)) {
      if (!this.equalityCriteria.length) return _.isEqual(this.getValue(), itemToCompareFrom);
      return _.every(this.equalityCriteria, (prop) => { return this.getValue()[prop] === itemToCompareFrom[prop] });
    }
    if (_.isFunction(this.equalityCriteria)) return this.equalityCriteria(this.getValue(), itemToCompareFrom);
  }

  updateValueFromOptions() {
    // let x = _.find(this.options, (o) => { return _.isEqual(this.getValue(), o); });
    let x = _.find(this.options, (o) => {
      return this.isEqualToValue(o);
    });
    if (x) {
      if (this._value !== x) {
        this._value = x;
        this._cd.markForCheck();
      }
    }
  }

  setOptions(options) {
    this.setActiveIndex(null);
    this.options = options;
    this._cd.markForCheck();
  }

  setActiveIndex(index) {
    this.activeIndex = index;
    this._cd.markForCheck();
  }

  moveActiveIndex(offset, event) {
    if (this.options == null || !this.options.length)
      return;

    if (this.activeIndex == null) {
      this.setActiveIndex(offset >= 0 ? 0 : this.options.length - 1); // if no active index, select first if offset is non-negative, last otherwise
    } else {
      var idx = this.activeIndex + offset;
      if (this.loop) {
        idx = (idx % this.options.length + this.options.length) % this.options.length;
      }
      idx = Math.max(Math.min(idx, this.options.length - 1), 0); // ensures selection is between first and last element
      this.setActiveIndex(idx);
    }
  }

  selectOption(index) {
    // Emit change event and mark control as dirty only if there really is a change.

    let selected = index == null ? null : this.options[index];

    if (!this.isEqualToValue(selected)) {
      if (this.isAllowedOption(selected)) {
        this.setValue(selected);
        this.optionSelect.emit(selected);
      }
    }

    this.close();
  }

  isAllowedOption(x) {
    return _.includes(this.options, x);
  }

  getDisplayText(o) {
    let text = this._getDisplayText(o);
    return text; //return _.isUndefined(text) ? 'None' : text;
  }

  //FIXME: redundant markup

  _getDisplayText(o) {
    //if (_.isUndefined(o) || _.isNull(o)) return null;
    if (this.displayText) {
      if (_.isFunction(this.displayText)) {
        return this.displayText(o);
      } else if (_.isObjectLike(this.displayText)) {
        return this.displayText[o];
      } else if (_.isString(this.displayText)) {
        if (_.isUndefined(o) || _.isNull(o)) return null;
        return o[this.displayText];
      } else {
        console.error('Invalid dropdown displayText configuration');
        return null;
      }
    } else {
      if (_.isUndefined(o) || _.isNull(o)) return null;
      // return o instanceof Object ? o['name'] : o;
      let name = o['name'];
      let id = o['id'];
      return o instanceof Object ? (_.isUndefined(name) ? id : name) : o;
    }
  }

  isNoneOption(o) {
    let text = this._getDisplayText(o);
    return _.isUndefined(text) ? true : false;
  }

  validate() {
    if (this.validTrigger) {
      if (this.required) {
        if (!this.getValue()) {
          return 'required-field';
        } else {
          return '';
        }
      }
    }
  }

  open() {
    if (this.dropdownActive) return;

    this.dropdownActive = true;
    this.toggleScrollEventListener(true);
    this.setDropdownContainerTop();
    this.setDropdownInvert();

    this._cd.markForCheck();
  }

  close() {
    this.toggleScrollEventListener(false);
    this.dropdownActive = false;
    this.setActiveIndex(null);
    this._cd.markForCheck();
  }

  setDropdownInvert() {
    let containerBoundingClientRect = this.dropdownContainer.nativeElement.getBoundingClientRect();
    let marginBottom = Number(window.getComputedStyle(this.dropdown.nativeElement).marginBottom.slice(0, -2));
    let dropdownBottom = containerBoundingClientRect.bottom + this.dropdown.nativeElement.offsetHeight + marginBottom;
    let dropdownRight = containerBoundingClientRect.left + this.dropdown.nativeElement.getBoundingClientRect().width;

    if (dropdownBottom < window.innerHeight) {
      this.dropdownStyles.margin = `${this.dropdownContainer.nativeElement.offsetHeight + marginBottom}px`;
    } else {
      this.dropdownStyles.margin = `${-(this.dropdown.nativeElement.offsetHeight + marginBottom)}px`;
    }

    if (dropdownRight > window.innerWidth) {
      this.dropdownStyles.right = `${window.innerWidth - containerBoundingClientRect.right}px`;
      this.dropdownStyles.left = 'auto';
    } else {
      this.dropdownStyles.left = `${containerBoundingClientRect.left}px`;
      this.dropdownStyles.right = 'auto';
    }
  }

  onClick(event: MouseEvent) {
    if (this.dropdownActive) {
      this.close();
    } else {
      this.open();
    }
  }

  onFocus(event: MouseEvent) {
    //this.open();
  }

  onBlur(event) {
    this.close();
    this.onTouchedCallback();
  }

  onKeydown(event) {
    let key = event.keyCode;

    if (this.dropdownActive) {
      switch (key) {
        case 13:
          this.selectOption(this.activeIndex);
          event.preventDefault();
          break;
        case 38:
        case 40:
          var step = { 38: -1, 40: +1 };
          this.moveActiveIndex(step[key], event);
          this.adjustScrollForSelected();
          return false; //break;
        case 27:
        case 8:
          this.close();
          break;
      }
    } else {
      switch (key) {
        case 13:
          event.preventDefault();
        case 40:
          this.open();
          break;
        case 8:
          this.selectOption(null);
          break;
      }
    }
  }

  onMouseover(index) {
    if (!this.dropdownActive) return;
    this.setActiveIndex(index);
  }

  onMouseleave(index) {
    if (!this.dropdownActive) return;
    this.setActiveIndex(null);
  }

  // TODO: Create a reusable external util function for this.
  // TODO: event handler that will cause change for el.
  isScrolledIntoView(el: HTMLElement) {
    var elemTop = el.getBoundingClientRect().top;
    var elemBottom = el.getBoundingClientRect().bottom;
    var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);

    return isVisible;
  }

  adjustScrollForSelected() {
    // [!] involves direct DOM manipulation -- try to avoid if possible
    let el = this.optionsList.nativeElement.children[this.activeIndex];
    let elContainer = this.optionsList.nativeElement;
    if (this.activeIndex != null && el) {
      let selTop = el.getBoundingClientRect().top;
      let selBottom = el.getBoundingClientRect().bottom;
      let containerTop = elContainer.getBoundingClientRect().top;
      let containerBottom = elContainer.getBoundingClientRect().bottom;

      if (selTop < containerTop) {
        //el.scrollIntoView(true); // easier (for compatible browsers) but experimental so not used
        elContainer.scrollTop += (selTop - containerTop);
      } else if (selBottom > containerBottom) {
        //el.scrollIntoView(false); // easier (for compatible browsers) but experimental so not used
        elContainer.scrollTop += (selBottom - containerBottom);
      }
    } else {
      elContainer.scrollTop = 0;
    }
  }

  protected _formControlStatusChangesSubscription: any;
  protected _lastFormControlStatusValue: any;

  subscribeToFormControlStatusChanges() {
    if (this._formControlStatusChangesSubscription) {
      this._formControlStatusChangesSubscription.unsubscribe();
      this._lastFormControlStatusValue = null;
    }
    if (this.formControl) {
      this._formControlStatusChangesSubscription = this.formControl.statusChanges.subscribe((x: any) => {
        if (this._lastFormControlStatusValue != x) {
          this._cd.markForCheck();
          // console.debug('new status value %s -> %s', this._lastFormControlStatusValue, x);
        }
        this._lastFormControlStatusValue = x;
      });
    }
  }

  // ControlValueAccessor interface function
  writeValue(value: any) {
    if (value !== this._value) {
      this._value = value;
      this.updateValueFromOptions();
      this._cd.markForCheck();
    }
  }

  setValue(v: any) {
    if (v !== this._value) {
      this._value = v;
      this.onChangeCallback(this._value);
      this._cd.markForCheck();
    }
  }
}
